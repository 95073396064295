<script>
	import Layout from "./templates/LayoutTemplate.svelte";
	import Template from "./templates/StoresTemplate.svelte";
	import LoadingText from "../../common/LoadingText.svelte";
	import { connection } from "../../store";

	export let params = {};

	const { _id } = params;
	let isLoading;
	let dataId;
	let data;
	let stores;

	async function loadData(dataId) {
		isLoading = true;
		await $connection
			.call("products.byId", dataId)
			.then((result) => {
				data = result;
			})
			.catch((error) => {
				console.log(error);
			});
		isLoading = false;
	}
	async function loadStores(productID) {
		isLoading = true;
		await $connection
			.call("stores.byIdProduct.forAdminLite", productID)
			.then((result) => {
				stores = result;
			})
			.catch((error) => {
				console.log(error);
			});
		isLoading = false;
	}

	$: if (params) {
		dataId = params._id;
		loadData(dataId);
		loadStores(dataId);
	}
</script>

<Layout id={dataId} name={data?.name} page="stores">
	{#if isLoading}
		<LoadingText />
	{:else if stores}
		<Template id={dataId} data={stores} />
	{/if}
</Layout>

<script>
	import Modal from "../Modal.svelte";
	import { debounce } from "lodash";
	import LoadingText from "../LoadingText.svelte";
	import Button from "../control/Button.svelte";
	import { connection } from "../../store";
	import { createEventDispatcher } from "svelte";
	import SearchBar from "../SearchBar.svelte";
	import Check from "../control/Check.svelte";
	const dispatch = createEventDispatcher();

	export let storeId;
	export let storeMenuIds;
	let modalAddMenu;
	let dataFiltered = [];
	let selectedMenu = [];
	let isLoading = false,
		showPaginate = false,
		isEndPaginate = false;
	let searchMenu = "";
	let page = 1,
		perPage = 5;
	const headerTableAddMenu = ["Menu", "Status", "Action"];

	async function fetchAllMenus() {
		// reset value
		showPaginate = false;
		isEndPaginate = false;
		isLoading = true;

		// get data
		const selectedMenuIds = storeMenuIds.length > 0 ? storeMenuIds : [];
		$connection
			.call("menus.all.pagination.search", page, perPage, searchMenu.trim().toLowerCase(), selectedMenuIds, true)
			.then((result) => {
				resolveSelected(result.data);
				if (result.length > perPage) {
					showPaginate = true;
					isEndPaginate = Math.floor(result.totalPage) == page;
				}
				isLoading = false;
			})
			.catch((err) => {
				window.pushToast(err.error, "danger");
			});
	}

	function handleSearch(e) {
		page = 1;
		fetchAllMenus();
	}
	function onSelectMenu(menu) {
		const isSelected = !menu.isSelected;
		menu.isSelected = isSelected;

		if (isSelected) {
			selectedMenu.push(menu._id);
		} else {
			selectedMenu = selectedMenu.filter((it) => it != menu._id);
		}
		selectedMenu = selectedMenu;

		resolveSelected(dataFiltered);
	}
	async function onConfirmSelectMenu() {
		if (!selectedMenu.length) {
			window.pushToast("Please Select Minimal 1 Menu.", "danger");
		} else {
			isLoading = true;
			$connection
				.call("stores.add.menus", storeId, selectedMenu)
				.then((result) => {
					window.pushToast("Successfully Add Menu.", "success");
					isLoading = false;
					dispatch("load-data");
					onCloseModalAddMenu();
				})
				.catch((err) => {
					window.pushToast("Something Wrong! Cant Add Menu.", "danger");
					isLoading = false;
				});
		}
	}
	function resolveSelected(_data) {
		dataFiltered = _data.map((e) => ({
			...e,
			isSelected: selectedMenu.includes(e._id) ? true : false,
		}));
	}
	export function open() {
		modalAddMenu.toggle();
		searchMenu = "";
		page = 1;
		fetchAllMenus();
	}
	function onCloseModalAddMenu() {
		selectedMenu = [];
		searchMenu = "";
		modalAddMenu.toggle();
	}

	export function close() {
		modalAddMenu.close();
	}
</script>

<Modal
	bind:this={modalAddMenu}
	title="Add Menu"
	closed
	on:close={() => {
		onCloseModalAddMenu();
	}}
>
	<SearchBar
		bind:value={searchMenu}
		on:input={debounce(function () {
			handleSearch();
		}, 1000)}
	/>
	<table class="min-w-full divide-y divide-gray-200 mt-4 mb-7">
		<thead class="bg-gray-50">
			<tr>
				{#each headerTableAddMenu as header}
					<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
						{header}
					</th>
				{/each}
			</tr>
		</thead>
		<tbody class="bg-white divide-y divide-gray-200">
			{#if isLoading}
				<LoadingText />
			{:else}
				{#if !dataFiltered.length}
					<tr>
						<td colspan="3" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"> No Menu Available </td>
					</tr>
				{/if}
				{#each dataFiltered as menu}
					<tr>
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
							{menu.name}
						</td>
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
							{menu.status}
						</td>
						<td class="px-8 whitespace-nowrap text-center text-sm text-gray-800">
							<Check
								bind:value={menu.isSelected}
								checked={menu.isSelected}
								on:change={(value) => onSelectMenu(menu)}
								disabled={menu.disabled}
							/>
						</td>
					</tr>
				{/each}
			{/if}
		</tbody>
	</table>

	{#if !isLoading && dataFiltered.length > 0}
		<div class="flex justify-between w-full">
			<div class="flex space-x-2">
				{#if showPaginate}
					<Button
						disabled={page == 1}
						on:click={async () => {
							if (page > 1) {
								page -= 1;
								await fetchAllMenus();
							}
						}}>Prev</Button
					>
					<Button
						disabled={isEndPaginate}
						on:click={async () => {
							if (!isEndPaginate) {
								page += 1;
								await fetchAllMenus();
							}
						}}>Next</Button
					>
				{/if}
			</div>
			<Button status="success" on:click={() => onConfirmSelectMenu()}>Select Menu</Button>
		</div>
	{/if}
</Modal>

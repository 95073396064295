<script>
	import Template from "./templates/DetailTemplate.svelte";
	import LoadingText from "../../common/LoadingText.svelte";
	export let params = {};
	import { connection } from "../../store";
	import { createEventDispatcher } from "svelte";

	const { _id } = params;
	const dispatch = createEventDispatcher();
	let isLoading;
	let dataId;
	let data;
	async function loadData(dataId) {
		isLoading = true;
		await $connection
			.call("productCategories.byId.forAdminLite", dataId)
			.then((result) => {
				data = result;
			})
			.catch((error) => {
				console.log(error);
			});
		isLoading = false;
	}

	$: if (params) {
		dataId = params._id;
		loadData(dataId);
	}

	function routeEvent() {
		loadData(dataId);
		dispatch('routeEvent')
	}
</script>

{#if isLoading}
	<LoadingText />
{:else if data}
	<Template id={dataId} bind:data on:reload-data={routeEvent} />
{/if}

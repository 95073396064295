<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Cleave from "cleave.js";
  import "cleave.js/dist/addons/cleave-phone.id";
  import Util from "../Util/Utility.ts";
  import { onMount } from "svelte";

  export let label = "";
  export let placeholder = "";
  export let value;
  export let name;
  export let type = "text";
  export let maxlength = 100;
  export let minlength = 1;
  export let max = "";
  export let min = "";
  export let withEye = type == "password" ? true : false;
  export let errors = [];
  export let disabled = false;

  let showPass = false;
  let showHide = false;

  onMount(() => {
    const inputArr = document.getElementsByClassName("input-number");
    const inputCard = document.getElementsByClassName("input-creditcard");
    const inputExp = document.getElementsByClassName("input-exp");
    const inputCvv = document.getElementsByClassName("input-cvv");
    const fields = Array.from(inputArr);
    const fieldCard = Array.from(inputCard);
    const fieldsExp = Array.from(inputExp);
    const fieldsCvv = Array.from(inputCvv);

    if (type == "number") {
      fields.forEach(function (field) {
        new Cleave(field, {
          phone: true,
        });
      });
    }

    if (type == "creditcard") {
      fieldCard.forEach(function (field) {
        new Cleave(field, {
          delimiter: " ",
          blocks: [3, 3],
          numericOnly: true,
        });
      });
    }

    if (type == "expdate") {
      fieldsExp.forEach(function (field) {
        new Cleave(field, {
          date: true,
          datePattern: ["m", "d"],
        });
      });
    }

    if (type == "cvv") {
      fieldsCvv.forEach(function (field) {
        new Cleave(field, {
          blocks: [3],
          numericOnly: true,
        });
      });
    }
  });

  $: if (errors.length > 0) {
    errors = errors
      .filter((err) => err.split(".")[0] == name)
      .map((e) => ({
        field: e.split(".")[0],
        error: e.split(".")[1],
      }));
    errors = errors;
  }

  function handleInput(evt) {
    const { target } = evt;
    switch (type) {
        case 'text':
            value = target.value;
            break;
        case 'email':
            value = target.value;
            break;
        case 'password':
            value = target.value;
            break;
        case 'number':
            value = parseFloat(target.value ? target.value : 0);
            if (min && value < min) value = min;
            break;
    }
  }
</script>

<div class="relative">
  {#if label}
    <label
      class="block mb-2 text-primary dark:text-white text-sm font-bold"
      for={value}
    >
      {label}
    </label>
  {/if}
  <div class="relative w-full">
    <input
      type={type == "creditcard" || type == "expdate" || type == "cvv"
        ? "text"
        : type}
      {name}
      {value}
      {maxlength}
      {minlength}
      pattern={type == "tel" ? "d+" : null}
      {max}
      {min}
      {disabled}
      class="{type == 'number'
        ? 'input-number'
        : type == 'creditcard'
        ? 'input-creditcard'
        : type == 'expdate'
        ? 'input-exp'
        : type == 'cvv'
        ? 'input-cvv'
        : ''} appearance-none block w-full {errors.length > 0
        ? 'border-red-500'
        : ''} {disabled
				? ' text-gray-500 border-gray-400'
				: ' border-gray-800'} border text-sm dark:text-white dark:bg-dark-third rounded py-2 px-3 focus:ring-transparent focus:border-gray-800"
      {placeholder}
      on:input={handleInput}
      on:change={dispatch('change', value)}
    />
    {#if withEye}
      <button
        type="button"
        on:click={() => {
          if (!showPass) {
            type = "text";
            showPass = !showPass;
          } else {
            type = "password";
            showPass = !showPass;
          }
        }}
        class="absolute right-0 inset-y-0 flex items-center pr-3 outline-none focus:ring-transparent focus:outline-none"
      >
        <i class="bx {showPass ? 'bx-show' : 'bx-hide'}" />
      </button>
    {/if}
  </div>
  {#if errors.length > 0 && errors[0]["error"] == "required"}
    <p class="text-red-500 text-xs italic m-0">Please fill out this field.</p>
  {/if}
  {#if errors.length > 0 && errors[0]["error"] == "match_field"}
    <p class="text-red-500 text-xs italic m-0">Please repeat exact password.</p>
  {/if}
</div>

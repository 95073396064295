<script>
  import { format, parse } from "date-fns";
  import flatpickr from "flatpickr";
  import { onMount, afterUpdate, createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let name = undefined;
  export let pattern = "dd MMM yyyy";
  export let patternTime = "H:i";

  export let value = null;
  export let from = null;
  export let to = null;
  export let isLimitedMonth = false;

  export let smallLabel = undefined;
  export let label = undefined;
  export let circle = undefined;
  export let ranged = false;
  export let isTime = false;
  export let isDate = true;
  export let disabled = false;
  export let noIcon = false;
  export let textSize = "sm";
  export let errors = [];

  let element;
  let formattedValue = "";
  function formatDate(value1, value2) {
    return `${format(value1, pattern)} to ${format(value2, pattern)}`;
  }

  $: if (ranged) {
    formattedValue = formatDate(from, to);
  } else if (isTime) {
    formattedValue = value;
  } else {
    formattedValue = format(value, pattern);
  }

  $: if (errors.length > 0) {
    errors = errors
      .filter((err) => err.split(".")[0] == name)
      .map((e) => ({
        field: e.split(".")[0],
        error: e.split(".")[1],
      }));
    errors = errors;
  }

  const flatPickroptions = ranged
    ? {
        onChange: (selectedDates, dateStr, instance) => {
          if (selectedDates.length < 2) {
            return;
          }
          const fromDate = new Date(selectedDates[0]);
          const toDate = new Date(selectedDates[1]);
          const diffTime = Math.abs(toDate - fromDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          fromDate.setHours(0, 0, 1);
          toDate.setHours(23, 59, 59);
          if (isLimitedMonth && diffDays > 30) {
            window.pushToast(`Max select 30 Days`, "danger");
            return;
          } else {
            from = fromDate;
            to = toDate;
          }

          dispatch("update", {
            fromDate,
            toDate,
          });
        },
        parseDate: (datestr) => {
          return parse(datestr, pattern, new Date());
        },
        formatDate: (date, _pattern, locale) => {
          return format(date, pattern);
        },
        mode: "range",
      }
    : isDate && isTime
    ? {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr: true,
        defaultDate: value,
        onChange: (selectedDates, dateStr, instance) => {
          value = new Date(selectedDates[0]);
        },
      }
    : !isDate && isTime
    ? {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        dateFormat: "H:i",
        onChange: (val) => {
          const newDate = new Date(val);
          value = format(newDate, "HH:mm");
        },
      }
    : {
        onChange: (selectedDates, dateStr, instance) => {
          value = new Date(selectedDates[0]);
        },
        parseDate: (datestr) => {
          return parse(datestr, pattern, value);
        },
        formatDate: (date, _pattern, locale) => {
          return format(date, pattern);
        },
      };

  function initFlatpickr() {
    if (element) {
      flatpickr(
        element,
        disabled ? { ...flatPickroptions, clickOpens: false } : flatPickroptions
      );
    }
  }

  onMount(() => {
    if (ranged) {
      if (!from) {
        from = new Date();
      }
      if (!to) {
        to = new Date();
      }
    } else {
      if (!isTime && !value) {
        value = new Date();
      }
    }
    initFlatpickr();
  });

  afterUpdate(initFlatpickr);
</script>

<div>
  {#if label}
    <label class="block text-primary text-sm font-bold mb-2" for={value}>
      {label}
    </label>
  {/if}
  <div class="relative">
    <input
      type="text"
      bind:this={element}
      value={formattedValue}
      class="{errors.length > 0 ? 'border-red-500' : 'border-gray-800'} 
            {circle ? 'rounded-full' : 'rounded'} {disabled
        ? 'opacity-50'
        : ''} relative block w-full text-left text-{textSize} text-dark-second border dark:text-white dark:bg-dark-third py-2 px-3 cursor-pointer hover:bg-gray-100"
      placeholder={ranged
        ? "Select date range"
        : isTime && isDate
        ? "Select Date"
        : "Select Time"}
    />
    {#if smallLabel}
      <span class="absolute -top-2.5 left-1 text-xs bg-white px-1"
        >{smallLabel}</span
      >
    {/if}
    {#if !noIcon}
      <div class="absolute top-1.5 right-2">
        <i class="bx bx-calendar bx-sm text-primary" />
      </div>
    {/if}
  </div>
  {#if errors.length > 0 && errors[0]["error"] == "required"}
    <p class="text-red-500 text-xs italic">Please fill out this field.</p>
  {/if}
</div>

<script>
    import Navbar from "../Navbar.svelte";
    import Sidebar from "../Sidebar.svelte";
    import Toast from "../Toast.svelte";
    import SidebarMobile from "../SidebarMobile.svelte";
    export let title = 'Dashboard';
    let showSidebar = false;
</script>

<div class="relative">
    <!-- {#if showSidebar} -->
    <SidebarMobile bind:showSidebar />
    <div class="flex h-screen">
        <Sidebar />
        <!-- {/if} -->
        <div class="relative h-full flex flex-col flex-no-wrap flex-1 bg-gray-100 overflow-hidden">
            <Navbar {title} bind:showSidebar />
            <div class="h-full flex-1 overflow-auto md:overflow-hidden">
                <slot />
            </div>
            <!-- <div class="absolute bottom-0 inset-x-0">
                <Footer />
            </div> -->
        </div>
    </div>
    <Toast />
</div>

<script>
  import { replace } from "svelte-spa-router";
  import Template from "./templates/FormTemplate.svelte";
  import { push } from "svelte-spa-router";
  import { onMount } from "svelte";
  import LoadingText from "../../common/LoadingText.svelte";
  import { form, field } from "svelte-forms";
  import { required, matchField } from "svelte-forms/validators";
  import { connection } from "../../store";
	import { createEventDispatcher } from "svelte";

  export let params = {};

  let isLoading;
  let dataId = params._id;

  let user;
  let roles = [];

  let name = field("name", "", [required()]);
  let email = field("email", "", [required()]);
  let gender = field("gender", "", [required()]);
  let phone = field("phone", "", [required()]);

  let address = "";

  let errors = [];

  let formEdit = form(name, email, gender, phone);
	const dispatch = createEventDispatcher();

  onMount(() => {
    loadRole();
  });

  function loadRole() {
    isLoading = true;
    $connection;
    $connection.call("role.getByUserId", dataId).then((result) => {
      if (result) {
        roles = result;
      }
    });
    $connection.call("users.byId", dataId).then((result) => {
      if (result) {
        user = result["user"];
        $name.value = user?.profile.name;
        $email.value = user?.emails[0].address;
        $gender.value = user?.profile.gender;
        $phone.value = user?.profile.phone.number;
        address = user?.profile.address;
      }
    });
    isLoading = false;
  }

  async function handleSubmit(e) {
    const { image, name, gender, phone, address, roles } = e.detail;

    await formEdit.validate();
    const valid = $formEdit.valid;
    if (valid) {
      $connection
        .call(
          "users.update",
          dataId,
          image,
          name,
          gender,
          phone,
          address,
          roles
        )
        .then((result) => {
          window.pushToast("Successfuly Update User.", "success");
          push(`/users/${dataId}/info/detail`);
					dispatch("routeEvent");
        })
        .catch((err) => {
          window.pushToast(err.reason, "danger");
        });
    } else {
      errors = $formEdit.errors;
      window.pushToast("Required fields not complete", "danger");
    }
  }
</script>

{#if isLoading}
  <LoadingText />
{:else if user && roles.length > 0}
  <Template
    bind:errors
    id={dataId}
    image={user.profile.image}
    bind:name={$name.value}
    bind:email={$email.value}
    bind:gender={$gender.value}
    bind:phone={$phone.value}
    bind:address
    password={user.password}
    {roles}
    on:submit={handleSubmit}
  />
{:else}
  No data
{/if}

<script>
    import Layout from './templates/LayoutTemplate.svelte';
    import Template from './templates/MenusTemplate.svelte';
    export let params = {};

    let dataId;

    $: if (params) {
        dataId = params._id;
    }
</script>

<Layout id={dataId} page="menus">
    <Template id={dataId} />
</Layout>

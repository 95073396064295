<script>
	import Layout from "./templates/LayoutTemplate.svelte";
	import Template from "./templates/CampaignsTemplate.svelte";
	import LoadingText from "../../common/LoadingText.svelte";
	export let params = {};
	import { connection } from "../../store";

	const { _id } = params;
	let isLoading;
	let dataId;
	let data;
	let campaigns;
	async function loadData(dataId) {
		isLoading = true;
		await $connection
			.call("products.byId", dataId)
			.then((result) => {
				data = result;
			})
			.catch((error) => {
				console.log(error);
			});
		isLoading = false;
	}
	async function loadCampaigns(productID) {
		isLoading = true;
		await $connection
			.call("campaigns.byIdProduct.forAdminLite", productID)
			.then((result) => {
				campaigns = result;
			})
			.catch((error) => {
				console.log(error);
			});
		isLoading = false;
	}
	$: if (params) {
		dataId = params._id;
		loadData(dataId);
		loadCampaigns(dataId);
	}
</script>

<Layout id={dataId} name={data?.name} page="campaigns">
	{#if isLoading}
		<LoadingText />
	{:else if campaigns}
		<Template data={campaigns} id={dataId} />
	{/if}
</Layout>

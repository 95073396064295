<script>
  import Button from "../../../common/control/Button.svelte";
  import Tile from "../../../common/Tile.svelte";
  import Modal from "../../../common/Modal.svelte";
  import Divider from "../../../common/Divider.svelte";
  import { push } from "svelte-spa-router";
  import { connection } from "../../../store";
  import { onMount } from "svelte";
  import LoadingText from "../../../common/LoadingText.svelte";
	import ModalEmployee from "../../../common/Modal/ModalEmployee.svelte";
	import ModalManager from "../../../common/Modal/ModalManager.svelte";

  export let id;
  export let storeName;

  let idEmployee = undefined;
  let idManager = undefined;
  let modalAddEmployee;
  let modalAddManager;
  let modalConfirmDelete;
  let modalConfirmDeleteManager;
  
  let managerPage = 1,
		employeePage = 1,
		listPerPage = 5;

	let showManagerPaginate = false,
		isEndManagerPaginate = false,
		showEmployeePaginate = false,
		isEndEmployeePaginate = false;

  // employees
  let storeEmployees = [];
  let storeEmployeeIds = [];
  let totalEmployees = 0;

  // managers
  let storeManagers = [];
  let storeManagerIds = [];
  let totalManagers = 0;


  const filterDeletedUser = (user) => (user.profile.deletedAt ? false : true);

  let isLoading = false;
  // $: dataManagers = data.managers || [];

  // $: dataEmployees = data.employees || []; 
  onMount(() => {
		isLoading = true;
		loadStoreEmployees();
    loadStoreManagers();
		isLoading = false;
	});

  function handleDelete() {
    modalConfirmDelete.toggle();
    $connection
      .call("stores.remove.employees", id, idEmployee)
      .then((res) => {
        window.pushToast("Successfully Deleted Employee.", "success");
        employeePage = 1;
        loadStoreEmployees();
      })
      .catch((err) => {
        console.log("err", err);
        window.pushToast("Something Wrong! Cant Deleted Employee.", "danger");
      });
  }

  function handleDeleteManager() {
    modalConfirmDeleteManager.toggle();
    $connection
      .call("stores.remove.managers", id, idManager)
      .then((res) => {
        window.pushToast("Successfully Deleted Manager.", "success");
        managerPage = 1;
        loadStoreManagers();
      })
      .catch((err) => {
        console.log("err", err);
        window.pushToast("Something Wrong! Cant Deleted Manager.", "danger");
      });
  }

  let modalConfirmDeleteStore;

  function handleDeleteStore() {
    $connection
      .call("stores.remove", id)
      .then((res) => {
        setTimeout(() => {
          push("/stores");
        }, 3000);
        window.pushToast("Successfully Deleted Store.", "success");
      })
      .catch((err) => {
        console.log("err", err);
        window.pushToast("Something Wrong! Cant deleted Store.", "danger");
      });
  }

  function loadStoreEmployees() {
		// reset value
		showEmployeePaginate = false;
		isEndEmployeePaginate = false;
		storeEmployees = [];
		totalEmployees = 0;
		storeEmployeeIds = [];

		$connection
			.call("users.employees.byStore.pagination", id, employeePage, listPerPage)
			.then((result) => {
				storeEmployees = result.employees;
				totalEmployees = result.length;
				storeEmployeeIds = result.allEmployeesID;
				if (result.length > listPerPage) {
					showEmployeePaginate = true;
					isEndEmployeePaginate = Math.floor(result.totalPage) == employeePage;
				}
			})
			.catch((error) => {
				console.log(error);
				window.pushToast("Something Wrong!", "danger");
			});
	}
  function loadStoreManagers() {
        // reset value
        showManagerPaginate = false;
        isEndManagerPaginate = false;
        storeManagers = [];
        totalManagers = 0;
        storeManagerIds = [];

        $connection
			.call("users.store-managers.byStore.pagination", id, managerPage, listPerPage)
			.then((result) => {
        storeManagers = result.managers;
                totalManagers = result.length;
                storeManagerIds = result.allManagersID;
                if (result.length > listPerPage) {
                    showManagerPaginate = true;
                    isEndManagerPaginate = Math.floor(result.totalPage) == managerPage;
                }
			})
			.catch((error) => {
				console.log(error);
				window.pushToast("Something Wrong!", "danger");
			});
    }

  function showAddEmployee() {
    modalAddEmployee.open();
  }
  function showAddManagers() {
    modalAddManager.open();
  }
</script>

<Modal
  size="small"
  title="Delete Store"
  confirm
  bind:this={modalConfirmDeleteStore}
  on:submit={handleDeleteStore}
  on:cancel={() => {
    modalConfirmDeleteStore.toggle();
  }}
/>

<Modal
  size="small"
  title="Delete Employee"
  confirm
  bind:this={modalConfirmDelete}
  on:submit={handleDelete}
  closed
  on:close={() => {
    idEmployee = undefined;
    modalConfirmDelete.toggle();
  }}
  on:cancel={() => {
    idEmployee = undefined;
    modalConfirmDelete.toggle();
  }}
/>

<Modal
  title={`Delete Manager From Store ${storeName}`}
  confirm
  bind:this={modalConfirmDeleteManager}
  on:submit={handleDeleteManager}
  closed
  on:close={() => {
    idManager = undefined;
    modalConfirmDeleteManager.toggle();
  }}
  on:cancel={() => {
    idManager = undefined;
    modalConfirmDeleteManager.toggle();
  }}
/>

<ModalManager
	bind:this={modalAddManager}
  bind:storeId={id}
	bind:storeManagerIds
	on:load-data={() => {
		managerPage = 1;
		loadStoreManagers();
	}}
></ModalManager>

<ModalEmployee
	bind:this={modalAddEmployee}
  bind:storeId={id}
	bind:storeEmployeeIds
	on:load-data={() => {
		employeePage = 1;
		loadStoreEmployees();
	}}
></ModalEmployee>



{#if isLoading}
  <LoadingText />
{:else}
  <Tile title="Total Managers" info={totalManagers} />
  <div class="mt-5">
    <Button
      leftIcon="bx bx-plus"
      on:click={function () {
        showAddManagers()
      }}>Add Managers</Button
    >
    <table class="min-w-full divide-y divide-gray-200 mt-4">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Name
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Gender
          </th>
          <th
            scope="col"
            class="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          />
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        {#if storeManagers}
          {#if storeManagers.length == 0}
            <tr>
              <td
                colspan="3"
                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >No Manager Available</td
              >
            </tr>
          {/if}
          {#each storeManagers as manager}
            <tr>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                {manager.profile.name}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                {manager.profile.gender == "M" ? "Male" : "Female"}
              </td>
              <td class="whitespace-nowrap text-sm text-gray-800">
                <Button
                  leftIcon="bx bx-trash"
                  on:click={function () {
                    if (storeManagerIds.length == 1) {
                      alert("Must have at least 1 manager");
                    } else {
                      idManager = manager._id;
                      modalConfirmDeleteManager.toggle();
                    }
                  }}
                />
              </td>
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
    {#if storeManagers.length > 0}
    <div class="flex justify-between w-full mt-4">
        <div class="flex space-x-2">
            {#if showManagerPaginate}
                <Button
                    disabled={managerPage == 1}
                    on:click={async () => {
                        if (managerPage > 1) {
                            managerPage -= 1;
                            await loadStoreManagers();
                        }
                    }}>Prev</Button
                >
                <Button
                    disabled={isEndManagerPaginate}
                    on:click={async () => {
                        if (!isEndManagerPaginate) {
                            managerPage += 1;
                            await loadStoreManagers();
                        }
                    }}>Next</Button
                >
            {/if}
        </div>
    </div>
{/if}
  </div>

  <br />
  <Divider />
  <br />

  <Tile title="Total Employees" info={totalEmployees} />
  <div class="mt-5">
    <Button
      leftIcon="bx bx-plus"
      on:click={function () {
       showAddEmployee()
      }}>Add Employee</Button
    >
    <table class="min-w-full divide-y divide-gray-200 mt-4">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Name
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Gender
          </th>
          <th
            scope="col"
            class="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          />
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        {#if storeEmployees}
          {#if storeEmployees.length == 0}
            <tr>
              <td
                colspan="3"
                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >No Employee Available</td
              >
            </tr>
          {/if}
          {#each storeEmployees as employee}
            <tr>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                {employee.profile.name}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                {employee.profile.gender == "M" ? "Male" : "Female"}
              </td>
              <td class="whitespace-nowrap text-sm text-gray-800">
                <Button
                  leftIcon="bx bx-trash"
                  on:click={() => {
                    idEmployee = employee._id;
                    modalConfirmDelete.toggle();
                  }}
                />
              </td>
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
    {#if storeEmployees.length > 0}
      <div class="flex justify-between w-full mt-4">
          <div class="flex space-x-2">
              {#if showEmployeePaginate}
                  <Button
                      disabled={employeePage == 1}
                      on:click={async () => {
                          if (employeePage > 1) {
                              employeePage -= 1;
                              loadStoreEmployees();
                          }
                      }}>Prev</Button
                  >
                  <Button
                      disabled={isEndEmployeePaginate}
                      on:click={async () => {
                          if (!isEndEmployeePaginate) {
                              employeePage += 1;
                              loadStoreEmployees();
                          }
                      }}>Next</Button
                  >
              {/if}
          </div>
      </div>
{/if}
  </div>

  <div class="absolute mr-3 mt-2 top-0 right-0 flex space-x-2">
    <Button leftIcon="bx bx-edit" on:click={push(`/stores/${id}/edit`)}
      >Edit</Button
    >
    <Button
      leftIcon="bx bx-trash"
      on:click={() => modalConfirmDeleteStore.toggle()}>Delete</Button
    >
    <Button
      leftIcon="bx bx-notification"
      on:click={() => {
        $connection
          .call("stores.activate", id)
          .then((res) => {
            window.pushToast("Store now Activated.", "success");
          })
          .catch((err) => {
            console.log("err", err);
            window.pushToast("Something Wrong!", "danger");
          });
      }}>Activate</Button
    >
    <Button
      leftIcon="bx bx-notification-off"
      on:click={() => {
        () => {
          $connection
            .call("stores.deactivate", id)
            .then((res) => {
              window.pushToast("Store now Deactivated.", "success");
            })
            .catch((err) => {
              console.log("err", err);
              window.pushToast("Something Wrong!", "danger");
            });
        };
      }}>Deactivate</Button
    >
  </div>
{/if}

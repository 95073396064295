<script>
  import url from "./js/ReactiveUrl";
  import { link } from "svelte-spa-router";
  import { fade } from "svelte/transition";
  import active from "svelte-spa-router/active";
  import Footer from "./Footer.svelte";
  import Logo from "./Logo.svelte";

  let className =
    "px-2 text-gray-800 bg-gray-100 font-medium rounded-xl hover:text-gray-800 dark:text-white dark:bg-grey-400 dark:hover:opacity-90";
  let inactiveClassName =
    "text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt";

  let config = "hidden";
  let location = "hidden";
  let product = "hidden";
  let inventory = "hidden";
  let purchasing = "hidden";
  function isMenuHiddenFor(hash, options) {
    if (options.some((op) => `${hash}`.includes(op))) {
      return "";
    }
    return "hidden";
  }

  $: {
    if ($url?.hash) {
      config = isMenuHiddenFor($url.hash, ["/payment-types/"]);
      location = isMenuHiddenFor($url.hash, ["/stores/", "/warehouses/"]);
      product = isMenuHiddenFor($url.hash, [
        "/products/",
        "/modifier/",
        "/category/",
        "/menu/",
      ]);
      inventory = isMenuHiddenFor($url.hash, [
        "/items/",
        "/stockopname/",
        "/stock-transfer/",
      ]);
      purchasing = isMenuHiddenFor($url.hash, [
        "/vendors/",
        "/purchasing-order/",
        "/receiving/",
      ]);
    }
  }
</script>

<nav
  class="hidden md:block md:w-64 shadow-lg bg-white relative dark:bg-dark-third overflow-hidden"
>
  <div
    class="flex flex-wrap flex-col flex-no-wrap md:items-stretch items-top justify-between mx-auto h-full"
  >
    <!-- Sidebar Menu -->
    <div class="flex w-full justify-center pt-2">
      <Logo width="100pt" height="70pt" />
    </div>
    <ul
      class="py-3 px-5 flex-col flex-1 list-none space-y-2 overflow-auto scrollbar-thin scrollbar-thumb-gray-300"
    >
      <li class="relative">
        <a
          use:link
          use:active={{ path: "/", className, inactiveClassName }}
          href="/"
          class="flex items-center text-xs py-2 text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt"
        >
          <i class="bx bx-tachometer bx-sm text-primary mr-4" />
          Dashboard
        </a>
      </li>
      <li class="relative">
        <i
          class="absolute top-3 right-0 bx bx-chevron-right text-primary transition duration-200 ease-linear {location ==
          'hidden'
            ? 'transform rotate-90'
            : ''}"
        />
        <a
          use:link
          href="/stores/"
          use:active
          class="flex items-center text-xs py-2"
        >
          <i class="bx bx-store bx-sm text-primary mr-4" />
          Location
        </a>
        <ul
          class="{location} md:flex-col md:min-w-full flex flex-col list-none space-y-2 ml-3 border-l border-gray-400"
        >
          <li class="items-center px-2">
            <a
              use:link
              href="/stores/"
              use:active={{ path: "/stores/*", className, inactiveClassName }}
              class="flex items-center text-xs py-2"
            >
              <i class="bx bx-store bx-sm text-primary mr-4" />
              Store
            </a>
          </li>
        </ul>
      </li>
      <li class="relative">
        <i
          class="absolute top-3 right-0 bx bx-chevron-right text-primary transition duration-200 ease-linear {product ==
          'hidden'
            ? 'transform rotate-90'
            : ''}"
        />
        <a use:link href="/products/" class="flex items-center text-xs py-2">
          <i class="bx bx-package bx-sm text-primary mr-4" />
          Product
        </a>
        <ul
          class="{product} md:flex-col md:min-w-full flex flex-col list-none space-y-2 ml-3 border-l border-gray-400"
        >
          <li class="items-center px-2">
            <a
              use:link
              href="/products/"
              use:active={{ path: "/products/*", className, inactiveClassName }}
              class="flex items-center text-xs py-2"
            >
              <i class="bx bx-package bx-sm text-primary mr-4" />
              Product
            </a>
          </li>
          <li class="items-center px-2">
            <a
              use:link
              href="/modifier/"
              use:active={{ path: "/modifier/*", className, inactiveClassName }}
              class="flex items-center text-xs py-2"
            >
              <i class="bx bx-package bx-sm text-primary mr-4" />
              Modifier
            </a>
          </li>
          <li class="items-center px-2">
            <a
              use:link
              href="/category/"
              use:active={{ path: "/category/*", className, inactiveClassName }}
              class="flex items-center text-xs py-2"
            >
              <i class="bx bx-package bx-sm text-primary mr-4" />
              Category
            </a>
          </li>
          <li class="items-center px-2">
            <a
              use:link
              href="/menu/"
              use:active={{ path: "/menu/*", className, inactiveClassName }}
              class="flex items-center text-xs py-2"
            >
              <i class="bx bx-package bx-sm text-primary mr-4" />
              Menu
            </a>
          </li>
        </ul>
      </li>

      <li class="relative">
        <a
          use:link
          href="/users/"
          use:active={{ path: "/users/*", className, inactiveClassName }}
          class="flex items-center text-xs py-2"
        >
          <i class="bx bx-group bx-sm text-primary mr-4" />
          User
        </a>
      </li>
    </ul>
    <!-- end Sidebar Menu -->
    <div class="bg-gray-50">
      <Footer />
    </div>
  </div>
</nav>

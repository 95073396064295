<script>
  import { link, location } from "svelte-spa-router";
  import active from "svelte-spa-router/active";

  import Button from "./control/Button.svelte";

  location.subscribe((e) => {
    let patt = new RegExp(`/users.*/g`);
  });

  function isActive(route) {
    let patt = new RegExp(`\/${route}.*`, "g");
    const result = patt.test($location);
    return result;
  }

  export let showSidebar;

  // let className = 'px-2 text-white bg-gray-400 rounded hover:text-gray-600 dark:text-white dark:hover:text-dark-txt';
  let className =
    "px-2 text-gray-800 bg-gray-200 rounded hover:text-gray-800 dark:text-white dark:bg-grey-400 dark:hover:opacity-90";
  let inactiveClassName =
    "text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt";
</script>

<nav
  class="absolute w-full {!showSidebar
    ? 'hidden'
    : ''}  top-0 overflow-y-auto shadow-xl bg-white flex-wrap items-center justify-between md:w-64 z-10 px-6 dark:bg-dark-third"
>
  <Button
    leftIcon="bx bx-x"
    on:click={() => (showSidebar = !showSidebar)}
    extClass="absolute top-0 right-0 mt-2 mr-2"
  />
  <div class="px-5 py-4 w-full text-center">
    <!-- Sidebar Menu -->
    <h1 class="leading-tight text-xl font-semibold mb-4">Kopi Bajawa Flores</h1>
    <ul class="md:flex-col md:min-w-full flex flex-col list-none">
      <li class="items-center border-b-2 border-t-2 border-gray-200">
        <a
          use:link
          use:active={{ path: "/", className, inactiveClassName }}
          href="/"
          class="flex items-center text-xs py-2 text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt"
        >
          <i class="bx bx-tachometer bx-sm text-primary mr-4" />
          Dashboard
        </a>
      </li>
      <li class="items-center border-b-2 border-gray-200">
        <a
          use:link
          href="/stores"
          class="flex items-center text-xs py-2 {isActive('stores')
            ? className
            : inactiveClassName}"
        >
          <i class="bx bx-store bx-sm text-primary mr-4" />
          Store
        </a>
      </li>
      <li class="items-center border-b-2 border-gray-200">
        <a
          use:link
          use:active={{
            path: "/products/*" | "/products",
            className,
            inactiveClassName,
          }}
          href="/products"
          class="flex items-center text-xs py-2 text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt"
        >
          <i class="bx bx-package bx-sm text-primary mr-4" />
          Product
        </a>
      </li>
      <li class="items-center border-b-2 border-gray-200">
        <a
          use:link
          use:active={{
            path: "/modifier/*" | "/modifier",
            className,
            inactiveClassName,
          }}
          href="/modifier/"
          class="flex items-center text-xs py-2 text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt"
        >
          <i class="bx bx-package bx-sm text-primary mr-4" />
          Modifier
        </a>
      </li>
      <li class="items-center border-b-2 border-gray-200">
        <a
          use:link
          use:active={{
            path: "/campaigns/*" | "/campaigns",
            className,
            inactiveClassName,
          }}
          href="/campaigns"
          class="flex items-center text-xs py-2 text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt"
        >
          <i class="bx bx-calendar-heart bx-sm text-primary mr-4" />
          Campaign
        </a>
      </li>
      <li class="items-center border-b-2 border-gray-200">
        <a
          use:link
          use:active={{
            path: "/promos/*" | "/promos",
            className,
            inactiveClassName,
          }}
          href="/promos"
          class="flex items-center text-xs py-2 text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt"
        >
          <i class="bx bx-purchase-tag bx-sm text-primary mr-4" />
          Promo
        </a>
      </li>
      <li class="items-center border-b-2 border-gray-200">
        <a
          use:link
          use:active={{
            path: "/posts/*" | "/posts",
            className,
            inactiveClassName,
          }}
          href="/posts"
          class="flex items-center text-xs py-2 text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt"
        >
          <i class="bx bx-edit bx-sm text-primary mr-4" />
          Post
        </a>
      </li>
      <li class="items-center border-b-2 border-gray-200">
        <a
          href="/"
          class="flex items-center text-xs py-2 text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt"
        >
          <i class="bx bx-medal bx-sm text-primary mr-4" />
          Gamification
        </a>
      </li>
      <li class="items-center border-b-2 border-gray-200">
        <a
          use:link
          href="/users"
          class="flex items-center text-xs py-2 {isActive('users')
            ? className
            : inactiveClassName}"
        >
          <i class="bx bx-group bx-sm text-primary mr-4" />
          User
        </a>
      </li>
      <li class="items-center border-b-2 border-gray-200">
        <a
          use:link
          use:active={{
            path: "/roles/*" | "/roles",
            className,
            inactiveClassName,
          }}
          href="/roles"
          class="flex items-center text-xs py-2 text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt"
        >
          <i class="bx bx-id-card bx-sm text-primary mr-4" />
          Role
        </a>
      </li>
      <li class="items-center border-b-2 border-gray-200">
        <a
          href="/"
          class="flex items-center text-xs py-2 text-gray-800 hover:text-gray-600 dark:text-white dark:hover:text-dark-txt"
        >
          <i class="bx bx-data bx-sm text-primary mr-4" />
          Master Data
        </a>
      </li>
    </ul>
    <!-- end Sidebar Menu -->
  </div>
</nav>

<script>
	import { validateInput, validateErrorField } from "../helper/FormHelper";
	import Cleave from "cleave.js";
	import { onMount } from "svelte";
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	onMount(() => {
		const inputArr = document.getElementsByClassName("input-money");
		const fields = Array.from(inputArr);

		fields.forEach(function (field) {
			new Cleave(field, {
				numeral: true,
				prefix: "Rp. ",
				numeralDecimalMark: ",",
				delimiter: ".",
			});
		});
	});

	export let label = "";
	export let placeholder = "";
	export let value = "";
	// let valueX = 0;
	export let name;
	export let form;
	export let disabled = false;
	export let noLabel = false;
	export let hint = false;
	export let hintText = "";
	export let errorMessageMax = null;
	export let errorMessageMin = null;
	export let errors = [];

	$: if (errors.length > 0) {
		errors = errors
			.filter((err) => err.split(".")[0] == name)
			.map((e) => ({
				field: e.split(".")[0],
				error: e.split(".")[1],
			}));
		errors = errors;
	}

	function handleInput(evt) {
		const { target } = evt;
		value = target.value;
	}
</script>

{#if !noLabel}
	<label class="block text-primary text-sm font-bold mb-2" for={value}>
		{label}
	</label>
{/if}

<div class="relative">
	<input
		type="text"
		{value}
		{name}
		step="0.01"
		class="input-money appearance-none {form && validateErrorField($form.fields, name)
			? 'border-red-500'
			: ''} block w-full text-sm text-dark-second border dark:text-white dark:bg-dark-third rounded py-2 px-3 {disabled
			? ' text-gray-500 border-gray-400'
			: 'border-gray-800'} focus:ring-transparent focus:border-gray-800"
		{placeholder}
		{disabled}
		on:input={handleInput}
		on:change={dispatch("change", value)}
	/>
	<div class="absolute top-1.5 right-2">
		<slot />
	</div>
</div>

{#if errors.length > 0 && errors[0]["error"] == "required"}
	<p class="text-red-500 text-xs italic">Please fill out this field.</p>
{/if}
{#if errors.length > 0 && errors[0]["error"] == "max"}
	<p class="text-red-500 text-xs italic">{errorMessageMax || "Value has been reach maximal number"}</p>
{/if}
{#if errors.length > 0 && errors[0]["error"] == "min"}
	<p class="text-red-500 text-xs italic">{errorMessageMin || "Value has been reach minimal number"}</p>
{/if}

{#if hint}
	<p class="text-gray-600 text-xs italic">{hintText}</p>
{/if}

import { readonly, writable } from "svelte/store";
import { connect } from "./connection";
import { writable as wr } from "svelte-local-storage-store";
export const transactions = wr("transactions", localStorage.getItem("transactions")
    ? JSON.parse(localStorage.getItem("transactions"))
    : []);
const storedUser = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
export const user = writable(storedUser);
export const store = writable(null);
export const connection = writable(connect());
export const loggedInUserId = writable(null);
export const trueUser = writable(null);
export const readableTrueUser = readonly(trueUser);

<script>
	import Utility from "../Util/Utility";
	export let title = "Total Income";
	export let content = 0;
	export let type = "number";
</script>

<div class="flex flex-col md:flex-row w-full h-32 border border-gray-400">
	<div class="flex w-full md:w-1/3 h-full items-center justify-center bg-primary text-white">
		<p>{title}</p>
	</div>
	<div class="flex w-full md:w-2/3 items-center justify-center text-xl md:text-3xl font-light py-3 md:p-0">
		{type == "money" ? Utility.formatRupiah(content, "Rp. ") : content}
	</div>
</div>

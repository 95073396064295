<script>
    import { link } from 'svelte-spa-router';
    import Header from '../../../common/Header.svelte';

    export let page = 'stores';
    export let id;
</script>

<Header title="Menu Information" />
<div class="p-4">
    <div class="mb-4">
        <ul class="flex cursor-pointer space-x-2">
            <li
                class={`py-2 px-6 bg-gray-100 rounded ${
                    page == 'stores' ? 'text-white bg-gray-400' : 'hover:opacity-90'
                }`}
            >
                <a use:link href="/menu/{id}/stores">Stores</a>
            </li>
            <li
                class="py-2 px-6 bg-gray-100 rounded  {page == 'detail' || page == 'edit'
                    ? 'text-white bg-gray-400'
                    : 'hover:opacity-90'}"
            >
                <a use:link href="/menu/{id}/detail">Detail {page == 'edit' ? '> Edit' : ''}</a>
            </li>
        </ul>
    </div>
    <slot />
</div>

<script>
  import { createEventDispatcher } from "svelte";
  import { onMount } from "svelte";
  const dispatch = createEventDispatcher();

  onMount(() => {
    const inputArr = document.getElementsByClassName("input-lat-long");
  });

  export let errors = [];
  export let label = "";
  export let placeholder = "";
  export let value = "";
  // let valueX = 0;
  export let name;
  export let type = "text";
  export let maxlength = 100;
  export let minlength = 1;
  export let min = "";
  export let max = "";
  export let disabled = false;
  export let noLabel = false;
  export let hint = false;
  export let hintText = "";
  export let errorMessageMax = null;
  export let errorMessageMin = null;

  $: if (errors.length > 0) {
    errors = errors
      .filter((err) => err.split(".")[0] == name)
      .map((e) => ({
        field: e.split(".")[0],
        error: e.split(".")[1],
      }));
    errors = errors;
  }

  const handleInput = (evt) => {
		const { target } = evt;
		switch (type) {
			case "number":
				value = parseFloat(target.value ? target.value : 0);
				if (min && value < min) value = min;
				break;
			default:
				value = target.value;
		}
	};
</script>

{#if !noLabel}
  <label
    class="block uppercase mb-2 text-primary dark:text-white text-xs font-bold"
    for={value}
  >
    {label}
  </label>
{/if}

<div class="relative">
  <input
    {type}
    {value}
    {maxlength}
    {minlength}
    {min}
    onkeypress={'return (event.charCode != 8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 110 || event.charCode == 190 || event.charCode == 46)'}
    {max}
    {name}
    class="input-lat-long appearance-none {errors.length > 0
      ? 'border-red-500'
      : ''} block w-full text-sm text-dark-second border dark:text-white dark:bg-dark-third rounded py-2 px-3 focus:ring-transparent focus:border-gray-800 {disabled
      ? ' text-gray-500 border-gray-400'
      : 'border-gray-800'}"
    {placeholder}
    {disabled}
    on:input={handleInput}
    on:change={dispatch("change", value)}
  />
  <div class="absolute top-1.5 right-2">
    <slot />
  </div>
</div>
<!-- note for onkeypress, 
 8 means backspace and 0 means keys that dont produce a character. Some exception exist like backspace
 >=48 and <=57 means it only allow digit input from 0-9. 
 110 means decimal places '.' on numpad
 190 means decimal places '.' on standard keyboard
 46 means delete key
 -->
{#if errors.length > 0 && errors[0]["error"] == "required"}
  <p class="text-red-500 text-xs italic">Please fill out this field.</p>
{:else if errors.length > 0 && errors[0]["error"] == "duplicate"}
  <p class="text-red-500 text-xs italic">Value can't have any duplicates</p>
{:else if errors.length > 0 && errors[0]["error"] == "max"}
  <p class="text-red-500 text-xs italic">
    {errorMessageMax || "Value has been reach maximal number"}
  </p>
{:else if errors.length > 0 && errors[0]["error"] == "min"}
  <p class="text-red-500 text-xs italic">
    {errorMessageMin || "Value has been reach minimal number"}
  </p>
{/if}

{#if hint}
  <p class="text-gray-600 text-xs italic">{hintText}</p>
{/if}

<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();
	import Input from "../../../common/control/Input.svelte";
	import MoneyInput from "../../../common/control/MoneyInput.svelte";
	import Button from "../../../common/control/Button.svelte";
	import TextArea from "../../../common/control/TextArea.svelte";
	import Header from "../../../common/Header.svelte";
	import { formatNumber } from "../../../common/helper/FormHelper";
	import Util from "../../../common/Util/Utility";
	import Switch from "../../../common/control/Switch.svelte";

	export let id;
	export let name;
	export let desc;
	export let items = [];
	export let errors = [];
	export let isRequired;

	let childrenWithNoSKUCode = [];

	function handleAddItem() {
		// need to add code
		items.push({ name: "", sku: "", price: 0, default: false, code: Util.generateId(6) });
		items = items;
	}

	function handleItemSKUChange(index, value) {
		items[index].sku = value;
		items = items;
	}

	function handleItemNameChange(index, value) {
		items[index].name = value;
		items = items;
	}

	function handleItemPriceChange(index, value) {
		items[index].price = value;
		items = items;
	}

	function handleToggleItemDefault(index) {
		items[index].default = !items[index].default;
		items = items;
	}

	function handleSetItemDefault(index) {
		if (items.filter((it) => it.default == true).length == 0) {
			items[index].default = true;
			items = items;
		} else {
			items.map((it) => {
				if (it.default == true) {
					it.default = false;
				}
			});
			items[index].default = true;
			items = items;
		}
	}

	function handleRemoveItem(index) {
		items = items.filter((item, idx) => idx != index);
	}
</script>

<Header title={id ? "Edit Modifier" : "Add Modifier"}>
	<Button
		leftIcon="bx bx-save"
		on:click={() => {
			childrenWithNoSKUCode = []; // reset value;
			// validate for required children SKU
			const childrenWithNoSKU = items.filter((it) => !it.sku);
			if (childrenWithNoSKU.length > 0) {
				childrenWithNoSKUCode = childrenWithNoSKU.map((it) => it.code);
				window.pushToast("Something Wrong! Please check again form field.", "danger");
				return;
			}

			const itemDefaultCheck = items.find((e) => e["default"] == true); // find the default child
			if (itemDefaultCheck == null) {
				window.pushToast("Default Modifier is Required", "danger");
				return;
			}

			const namedItems = items.filter((it) => it.name !== ""); // filter named child
			if (itemDefaultCheck.name == "" || namedItems.length == 0) {
				window.pushToast("Required Items min 1.", "danger");
				return;
			}

			const itemMap = namedItems.map((item) => {
				let newPrice;
				if (item["price"].toString().includes("Rp.")) {
					newPrice = formatNumber(item.price);
				} else {
					newPrice = item.price;
				}
				return {
					name: item.name,
					sku: item.sku,
					price: isNaN(newPrice) ? 0 : parseFloat(newPrice.toString().replace(/[^0-9\,]+/g, "") ?? 0),
					default: item.default,
					code: item.code,
				};
			});

			dispatch("submit", { name, desc, itemMap, isRequired });
		}}
	>
		Save Change
	</Button>
</Header>
<div class="space-y-2 p-4">
	<div class="flex-1 mr-1">
		<Input bind:errors label="Name" placeholder="ex: Ice" bind:value={name} name="name" />
	</div>
	<div class="flex-1 mr-1">
		<TextArea bind:errors label="Description" placeholder="ex: add ice" bind:value={desc} name="desc" />
	</div>
	<div>
		<Switch
			bind:value={isRequired}
			checked={isRequired}
			name="status"
			text="Apakah Modifier Wajib?"
			textRight={isRequired ? "Modifier Wajib" : "Modifier Tidak Wajib"}
			on:change={(e) => {
				isRequired = !isRequired;
			}}
		/>
	</div>
	<div class="flex-1 space-y-2">
		<label>Items</label>
		{#each items as item, index}
			<div>
				<div class="flex items-start space-x-2">
					<div class="flex rounded border bg-gray-100 items-center justify-center w-10 h-10">
						{index + 1}
					</div>
					<div class="flex-1">
						<Input
							noLabel
							placeholder="name ex: medium"
							value={item.name}
							name="item-name"
							on:change={(e) => handleItemNameChange(index, e.detail)}
						/>
					</div>
					<div class="flex flex-col flex-1">
						<Input
							maxlength={10}
							extClass={childrenWithNoSKUCode.includes(item.code) ? "border-red-500" : ""}
							noLabel
							placeholder="sku ex: 001"
							value={item.sku ?? ""}
							name="sku"
							on:change={(e) => handleItemSKUChange(index, e.detail)}
						/>
						{#if childrenWithNoSKUCode.includes(item.code)}
							<p class="text-red-500 text-xs italic w-32">Please fill out this field.</p>
						{/if}
					</div>
					<div class="flex-1">
						<MoneyInput
							noLabel
							placeholder="price ex: 1000"
							value={item.price}
							name="item-price"
							on:change={(e) => handleItemPriceChange(index, e.detail)}
						/>
					</div>
					<div class="flex space-x-2 flex-1 items-center">
						{#if item.default}
							<Button status="success" on:click={() => handleToggleItemDefault(index)}>Default</Button>
						{:else}
							<Button on:click={() => handleSetItemDefault(index)}>Set as default</Button>
						{/if}
						<Button leftIcon="bx bx-trash" on:click={() => handleRemoveItem(index)} />
					</div>
				</div>
			</div>
		{/each}
		<Button extClass="mt-2" on:click={handleAddItem}>Add new item</Button>
	</div>
</div>

<script>
	import Layout from "./templates/LayoutTemplate.svelte";
	import Template from "./templates/StoresTemplate.svelte";
	import LoadingText from "../../common/LoadingText.svelte";
	import { connection } from "../../store";
	import { createEventDispatcher } from "svelte";

	export let params = {};
	const dispatch = createEventDispatcher();
	const { _id } = params;
	let isLoading = true;
	let dataId;
	let data;
	async function loadData(dataId) {
		isLoading = true;
		await $connection
			.call("menus.stores", dataId)
			.then((result) => {
				data = result;
			})
			.catch((error) => {
				console.log(error);
			});
		isLoading = false;
	}

	$: if (params) {
		dataId = params._id;
		loadData(dataId);
	}

	function routeEvent() {
		loadData(dataId);
		dispatch("routeEvent");
	}
</script>

{#if isLoading && !data}
	<LoadingText />
{:else}
	<Layout id={_id} page="stores">
		{#if data}
			<Template id={_id} {data} on:reload-data={routeEvent} />
		{:else}
			<p>Data Not Found</p>
		{/if}
	</Layout>
{/if}

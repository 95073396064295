<script>
	import { push } from "svelte-spa-router";
	import Button from "../../../common/control/Button.svelte";
	import { connection } from "../../../store";
	import { formatRupiah } from "../../../common/helper/FormHelper";
	export let data;
	export let id;
</script>

<div class="mt-5">
	<table class="min-w-full divide-y divide-gray-200">
		<thead class="bg-gray-50">
			<tr>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Campaign </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Contribution </th>
			</tr>
		</thead>
		<tbody class="bg-white divide-y divide-gray-200">
			{#if data.length == 0}
				<tr>
					<td colspan="2" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">No Campaign Available</td>
				</tr>
			{/if}
			{#each data as campaign}
				<tr>
					<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{campaign.name}</td>
					<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
						{formatRupiah(
							campaign.products.filter((c) => c._id == id).map((res) => res.contribution),
							"Rp. "
						)}
					</td>
				</tr>
			{/each}
		</tbody>
	</table>
</div>

<div class="absolute top-0 right-0 mr-3 mt-2 flex space-x-2">
	<Button leftIcon="bx bx-edit" on:click={push(`/products/${id}/edit`)}>Edit</Button>
	<!-- <Button leftIcon="bx bx-trash" on:click={() => modalConfirmDelete.toggle()}>Delete</Button> -->
	<Button
		leftIcon="bx bx-notification"
		on:click={() =>
			$connection
			.call("products.activate", id)
				.then(() => {
					window.pushToast("Product now Activated", "success");
				})
				.catch((err) => {
					alert(err);
				})}>Activate</Button
	>
	<Button
		leftIcon="bx bx-notification-off"
		on:click={() =>
			$connection
				.call("products.deactivate", id)
				.then(() => {
					window.pushToast("Product now Deactivated", "success");
				})
				.catch((err) => {
					alert(err);
				})}>Deactivate</Button
	>
</div>

<script>
  import url from "../../../common/js/ReactiveUrl";
  import Modal from "../../../common/Modal.svelte";
  import Button from "../../../common/control/Button.svelte";
  import Header from "./HeaderTemplate.svelte";
  import { link } from "svelte-spa-router";
  import { createEventDispatcher } from "svelte";
  import { noImg } from "../../../config";
  export let profile;
  export let roles = [];

  export let id;
  export let title = "";

  const dispatch = createEventDispatcher();
  let modal = {
    header: "",
    body: null,
    onSubmit: () => {},
  };
  let modalRef;

  function handleRemove() {
    modal = {
      header: "Delete User",
      body: "Are you sure to move this user to trash? This action will also remove user from employee data on store.",
      onSubmit: (e) => {
        dispatch("remove");
        modalRef.toggle();
      },
    };
    modalRef.toggle();
  }

  function handleResetPassword() {
    modal = {
      header: "Reset Password",
      body: "Are you sure to reset this user's password?",
      onSubmit: (e) => {
        dispatch("password-reset");
        modalRef.toggle();
      },
    };
    modalRef.toggle();
  }

  function handleRestore() {
    modal = {
      header: "Restore User",
      body: "Are you sure to restore this user?",
      onSubmit: (e) => {
        dispatch("restore");
        modalRef.toggle();
      },
    };
    modalRef.toggle();
  }

  function handleRemovePermanent() {
    modal = {
      header: "Delete User Permanently",
      body: "Are you sure to delete this user permanently?",
      onSubmit: (e) => {
        dispatch("remove-permanent");
        modalRef.toggle();
      },
    };
    modalRef.toggle();
  }

  function handleSendVerification() {
    modal = {
      header: "Email Verification",
      body: "Are you sure to send email verification?",
      onSubmit: (e) => {
        dispatch("verification");
        modalRef.toggle();
      },
    };
    modalRef.toggle();
  }

  //   $: hrefTransactions = `/users/${id}/info`;
  $: hrefDetails = `/users/${id}/info`;
</script>

<Modal bind:this={modalRef} title={modal.header} size="small" index="20">
  <div class="text-center mt-4 mb-6">
    {modal.body}
  </div>
  <div class="flex justify-end space-x-2">
    <Button on:click={() => modalRef.toggle()} status="danger">No</Button>
    <Button on:click={modal.onSubmit} status="success">Yes</Button>
  </div>
</Modal>
<Header
  title="User Information"
  {profile}
  on:edit={() => dispatch("edit")}
  on:verification={handleSendVerification}
  on:password-reset={handleResetPassword}
  on:restore={handleRestore}
  on:remove={handleRemove}
  on:remove-permanent={handleRemovePermanent}
/>
<div class="relative bg-gray-50">
  <div class="flex flex-col md:flex-row items-center p-4">
    <img
      class="w-20 h-20 object-cover"
      src={profile && profile.image ? profile.image : noImg}
      alt="profile"
    />
    <div class="flex flex-col ml-0 md:ml-3 text-center md:text-left p-4 md:p-0">
      <p class="text-md font-medium">{profile?.name}</p>
      <p class="capitalize text-gray-500">{roles.join(", ")}</p>
      <p>
        status: <span
          class="{profile?.deletedAt
            ? 'text-red-500'
            : 'text-green-500'} font-medium"
          >{#if profile?.deletedAt}Deleted{:else}Active{/if}</span
        >
      </p>
    </div>
  </div>
  <div class="p-2">
    <ul class="flex cursor-pointer space-x-2">
      <li
        class={`py-2 bg-gray-100 rounded ${
          $url.hash === `#${hrefDetails}`
            ? "text-white bg-gray-400"
            : "hover:opacity-90"
        }`}
      >
        <a use:link href={hrefDetails} class="py-2 px-6"> Detail </a>
      </li>
    </ul>
  </div>
</div>
<slot />

<script>
  import { version, lastUpdate } from "../config";
  // make dynamic date to be added to footer
  let date = new Date().getFullYear();
</script>

<footer class="h-full border-t">
  <div class="container mx-auto px-5 py-3">
    <!-- <hr class="mb-4 border-b-1 border-gray-300" /> -->
    <div
      class="flex flex-col flex-wrap items-start justify-start w-full text-gray-500 text-xs font-light"
    >
      <p class="font-normal pb-1">Version: {version}</p>
      <p class="font-normal pb-1">Update: {lastUpdate}</p>
      <p class="font-bold">© Pesona Dari Timor</p>
      <!-- <p class="text-gray-400">
        We're provider of quality IT solutions and reliable service in software
        dev.
      </p> -->
      <!-- <a href >Terms and Conditions</a>
          <div class="mx-2 h-3 w-0.5 bg-gray-300"/>
          <a href >Privacy Policy</a>
          <div class="mx-2 h-3 w-0.5 bg-gray-300"/>
          <a href class="mr-4">Cookies Policy</a>
          Copyright © {date} PT Kreatifitas Sinergisme Teknoindo -->
    </div>
  </div>
</footer>

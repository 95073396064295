<script>
  import Input from "../../../common/control/Input.svelte";
  import CustomInput from "../../../common/control/CustomInput.svelte";
  import PhoneInput from "../../../common/control/PhoneInput.svelte";
  import Select from "../../../common/control/Select.svelte";
  import Button from "../../../common/control/Button.svelte";
  import Switch from "../../../common/control/Switch.svelte";
  import TextArea from "../../../common/control/TextArea.svelte";
  import Header from "./HeaderTemplate.svelte";
  import DatePicker from "../../../common/control/DatePicker.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import utils from "../../../utils";
  import { connection } from "../../../store";
  import LoadingText from "../../../common/LoadingText.svelte";
  const dispatch = createEventDispatcher();

  export let id;
  export let managerIds = [];
  export let name;
  export let address;
  export let desc;
  export let latitude;
  export let longitude;
  export let status = false;
  export let isOpen = true;
  export let phone;
  export let tax = 10;
  export let serviceCharge = 15;
  export let rounding = "DOWN";
  export let formTitle;
  export let discounts = [];
  export let prefix = "";
  export let errors = [];

  $: discountList = discounts;

  let managerId;
  let managers = [];
  let discNumber = null;

  let isLoading = false;

  export let operationalHours = [
    {
      day: "monday",
      open: "09:00",
      closed: "21:00",
    },
    {
      day: "tuesday",
      open: "09:00",
      closed: "21:00",
    },
    {
      day: "wednesday",
      open: "09:00",
      closed: "21:00",
    },
    {
      day: "thursday",
      open: "09:00",
      closed: "21:00",
    },
    {
      day: "friday",
      open: "09:00",
      closed: "21:00",
    },
    {
      day: "saturday",
      open: "09:00",
      closed: "21:00",
    },
    {
      day: "sunday",
      open: "09:00",
      closed: "21:00",
    },
  ];

  onMount(() => {
    loadManagers();
  });

  let userSub = $connection.subscribe("users.stores-managers");

  async function loadManagers() {
    await userSub.ready();
    let userCollection = $connection.collection("users").reactive();

    managers = userCollection.data();
    managers = managers.map((e) => ({ ...e, _id: e.id }));
  }

  function mapOptions(item) {
    return {
      id: item._id,
      name: item.profile.name,
    };
  }

  function handleSubmit() {
    const phoneMap = {
      code: "62",
      number:
        phone != "" && phone != "NaN" && phone != NaN && phone != null
          ? utils.formatNumber(phone).toString()
          : "",
    };
    const validateOP = operationalHours.filter((op) => {
      const open = op.open.split(":");
      const closed = op.closed.split(":");
      return open[0] > closed[0];
    }).length;

    if (validateOP > 0) {
      window.pushToast(
        "Operational Hour Open must be less than Closed.",
        "danger"
      );
    } else if (tax < 0) {
      window.pushToast("Tax can not less than zero.", "danger");
    } else if (serviceCharge < 0) {
      window.pushToast("Tax can not less than zero.", "danger");
    } else {
      dispatch("submit", {
        managerIds,
        name,
        address,
        desc,
        status,
        isOpen,
        latitude,
        longitude,
        operationalHours,
        phoneMap,
        tax,
        serviceCharge,
        rounding,
        discountList,
        prefix,
      });
    }
  }
</script>

{#if isLoading}
  <LoadingText />
{:else}
  <Header title={formTitle}>
    <Button leftIcon="bx bx-save" on:click={() => handleSubmit()}
      >Save Changes</Button
    >
  </Header>
  <div class="flex flex-col space-y-2 p-4">
    <div class="flex flex-row space-x-2">
      <div class="flex-1">
        <Input
          bind:errors
          label="Name"
          type="text"
          maxlength={100}
          bind:value={name}
          name="name"
        />
      </div>
      <div class="flex-1">
        <Input
          bind:errors
          label="Prefix Transaction Midtrans"
          maxlength={5}
          type="text"
          bind:value={prefix}
          name="prefix"
        />
      </div>
    </div>

    <div class="flex-1">
      <PhoneInput
        bind:errors
        label="Phone"
        bind:value={phone}
        maxlength={20}
        name="phone"
      />
    </div>

    <div class="flex-1">
      <TextArea
        bind:errors
        label="Address"
        name="address"
        bind:value={address}
        maxlength={150}
      />
    </div>

    {#if !id && managerIds}
      <div class="flex-1 flex space-x-2 items-center">
        <div class="flex-1">
          <Select
            bind:errors
            name="managerIds"
            addButton={true}
            on:actionAdd={(e) => {
              if (managerId) {
                managerIds = [...managerIds, managerId];
                managerId = undefined;
              }
            }}
            textHint="Choose Manager"
            options={managers
              .filter(
                (m) =>
                  m.profile.name !== "Admin" &&
                  !m.profile.deletedAt &&
                  !managerIds.includes(m._id)
              )
              .map((item) => mapOptions(item))}
            label="Default Managers"
            selected={managerId}
            fullWidth
            on:change={(e) => {
              managerId = e.detail;
            }}
          />
        </div>
      </div>

      <div class="flex-1 flex gap-2 flex-wrap">
        {#each managerIds as mid}
          <div
            class="px-4 py-1 rounded-full flex items-center border border-gray-800"
          >
            {managers.find((it) => it._id == mid)?.profile.name}
            <i
              class="bx bx-trash text-red-500 cursor-pointer ml-2"
              on:click={() => {
                const newMids = managerIds.filter((it) => it != mid);
                managerIds = newMids;
              }}
            />
          </div>
        {/each}
      </div>
    {/if}

    <div class="flex-1 pt-3">
      <div class="flex space-x-3">
        <div class="flex-1">
          <Switch
            bind:value={status}
            checked={status}
            name="status"
            text="Status Store"
            textRight={status ? "Active" : "InActive"}
            on:change={(e) => {
              status = !status;
            }}
          />
        </div>
        <div class="flex-1">
          <Switch
            bind:value={isOpen}
            checked={isOpen}
            name="isOpen"
            text="Is Store Open?"
            textRight={isOpen ? "Open" : "Closed"}
            on:change={(e) => {
              isOpen = !isOpen;
            }}
          />
        </div>
      </div>
    </div>

    <div class="flex flex-1 space-x-3">
      <div class="flex-1">
        <CustomInput
          bind:errors
          label="Tax"
          min="0"
          max="100"
          type="number"
          bind:value={tax}
          errorMessageMax = "Value must be less or equal to 100";
          name="tax">%</CustomInput
        >
      </div>
      <div class="flex-1">
        <CustomInput
          bind:errors
          label="Service Charge"
          min="0"
          max="100"
          type="number"
          bind:value={serviceCharge}
          name="serviceCharge"
          errorMessageMax = "Value must be less or equal to 100";
        >
          %
        </CustomInput>
      </div>
      <div class="flex-1">
        <p class="block text-primary text-sm font-bold mb-2">Rounding</p>
        <div class="flex space-x-3 align-center">
          <div
            class="py-1 px-4 rounded-md border-2 {rounding == 'UP'
              ? 'border-primary bg-primary text-white'
              : 'border-gray-200 text-gray-600 bg-gray-200'} flex-1 text-center cursor-pointer"
            on:click={() => (rounding = "UP")}
          >
            UP
          </div>
          <div
            class="py-1 px-4 rounded-md border-2 {rounding == 'DOWN'
              ? 'border-primary bg-primary text-white'
              : 'border-gray-200 text-gray-600 bg-gray-200'} flex-1 text-center cursor-pointer"
            on:click={() => (rounding = "DOWN")}
          >
            DOWN
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1">
      <TextArea
        bind:errors
        label="Description"
        name="desc"
        bind:value={desc}
        maxlength="100"
      />
    </div>

    <div class="flex flex-1 space-x-3">
      <div class="flex-1">
        <CustomInput
          bind:errors
          label="Latitude"
          type="text"
          bind:value={latitude}
          name="latitude"
        />
      </div>
      <div class="flex-1">
        <CustomInput
          bind:errors
          label="Longitude"
          type="text"
          bind:value={longitude}
          name="longitude"
        />
      </div>
    </div>
    <div class="flex flex-col flex-1">
      <div class="flex items-end space-x-2">
        <div class="flex-1">
          <CustomInput
            bind:errors
            label="Discounts"
            type="number"
            bind:value={discNumber}
            name="discounts">%</CustomInput
          >
        </div>
        <Button
          leftIcon="bx bx-plus"
          type="button"
          extClass="mb-1"
          on:click={() => {
            if (discountList.includes(discNumber)) {
              window.pushToast(`${discNumber} already exist`, "warning");
            } else if (
              discNumber != null &&
              discNumber != 0 &&
              discNumber <= 100 &&
              discNumber > 0
            ) {
              discountList.push(Number(discNumber));
              discountList = discountList;
              discNumber = null;
            } else if (discNumber > 100) {
              discNumber = null;
              window.pushToast("Sorry, Max 99% discount.", "danger");
            } else if (discNumber < 0) {
              discNumber = null;
              window.pushToast("Sorry, value must more than 0", "danger");
            }
          }}>Add</Button
        >
      </div>
      <div class="flex-1 flex space-x-2 mt-2 overflow-x-auto">
        {#each discountList.sort((a, b) => a - b) as disc, i}
          <div
            class="px-4 py-1 rounded-full border border-gray-800 flex items-center"
          >
            {disc}%
            <i
              class="bx bx-trash text-red-500 cursor-pointer ml-2"
              on:click={() => {
                discountList = discountList.splice(i, 1);
                discountList = discountList;
              }}
            />
          </div>
        {/each}
      </div>
    </div>
    <div class="flex flex-col flex-1 space-y-2">
      <p class="text-primary font-bold text-sm">Operational Hours</p>
      <div class="flex space-x-2">
        <div class="flex-1">
          <p class="text-primary font-bold text-sm">Day</p>
        </div>
        <div class="flex-1">
          <p class="text-primary font-bold text-sm">Open</p>
        </div>
        <div class="flex-1">
          <p class="text-primary font-bold text-sm">Closed</p>
        </div>
      </div>
      {#if operationalHours != undefined && operationalHours.length > 0}
        {#each operationalHours as op}
          <div class="flex space-x-2">
            <div class="flex-1">
              <Input
                bind:errors
                noLabel
                type="text"
                value={op.day == "thrusday" ? "THURSDAY" : op.day.toUpperCase()}
                name={op.day}
                disabled
              />
            </div>
            <div class="flex-1">
              <DatePicker
                isDate={false}
                bind:value={op.open}
                isTime={true}
                bind:errors
                name="open"
              />
            </div>
            <div class="flex-1">
              <DatePicker
                isDate={false}
                bind:value={op.closed}
                isTime={true}
                bind:errors
                name="closed"
              />
            </div>
          </div>
        {/each}
      {/if}
    </div>
  </div>
{/if}

<script>
	import Modal from "../Modal.svelte";
	import { debounce } from "lodash";
	import LoadingText from "../LoadingText.svelte";
	import Button from "../control/Button.svelte";
	import { connection } from "../../store";
	import EmployeeCard from "../card/EmployeeCard.svelte";
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	export let storeId;
	export let storeManagerIds;
	let modalAddManager;

	let dataFiltered = [],
		tempManagerSelected = [];
	let isLoading = false,
		showPaginate = false,
		isEndPaginate = false;
	let searchManager = "";
	let page = 1,
		perPage = 15;

	async function loadManager() {
		// reset value
		showPaginate = false;
		isEndPaginate = false;
		dataFiltered = [];
		isLoading = true;
		const existingManagersId = storeManagerIds.length > 0 ? storeManagerIds : [];

		$connection
			.call("users.store-managers.pagination.search", page, perPage, searchManager.toString(), existingManagersId)
			.then((result) => {
				dataFiltered = result.data;
				if (result.length > perPage) {
					showPaginate = true;
					isEndPaginate = Math.floor(result.totalPage) == page;
				}
				isLoading = false;
			})
			.catch((err) => {
				console.log("err", err);
			});
	}

	function handleSearchManager() {
		page = 1;
		loadManager();
	}
	function handleAddManager(emp) {
		const exist = tempManagerSelected.filter((it) => it._id == emp._id).length == 1;
		if (!exist) {
			tempManagerSelected = [...tempManagerSelected, emp];
		} else {
			tempManagerSelected = tempManagerSelected.filter((it) => it._id != emp._id);
		}
	}
	function handleSelectManager() {
		// make an array of object that only has _id from storeManagerIds, we can't use storeManagers
		// because storeManagers only contains data per page, so we use storeManagerIds that contains
		// all managers _id
		const storeManagersObject = storeManagerIds.map((str) => ({ _id: str }));
		const managerSelected = [...storeManagersObject, ...tempManagerSelected];
		$connection
			.call("stores.add.managers", storeId, managerSelected)
			.then((res) => {
				window.pushToast("Successfully Add Manager.", "success");
				dispatch("load-data");
			})
			.catch((err) => {
				window.pushToast("Something Wrong! Cant Add Manager.", "danger");
				console.log(err);
			});

		tempManagerSelected = [];
		searchManager = "";
		modalAddManager.toggle();
	}

	export function open() {
		modalAddManager.toggle();
		searchManager = "";
		page = 1;
		tempManagerSelected = [];
		loadManager();
	}

	export function close() {
		modalAddManager.close();
	}
</script>

<Modal
	bind:this={modalAddManager}
	title="Add Manager"
	closed
	on:close={() => {
		searchManager = "";
		modalAddManager.toggle();
	}}
>
	<input
		bind:value={searchManager}
		on:input={debounce(function () {
			handleSearchManager();
		}, 1000)}
		type="text"
		placeholder="Search Manager.."
		class="appearance-none block pl-5 pr-5 w-full text-dark-second border dark:text-white dark:bg-dark-third rounded-full py-2 px-3 focus:ring-transparent focus:border-gray-800 outline-none"
	/>
	{#if isLoading}
		<LoadingText />
	{:else}
		{#if dataFiltered.length == 0}
			<p class="m-0 text-gray-400 p-4">No Manager Available.</p>
		{/if}
		<div class="grid grid-cols-4 gap-4 my-4">
			{#each dataFiltered as manager}
				<EmployeeCard
					name={manager.profile.name}
					image={manager.profile.image}
					description={manager.profile.gender == "M" ? "Male" : "Female"}
					selectable
					selected={tempManagerSelected.length > 0 && tempManagerSelected.filter((item) => item._id === manager._id).length == 1}
					on:card-click={() => handleAddManager(manager)}
				/>
			{/each}
		</div>
		{#if dataFiltered.length > 0}
			<div class="flex justify-between w-full">
				<div class="flex space-x-2">
					{#if showPaginate}
						<Button
							disabled={page == 1}
							on:click={async () => {
								if (page > 1) {
									page -= 1;
									await loadManager();
								}
							}}>Prev</Button
						>
						<Button
							disabled={isEndPaginate}
							on:click={async () => {
								if (!isEndPaginate) {
									page += 1;
									await loadManager();
								}
							}}>Next</Button
						>
					{/if}
				</div>
				<Button status="success" on:click={() => handleSelectManager()}>Select Manager</Button>
			</div>
		{/if}
	{/if}
</Modal>

import { loggedInUserId } from "./store";
import { wrap } from "svelte-spa-router/wrap";
import Login from "./pages/Login.svelte";
import NotFound from "./pages/NotFound.svelte";
import Error from "./pages/Error.svelte";
import HomeNew from "./pages/HomeNew.svelte";
import User from "./pages/user/Default.svelte";
import Store from "./pages/store/Default.svelte";
import Product from "./pages/product/Default.svelte";
import Modifier from "./pages/modifier/Default.svelte";
import ProductCategory from "./pages/productCategory/Default.svelte";
import Menu from "./pages/menu/Default.svelte";
let userId;
loggedInUserId.subscribe((val) => {
    userId = val;
});
const routes = {
    "/": wrap({
        component: HomeNew,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/unauthorized": wrap({
        component: Error,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/users": wrap({
        component: User,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/users/*": wrap({
        component: User,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/stores": wrap({
        component: Store,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/stores/*": wrap({
        component: Store,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/products": wrap({
        component: Product,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/products/*": wrap({
        component: Product,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/modifier": wrap({
        component: Modifier,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/modifier/*": wrap({
        component: Modifier,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/category": wrap({
        component: ProductCategory,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/category/*": wrap({
        component: ProductCategory,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/menu": wrap({
        component: Menu,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/menu/*": wrap({
        component: Menu,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/login": Login,
    // The catch-all route must always be last
    "*": NotFound,
};
export default routes;

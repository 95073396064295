<script>
  import Layout from "./templates/LayoutTemplate.svelte";
  import Template from "./templates/DetailTemplate.svelte";
  import LoadingText from "../../common/LoadingText.svelte";
  import { onMount } from "svelte";
  import { connection } from "../../store";
  export let params = {};

  const { _id } = params;
  let isLoading;
  let data;
  let dataId = params._id;

  onMount(() => {
    loadData();
  });

  $: if (params) {
    if (dataId != params._id) {
      isLoading = true;
      dataId = params._id;
      loadData();
    }
  }

  function loadData() {
    $connection.call("stores.byId", dataId).then((res) => {
      data = res;
    });
  }
</script>

<Layout id={dataId} page="info">
  {#if !data}
    <LoadingText />
  {:else}
    <Template id={dataId} bind:data />
  {/if}
</Layout>

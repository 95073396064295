<script>
  import Layout from "./templates/LayoutTemplate.svelte";
  import Detail from "./Detail.svelte";
  import LoadingText from "../../common/LoadingText.svelte";
  import Router, { push } from "svelte-spa-router";
  import { connection } from "../../store";
  import { onMount } from "svelte";
  import DetailTemplate from "./templates/DetailTemplate.svelte";

  export let params = {};
  const prefix = "/users";
  const routes = {
    "/:_id/info": Detail,
  };
  let data;
  let dataId = params._id;
  let isLoading = true;
  let dataLoaded = false;
  let roles = [];

  onMount(() => {
    loadData();
  });

  $: if (params) {
    if (dataId != params._id) {
      isLoading = true;
      dataId = params._id;
      loadData();
    }
  }

  function loadData() {
    $connection.call("users.byId", dataId).then((res) => {
      data = res.user;

      $connection.call("role.getByUserId", dataId).then((rest) => {
        if (rest) {
          roles = rest.map((e) => e);
          isLoading = false;
        }
      });
    });
  }

  function handleRemove() {
    $connection
      .call("users.remove", dataId)
      .then(() => {
        window.pushToast("User moved into trash");
        loadData();
      })
      .catch((error) => {
        window.pushToast(`${error.reason}`, "danger");
      });
  }

  function handleRemovePermanent() {
    $connection
      .call("users.remove.permanent", dataId)
      .then(() => {
        window.pushToast("User deleted permanently");
        push(prefix);
      })
      .catch((error) => {
        window.pushToast(`${error.reason}`, "danger");
      });
  }

  function handlePasswordReset() {
    $connection
      .call("users.password.reset", dataId)
      .then(() => {
        window.pushToast("Password has been reset");
      })
      .catch((error) => {
        window.pushToast(`${error.reason}`, "danger");
      });
  }

  function handleRestore() {
    $connection
      .call("users.restore", dataId)
      .then(() => {
        window.pushToast("User restored");
        loadData();
      })
      .catch((error) => {
        window.pushToast(`${error.reason}`, "danger");
      });
  }

  function handleSendVerification() {
    $connection
      .call("users.email.send.verification", dataId)
      .then(() => {
        window.pushToast("Verification sent");
      })
      .catch((error) => {
        window.pushToast(`${error.reason}`, "danger");
      });
  }

  //   let userHandler = Meteor.subscribe("users.findOne", dataId);
  //   let roleHandler = Meteor.subscribe("roles.findByUserId", dataId);

  //   $: if (params) {
  //     dataId = params._id;
  //     userHandler = Meteor.subscribe("users.findOne", dataId);
  //     roleHandler = Meteor.subscribe("roles.findByUserId", dataId);
  //   }
  //   $m: data = Meteor.users.findOne(dataId);
  //   $m: {
  //     if (Meteor.userId()) {
  //       isLoading = !userHandler.ready() && !roleHandler.ready();
  //       data = Meteor.users.findOne(dataId);
  //       roles = Meteor.roleAssignment
  //         .find({ "user._id": dataId })
  //         .fetch()
  //         .filter((item) =>
  //           [
  //             "admin",
  //             "store-manager",
  //             "employee",
  //             "customer",
  //             "admin-lite",
  //           ].includes(item.role._id)
  //         )
  //         .map((item) => item.role._id);
  //     }
  //   }
</script>

{#if data && !isLoading}
  <Layout
    id={dataId}
    title="Promo Information"
    profile={data?.profile}
    {roles}
    on:edit={() => push(`${prefix}/${dataId}/edit`)}
    on:remove={handleRemove}
    on:password-reset={handlePasswordReset}
    on:restore={handleRestore}
    on:remove-permanent={handleRemovePermanent}
    on:verification={handleSendVerification}
  >
    <DetailTemplate {data} />
  </Layout>
{:else}
  <div class="p-4">
    <LoadingText />
  </div>
{/if}

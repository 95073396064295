<script>
	import { push } from "svelte-spa-router";
	import { connection } from "../../../store";
	import Button from "../../../common/control/Button.svelte";
	import { createEventDispatcher } from "svelte";

	export let id;
	export let data;
	const dispatch = createEventDispatcher();
</script>

<div>
	<table class="min-w-full divide-y divide-gray-200">
		<thead class="bg-gray-50">
			<tr>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Store </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Address </th>
			</tr>
		</thead>
		<tbody class="bg-white divide-y divide-gray-200">
			{#if data.stores.length == 0}
				<tr>
					<td colspan="2" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">No Store Available</td>
				</tr>
			{/if}
			{#each data.stores as store, index}
				<tr>
					<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
						{store.name.length > 30 ? store.name.substring(0, 30) + "..." : store.name}
					</td>
					<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
						{store.address}
					</td>
				</tr>
			{/each}
		</tbody>
	</table>
</div>

<div class="absolute top-0 right-0 mr-3 mt-2 flex space-x-2">
	<Button leftIcon="bx bx-edit" on:click={() => push(`/menu/${id}/edit`)}>Edit</Button>
	{#if data.status == "INACTIVE"}
		<Button
			leftIcon="bx bx-notification"
			on:click={() =>
				$connection
					.call("menus.activate", id, "ACTIVE")
					.then(() => {
						window.pushToast("Menu now Activated", "success");
						dispatch("reload-data");
					})
					.catch((err) => {
						alert(err.reason);
					})}>Activate</Button
		>
	{:else}
		<Button
			leftIcon="bx bx-notification-off"
			on:click={() =>
				$connection
					.call("menus.activate", id, "INACTIVE")
					.then(() => {
						window.pushToast("Menu now Deactivated", "success");
						dispatch("reload-data");
					})
					.catch((err) => {
						alert(err.reason);
					})}>Deactivate</Button
		>
	{/if}
</div>

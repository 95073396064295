<script>
	import Button from "../../../common/control/Button.svelte";
	import { createEventDispatcher, onMount } from "svelte";
	import { debounce } from "lodash";
	import { connection } from "../../../store";
	import LoadingText from "../../../common/LoadingText.svelte";
	import ProductCard from "../../../common/card/ProductCard.svelte";
	import { formatRupiah } from "../../../common/helper/FormHelper";
	const dispatch = createEventDispatcher();

	export let data;

	let products = [];
	let tempProductSelected = [];
	let searchProduct = "";
	let loadingProduct = true;
	let page = 1;
	let perPage = 100;
	let showPaginate = true;
	let isEndPaginate = false;

	onMount(() => loadProducts());
	async function loadProducts(searchKey = "") {
		loadingProduct = true;
		// reset value
		showPaginate = false;
		isEndPaginate = false;
		products = [];

		// get data
		const selectedProductIds = data.products.length > 0 ? data.products.map((prod) => prod._id) : [];
		await $connection
			.call("products.all.forList.pagination.search", null, page, perPage, searchKey, selectedProductIds)
			.then((result) => {
				resolveSelected(result.data);

				if (result.length > perPage) {
					showPaginate = true;
					isEndPaginate = Math.floor(result.totalPage) == page;
				}

				loadingProduct = false;
			})
			.catch((err) => {
				console.log(err);
				window.pushToast(err.error, "danger");
				loadingProduct = false;
			});
	}
	function resolveSelected(_data) {
		products = _data.map((e) => ({
			...e,
			isSelected: tempProductSelected.some((item) => item._id == e._id) ? true : false,
		}));
	}
	function handleAddProduct(item) {
		const isSelected = !item.isSelected;
		item.isSelected = isSelected;

		if (isSelected) {
			tempProductSelected.push(item);
		} else {
			tempProductSelected = tempProductSelected.filter((it) => it._id != item._id);
		}

		products = products.map((e) => ({ ...e, isSelected: e._id == item._id ? isSelected : e.isSelected }));
	}
	function handleSelectProduct() {
		if (tempProductSelected.length == 0) {
			window.pushToast("Please add min 1 product!", "danger");
			return;
		}
		data.products.push(...tempProductSelected);
		dispatch("select", data.products);
		page = 1;
		searchProduct = "";
		data.products = [];
		tempProductSelected = [];
	}
	async function handleSearch() {
		let searchKey = searchProduct.toString();
		page = 1;
		await loadProducts(searchKey);
	}
</script>

<input
	bind:value={searchProduct}
	on:input={debounce(function (e) {
		handleSearch();
	}, 1000)}
	type="search"
	placeholder="Search Product.."
	class="appearance-none block pl-5 pr-5 w-full text-dark-second border dark:text-white dark:bg-dark-third rounded-full py-2 px-3 focus:ring-transparent focus:border-gray-800 outline-none"
/>
{#if loadingProduct}
	<div class="p-4">
		<LoadingText />
	</div>
{:else}
	{#if products.length == 0}
		<p class="m-0 text-gray-400 p-4">No Products Available.</p>
	{/if}
	<div class="grid grid-cols-4 gap-4 py-4 my-2 overflow-y-auto" style="max-height: calc(70vh - 30px);">
		{#each products as product}
			<ProductCard
				image={product.images[0].url}
				name={product.name}
				description={formatRupiah(product.price, "Rp. ")}
				selectable
				selected={product.isSelected}
				on:card-click={() => handleAddProduct(product)}
			/>
		{/each}
	</div>
	{#if products.length > 0}
		<div class="flex justify-between w-full">
			<div class="flex space-x-2">
				{#if searchProduct.trim().toString() == "" && showPaginate}
					<Button
						disabled={page == 1}
						on:click={async () => {
							if (page > 1) {
								page -= 1;
								page = page;
								await loadProducts();
							}
						}}>Prev</Button
					>
					<Button
						disabled={isEndPaginate}
						on:click={async () => {
							if (!isEndPaginate) {
								page += 1;
								page = page;
								await loadProducts();
							}
						}}>Next</Button
					>
				{/if}
			</div>
			<Button status="success" on:click={() => handleSelectProduct()}>Select Product</Button>
		</div>
	{/if}
{/if}

<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();
	import Input from "../../../common/control/Input.svelte";
	import Button from "../../../common/control/Button.svelte";
	import TextArea from "../../../common/control/TextArea.svelte";
	import Header from "../../../common/Header.svelte";
	import TableRowTemplate from "./TableRowTemplate.svelte";
	import AddProductTemplate from "./AddProductTemplate.svelte";
	import Modal from "../../../common/Modal.svelte";

	export let id;
	export let name;
	export let description;
	export let products;
	export let errors;

	let modal = {
		header: "",
	};

	let modalRef;
	function handleAddProduct() {
		modal = {
			header: "Add Product",
			body: {
				products: [...products],
			},
			onSelect: (e) => {
				products = e.detail;
				modalRef.toggle();
				dispatch("validate", { name, description, products });
			},
		};
		modalRef.toggle();
	}

	function handleDelete(index) {
		products = products.filter((item, idx) => idx != index);
	}
</script>

<Modal bind:this={modalRef} title={modal.header}>
	<AddProductTemplate data={modal.body} on:select={modal.onSelect} />
</Modal>

<Header title={id ? "Edit Menu" : "Add Menu"}>
	<Button
		leftIcon="bx bx-save"
		on:click={() => {
			dispatch("submit", { name, description, products });
		}}
	>
		Save Changes
	</Button>
</Header>
<div class="space-y-2 p-4">
	<div class="flex-1">
		<Input bind:errors label="Menu Name" bind:value={name} name="name" />
	</div>
	<div class="flex-1">
		<TextArea bind:errors label="Description" bind:value={description} name="description" maxlength={50} />
	</div>
	<div class="flex-1 pt-2">
		<Button extClass="mt-2" on:click={handleAddProduct} bind:errors name="products" errorMessage="Please add product">Add Product</Button>
		<table class="min-w-full divide-y divide-gray-200 mt-3">
			<thead class="bg-gray-50">
				<tr>
					<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Product </th>
					<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Status </th>
					<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" />
				</tr>
			</thead>
			<tbody class="bg-white divide-y divide-gray-200">
				{#if products.length == 0}
					<tr>
						<td colspan={3} class="px-6 py-4 whitespace-nowrap text-sm text-gray-300 text-center">
							<span>No Data</span>
						</td>
					</tr>
				{/if}
				{#each products as item, index}
					<TableRowTemplate {item} on:delete-row={() => handleDelete(index)} />
				{/each}
			</tbody>
		</table>
	</div>
</div>

<script>
  import ImageKit from "imagekit-javascript";
  import Util from "../../common/Util/Utility";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Button from "../control/Button.svelte";
  import { noImg, urlApiEndpoint } from "../../config";
  import { connection } from "../../store";

  export let title = "title";
  export let size = "medium";
  export let image = "";
  export let description = "Rp. 2.000";
  export let action = true;
  export let actionType;
  export let actionLabel = actionType == "file" ? "Upload File" : "Add";
  export let actionStatus = "primary";

  let uploading = false;

  let classes = "";

  if (size === "small") {
    classes = "w-15 h-15";
  } else if (size === "medium") {
    classes = "w-24 h-24";
  } else if (size === "large") {
    classes = "w-50 h-50";
  }

  async function handleSectionImageUpload(files) {
    if (
      files[0].type != "image/jpg" &&
      files[0].type != "image/png" &&
      files[0].type != "image/jpeg"
    ) {
      window.pushToast("Format gambar tidak sesuai", "danger");
      return;
    }
    uploading = true;

    var imagekit = new ImageKit({
      publicKey: "public_Yq/EBehF/1Xwmc+XjZGkEqsSh6w=",
      urlEndpoint: "https://ik.imagekit.io/78p1kjbqlaj/",
    });

    $connection.call("authentication.imgkit").then((res) => {
      imagekit.upload(
        {
          file: files[0],
          fileName: `ERAPI_IMAGE_${Util.generateId(5)}`,
          tags: ["user", "profile", "erapi", "photo"],
          token: res.token,
          expire: res.expire,
          signature: res.signature,
        },
        function (err, result) {
          if (err) {
            console.log(err, "error upload image");
          } else {
            uploading = false;
            image = result.url;
          }
        }
      );
    });
  }
</script>

<div class="flex flex-1 flex-col items-center justify-center">
  <img class="{classes} object-cover" src={image ? image : noImg} alt="..." />
  <h4 class="text-md font-medium mt-2">{title}</h4>
  <span class="text-sm mb-2 mt-1">{description}</span>
  {#if uploading}
    <p>Loading ...</p>
  {:else if action}
    {#if actionType == "file"}
      <label
        class="flex items-center justify-center py-2 px-3 bg-white text-blue-500 tracking-wide rounded border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white"
      >
        <span class="ml-2 text-sm"
          ><i class="bx bx-upload" /> {actionLabel}</span
        >
        <input
          type="file"
          accept=".jpg, .jpeg, .png"
          on:change={(e) => handleSectionImageUpload(e.target.files)}
          class="hidden"
        />
      </label>
    {:else}
      <Button on:click={() => dispatch("click")} status={actionStatus}
        >{actionLabel}</Button
      >
    {/if}
  {/if}
</div>

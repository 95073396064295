export const printType = {
    Kitchen: {
        value: 'kitchen',
        text: 'Kitchen Indoor',
        propertyName: 'printCount',
    },
    Bar: {
        value: 'bar',
        text: 'Bar',
        propertyName: 'printCountBar',
    },
    Bakery: {
        value: 'bakery',
        text: 'Bakery',
        propertyName: 'printCountBakery',
    },
    KitchenBakar: {
        value: 'kitchen_bakar',
        text: 'Kitchen Bakar',
        propertyName: 'printCountKitchenBakar',
    },
    KitchenDimsum: {
        value: 'kitchen_dimsum',
        text: 'Kitchen Dimsum',
        propertyName: 'printCountKitchenDimsum',
    },
};
export const defaultPrintType = [printType.Kitchen.value, printType.Bar.value, printType.Bakery.value];

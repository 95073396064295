<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  import LoadingText from "../../common/LoadingText.svelte";
  export let items = [];
  export let category = false;
  export let withBadge = false;
  export let loadingList = false;

</script>

<div class="flex-1 mt-3 max-h-full pb-11" id="listData">
	<div
		on:scroll={(e) => dispatch("scroll", e)}
		class="max-h-full shadow-md bg-white rounded-md overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
	>
		<!-- <div class="bg-gray-400">
          <i class="bx bx-chevron-left" />
          <i class="bx bx-chevron-right" />
      </div> -->
      {#if items.length > 0}
          {#if category}
              {#each items as item}
                  <div class="bg-gray-200 px-3 py-2 flex items-center">
                      <h4 class="text-md font-medium">{item.category}</h4>
                  </div>
                  {#if item.items.length > 0}
                      {#each item.items as child}
                          <!-- svelte-ignore a11y-click-events-have-key-events -->
                          <div
                              class="border-b p-3 flex justify-between cursor-pointer hover:bg-primary hover:text-white items-center {child.active
                                  ? 'bg-primary text-white'
                                  : 'bg-level-1'} {child.gapWithIcon ?? ''}"
                              on:click={() => dispatch('item-click', child)}
                          >
                              <div class="flex flex-col">
                                    <div class="flex items-center gap-2">
                                        <h4 class="text-md font-medium break-words-self">{child.title}</h4>
                                        {#if withBadge && child.isRequired}
                                            <div class="text-xs  font-bold bg-danger px-1 py-0.5 text-white mb-2" style="font-size: 8px;">WAJIB</div>
                                        {/if}
                                    </div>
                                    {#if child.desc}
                                        <small class="text-sm">{child.desc}</small>
                                    {/if}
                              </div>
                              <div class="flex flex-col items-start">
                                  {#if child.icon !== ''}
                                      <i class="{child.icon} text-2xl" />
                                  {/if}
                              </div>
                          </div>
                      {/each}
                  {:else}
                      <div class="border-b p-3 flex justify-between items-center bg-level-1">No data</div>
                  {/if}
              {/each}
			  {#if loadingList}
					<div class="my-2">
						<LoadingText />
					</div>
			{/if}
          {:else}
              {#each items as item}
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <div
                      class="border-b p-3 flex justify-between cursor-pointer hover:bg-primary hover:text-white items-center {item.active
                          ? 'bg-primary text-white'
                          : 'bg-level-1'}"
                      on:click={() => dispatch('item-click', item)}
                  >
                      <div class="flex flex-col">
                          <h4 class="text-md font-medium break-words-self">{item.title}</h4>
                          {#if item.desc}
                              <small class="text-sm">{item.desc}</small>
                          {/if}
                      </div>
                      <div class="flex flex-col items-start">
                          {#if item.icon}
                              <i class="{item.icon} text-2xl" />
                          {/if}
                      </div>
                  </div>
              {/each}
          {/if}
      {:else}
          <p class="p-4">No Data</p>
      {/if}
  </div>
</div>

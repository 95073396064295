<script>
	import Modal from "../Modal.svelte";
	import { debounce } from "lodash";
	import LoadingText from "../LoadingText.svelte";
	import Button from "../control/Button.svelte";
	import { connection } from "../../store";
	import EmployeeCard from "../card/EmployeeCard.svelte";
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	export let storeId;
	export let storeEmployeeIds;
	let modalAddEmployee;

	let dataFiltered = [],
		tempEmployeeSelected = [];
	let isLoading = false,
		showPaginate = false,
		isEndPaginate = false;
	let searchEmployee = "";
	let page = 1,
		perPage = 15;

	async function loadEmployees() {
		// reset value
		isLoading = true;
		showPaginate = false;
		isEndPaginate = false;
		dataFiltered = [];

		// get data
		const existingEmployeesId = storeEmployeeIds.length > 0 ? storeEmployeeIds : [];

		$connection
			.call("users.employees.pagination.search", page, perPage, searchEmployee.toString(), existingEmployeesId)
			.then((result) => {
				dataFiltered = result.data;
				if (result.length > perPage) {
					showPaginate = true;
					isEndPaginate = Math.floor(result.totalPage) == page;
				}
				isLoading = false;
			})
			.catch((err) => {
				console.log("err", err);
			});
		isLoading = false;
	}

	function handleSearch() {
		page = 1;
		loadEmployees();
	}
	function handleAddEmployee(emp) {
		const exist = tempEmployeeSelected.filter((it) => it._id == emp._id).length == 1;
		if (!exist) {
			tempEmployeeSelected = [...tempEmployeeSelected, emp];
		} else {
			tempEmployeeSelected = tempEmployeeSelected.filter((it) => it._id != emp._id);
		}
	}
	function handleSelectEmployee() {
		// make an array of object that only has _id from storeEmployeeIds, we can't use storeEmployees
		// because storeEmployees only contains data per page, so we use storeEmployeeIds that contains
		// all employees _id
		const storeEmployeesObject = storeEmployeeIds.map((str) => ({ _id: str }));
		const employeeSelected = [...storeEmployeesObject, ...tempEmployeeSelected];

		$connection
			.call("stores.add.employees", storeId, employeeSelected)
			.then((res) => {
				window.pushToast("Successfully Add Employee.", "success");
				dispatch("load-data");
			})
			.catch((err) => {
				window.pushToast("Something Wrong! Cant Add Employee.", "danger");
				console.log(err);
			});

		// reset value and close modal
		tempEmployeeSelected = [];
		searchEmployee = "";
		modalAddEmployee.toggle();
	}

	export function open() {
		modalAddEmployee.toggle();
		searchEmployee = "";
		page = 1;
		tempEmployeeSelected = [];
		loadEmployees();
	}

	export function close() {
		modalAddEmployee.close();
	}
</script>

<Modal
	bind:this={modalAddEmployee}
	title="Add Employee"
	closed
	on:close={() => {
		searchEmployee = "";
		modalAddEmployee.toggle();
	}}
>
	<input
		bind:value={searchEmployee}
		on:input={debounce(function () {
			handleSearch();
		}, 1000)}
		type="text"
		placeholder="Search Employee.."
		class="appearance-none block pl-5 pr-5 w-full text-dark-second border dark:text-white dark:bg-dark-third rounded-full py-2 px-3 focus:ring-transparent focus:border-gray-800 outline-none"
	/>
	{#if isLoading}
		<LoadingText />
	{:else}
		{#if dataFiltered.length == 0}
			<p class="m-0 text-gray-400 p-4">No Employee Available.</p>
		{/if}
		<div class="grid grid-cols-4 gap-4 my-4">
			{#each dataFiltered as employee}
				<EmployeeCard
					name={employee.profile.name}
					image={employee.profile.image}
					description={employee.profile.gender == "M" ? "Male" : "Female"}
					selectable
					selected={tempEmployeeSelected.length > 0 && tempEmployeeSelected.filter((item) => item._id === employee._id).length == 1}
					on:card-click={() => handleAddEmployee(employee)}
				/>
			{/each}
		</div>
		{#if dataFiltered.length > 0}
			<div class="flex justify-between w-full">
				<div class="flex space-x-2">
					{#if showPaginate}
						<Button
							disabled={page == 1}
							on:click={async () => {
								if (page > 1) {
									page -= 1;
									await loadEmployees();
								}
							}}>Prev</Button
						>
						<Button
							disabled={isEndPaginate}
							on:click={async () => {
								if (!isEndPaginate) {
									page += 1;
									await loadEmployees();
								}
							}}>Next</Button
						>
					{/if}
				</div>
				<Button status="success" on:click={() => handleSelectEmployee()}>Select Employee</Button>
			</div>
		{/if}
	{/if}
</Modal>

<script>
	import IconButton from "../../../common/control/IconButton.svelte";
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();
	export let item = {};
	export let isStatic = false;
</script>

{#if item}
	<tr>
		<td class="px-6 py-4 text-sm text-gray-800">
			<span>{item.name}</span>
		</td>
		<td class="px-6 py-4 text-sm text-gray-800">
			<span>{item.status}</span>
		</td>
		{#if !isStatic}
			<td class="p-4">
				<div class="flex">
					<IconButton on:click={(evt) => dispatch("delete-row", evt)} title="Remove" icon="bx bx-trash" type="danger" />
				</div>
			</td>
		{/if}
	</tr>
{/if}

<script>
  import { link } from "svelte-spa-router";
  import Header from "../../../common/Header.svelte";

  export let page = "info";
  export let id;
</script>

<Header title="Store Information" />
<div class="p-4">
  <div class="mb-4">
    <ul class="flex cursor-pointer space-x-2 overflow-x-auto">
      <li
        class={`py-2 px-6 bg-gray-100 rounded ${
          page == "supplies" ? "text-white bg-gray-400" : "hover:opacity-90"
        }`}
      >
        <a use:link href="/stores/{id}/supplies">Supplies</a>
      </li>
      <li
        class={`py-2 px-6 bg-gray-100 whitespace-nowrap rounded ${
          page == "stockopname" ? "text-white bg-gray-400" : "hover:opacity-90"
        }`}
      >
        <a use:link href="/stores/{id}/stockopname">Stock Opname</a>
      </li>
      <li
        class={`py-2 px-6 bg-gray-100 rounded ${
          page == "products" ? "text-white bg-gray-400" : "hover:opacity-90"
        }`}
      >
        <a use:link href="/stores/{id}/products">Products</a>
      </li>
      <li
        class={`py-2 px-6 bg-gray-100 rounded ${
          page == "menus" ? "text-white bg-gray-400" : "hover:opacity-90"
        }`}
      >
        <a use:link href="/stores/{id}/menus">Menu</a>
      </li>
      <li
        class={`py-2 px-6 bg-gray-100 rounded ${
          page == "employees" ? "text-white bg-gray-400" : "hover:opacity-90"
        }`}
      >
        <a use:link href="/stores/{id}/employees">Employees</a>
      </li>
      <li
        class={`py-2 px-6 bg-gray-100 rounded  ${
          page == "info" || page == "edit"
            ? "text-white bg-gray-400"
            : "hover:opacity-90"
        }`}
      >
        <a use:link href="/stores/{id}/info"
          >Detail {page == "edit" ? "> Edit" : ""}</a
        >
      </li>
    </ul>
  </div>
  <slot />
</div>

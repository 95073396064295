<script>
	import Layout from "./templates/LayoutTemplate.svelte";
	import Template from "./templates/ProductsTemplate.svelte";
	export let params = {};

	const { _id } = params;
	let dataId = _id;

	$: if (params) {
		dataId = params._id;
	}
</script>

<Layout id={dataId} page="products">
	<Template id={dataId} />
</Layout>

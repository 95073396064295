<script>
  import Button from './Button.svelte';
  import IconButton from './IconButton.svelte';
  import { createEventDispatcher } from 'svelte';
  export let isFilterActive = false;
  export let actionIcon = 'bx bx-plus';
  export let showIconButton = true;
  export let showApplyFilter = false;
  export let isClearFilterActive = false;
  export let isShowCheck = false;
  const dispatch = createEventDispatcher();
  let filterOpen = false;
  function handleClearFilter(isActive) {
      if (isActive) {
          dispatch('clear');
          filterOpen = false;
      }
  }
</script>

<div class="relative flex space-x-2">
  {#if filterOpen}
      <div class="p-4 absolute bg-white shadow-lg rounded w-full z-10 top-9">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
              class="mb-2 cursor-pointer flex justify-end"
              on:click={() => {
                  filterOpen = false;
              }}
          >
              <svg
                  class="fill-current text-dark-second dark:text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
              >
                  <path
                      d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  />
              </svg>
          </div>
          <slot />
          <div class="flex justify-end">
            <div class="text-right mt-4 ">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <span
                    class="p-2 font-bold  text-sm rounded {isClearFilterActive
                        ? 'text-primary cursor-pointer hover:bg-gray-100'
                        : 'text-gray-300'}"
                    on:click={() => handleClearFilter(isClearFilterActive)}
                >
                    Clear Filter
                </span>
            </div>
              {#if showApplyFilter}
                  <div class="text-right mt-4 ">
                      <!-- svelte-ignore a11y-click-events-have-key-events -->
                      <span
                          class="p-2 font-bold  text-sm rounded {isFilterActive
                              ? 'text-primary cursor-pointer hover:bg-gray-100'
                              : 'text-gray-300'}"
                          on:click={() => {
                              if (isFilterActive) dispatch('apply-filter');
                          }}
                      >
                          Apply Filter
                      </span>
                  </div>
              {/if}
          </div>
      </div>
  {/if}
  <div class="flex-1">
      <Button
          on:click={() => {
              filterOpen = !filterOpen;
          }}
          status="ghost"
          leftIcon="bx bx-filter"
          extClass="flex w-full items-center rounded-md"
      >
          <span class="flex-1">Filter</span>
          {#if isFilterActive || isShowCheck}
              <i class="bx bxs-check-circle text-success" />
          {/if}
      </Button>
  </div>
  {#if showIconButton}
      <IconButton rounding={false} on:click={() => dispatch('action')} type="normal" icon={actionIcon} />
  {/if}
</div>

<script>
  import Button from "../../../common/control/Button.svelte";
  import IconButton from "../../../common/control/IconButton.svelte";
  import { createEventDispatcher } from "svelte";
  import { clickOutside } from "../../../common/js/ClickOutside";
  export let profile;
  export let title = "Title";
  const dispatch = createEventDispatcher();
  let more = false;
</script>

<div class="relative flex justify-between bg-primarydark rounded-t-lg pl-4 p-2">
  <p class="text-white py-1"><b>{title}</b></p>
  <div class="flex space-x-2">
    <Button leftIcon="bx bx-lock" on:click={() => dispatch("verification")}
      >Send Verification</Button
    >
    <Button leftIcon="bx bx-edit" on:click={() => dispatch("edit")}>Edit</Button
    >
    <IconButton
      icon="bx bx-dots-vertical-rounded"
      type="white"
      on:click={() => (more = !more)}
    />
  </div>
  {#if more}
    <div
      class="absolute flex flex-col justify-end right-0 top-12 bg-white py-2 rounded-md shadow-lg border-2 z-10"
      use:clickOutside
      on:click_outside={() => {
        more = false;
      }}
    >
      <ul class="md:flex-col md:min-w-full flex flex-col list-none space-y-2">
        <li class="items-center">
          <div>
            <Button
              appearance="ghost"
              leftIcon="bx bx-lock"
              on:click={() => dispatch("password-reset")}>Reset Password</Button
            >
          </div>
          {#if profile?.deletedAt}
            <div>
              <Button
                appearance="ghost"
                leftIcon="bx bx-recycle"
                on:click={() => dispatch("restore")}>Restore User</Button
              >
            </div>
            <div>
              <Button
                appearance="ghost"
                leftIcon="bx bx-trash"
                on:click={() => dispatch("remove-permanent")}
                >Delete User Permanent</Button
              >
            </div>
          {:else}
            <div>
              <Button
                appearance="ghost"
                leftIcon="bx bx-trash"
                on:click={() => dispatch("remove")}>Delete User</Button
              >
            </div>
          {/if}
        </li>
      </ul>
    </div>
  {/if}
</div>

<script>
	import { fade } from "svelte/transition";
	import { push, replace } from "svelte-spa-router";
	import Router from "svelte-spa-router";
	import Layout from "../../common/layout/Default.svelte";
	import DataList from "../../common/DataDisplay/List.svelte";
	import LoadingText from "../../common/LoadingText.svelte";
	import SearchBar from "../../common/SearchBar.svelte";
	import { connection } from "../../store";
	import List from "./List.svelte";
	import { onMount } from "svelte";
	import Add from "./Add.svelte";
	import Edit from "./Edit.svelte";
	import Detail from "./Detail.svelte";
	import ListFilterAndAction from "../../common/control/ListFilterAndAction.svelte";
	import Select from "../../common/control/Select.svelte";
	import Button from "../../common/control/Button.svelte";

	export let params = {};

	const prefix = "/modifier";
	const routes = {
		"/": List,
		"/add": Add,
		"/:_id": Detail,
		"/:_id/edit": Edit,
	};

	let isLoading = true;
	let search = "";
	let data;
	let selectedIsRequired = "all";
	let selectedStatus = "all";
	let isFilterActive = false;
	let isClearFilterActive = false;
	let previousFilter = {
		search: "",
		selectedIsRequired: "all",
		selectedStatus: "all",
	};

	$: dataId = params.wild?.split("/")[0];

	$: dataFiltered = data;
	onMount(() => {
		loadData();
	});

	async function loadData() {
		await $connection
			.call("products.additionals.all")
			.then((result) => {
				if (result) {
					data = result;
					isLoading = false;
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	function handleSearch(e) {
		search = e.detail.toLowerCase();
		isFilterActive = isFilterChanged(search, selectedIsRequired, selectedStatus);
		isClearFilterActive = search != "" || selectedIsRequired != "all" || selectedStatus != "all";
	}

	function handleItemClick(e) {
		clearFilter();
		dataId = e.detail._id;
		replace(`${prefix}/${e.detail._id}`);
	}
	function mapByStatus(modifiers, currentActive) {
		const active = [];
		const inActive = [];
		modifiers.forEach((modifier) => {
			let pointer;
			if (modifier.status == "ACTIVE") {
				pointer = active;
			} else {
				pointer = inActive;
			}
			pointer.push({
				_id: modifier._id,
				title: modifier.name,
				active: modifier._id === currentActive,
				icon: "bx bx-box",
				isRequired: modifier.isRequired,
			});
		});
		return [
			{
				category: `Active (${active.length})`,
				items: active,
			},
			{
				category: `InActive (${inActive.length})`,
				items: inActive,
			},
		];
	}
	function routeEvent() {
		loadData();
	}
	function clearFilter() {
		selectedIsRequired = "all";
		selectedStatus = "all";
		search = "";
		isFilterActive = false;
		isClearFilterActive = false;
		previousFilter.search = "";
		previousFilter.selectedIsRequired = "all";
		previousFilter.selectedStatus = "all";
		dataFiltered = data;
	}
	function handleAdd() {
		clearFilter();
		dataId = null;
		push(`${prefix}/add`);
	}

	function handleFilter() {
		const keyword = search.trim().toLowerCase();
		const statusFilter = getStatusFilter(selectedStatus);
		const requiredFilter = getRequiredFilter(selectedIsRequired);

		// Apply filters based on selected filters
		if (requiredFilter === "ALL" && statusFilter === "ALL") {
			// When both filters are not used
			dataFiltered = filterData(data, keyword, null, null);
		} else if (requiredFilter !== "ALL" && statusFilter === "ALL") {
			// When isRequired filter is used
			dataFiltered = filterData(data, keyword, requiredFilter, null);
		} else if (requiredFilter === "ALL" && statusFilter !== "ALL") {
			// When status filter is used
			dataFiltered = filterData(data, keyword, null, statusFilter);
		} else {
			// Where both filters are used
			dataFiltered = filterData(data, keyword, requiredFilter, statusFilter);
		}
		isFilterActive = false;
		previousFilter.search = search;
		previousFilter.selectedIsRequired = selectedIsRequired;
		previousFilter.selectedStatus = selectedStatus;
	}

	function getStatusFilter(selectedStatus) {
		switch (selectedStatus) {
			case "active":
				return "ACTIVE";
			case "not_active":
				return "INACTIVE";
			default:
				return "ALL";
		}
	}

	function getRequiredFilter(selectedIsRequired) {
		switch (selectedIsRequired) {
			case "required":
				return "REQUIRED";
			case "not_required":
				return "NOT REQUIRED";
			default:
				return "ALL";
		}
	}

	function filterData(data, keyword, requiredFilter, statusFilter) {
		// first condition checks if requiredFilter is provided. If it is not provided then it will return true
		// then if it is provided, then it will check item.isRequired based on the provided value.
		// second condition checks if statusFilter is provided or not, if provided then check item.status
		// based on the provided value.
		return data.filter(
			(item) =>
				(!requiredFilter ||
					(requiredFilter === "REQUIRED" && item.isRequired) ||
					(requiredFilter === "NOT REQUIRED" && !item.isRequired)) &&
				(!statusFilter || item.status === statusFilter) &&
				item.name.toLowerCase().includes(keyword)
		);
	}
	function isFilterChanged(search, selectedIsRequired, selectedStatus) {
		return (
			search !== previousFilter.search ||
			selectedIsRequired !== previousFilter.selectedIsRequired ||
			selectedStatus !== previousFilter.selectedStatus
		);
	}

	function isCleanFilterEnabled(search, selectedIsRequired, selectedStatus) {
		return search != "" || selectedIsRequired != "all" || selectedStatus != "all";
	}

	function onChangeSelectFilter(search, selectedIsRequired, selectedStatus) {
		isFilterActive = isFilterChanged(search, selectedIsRequired, selectedStatus);
		isClearFilterActive = isCleanFilterEnabled(search, selectedIsRequired, selectedStatus);
	}
</script>

<Layout title="Modifier">
	<div class="flex flex-col md:flex-row md:h-full">
		<div class="flex flex-col md:h-full w-full md:w-1/4 pt-3 pb-14 pl-3 pr-1.5">
			<ListFilterAndAction
				showIconButton={false}
				showApplyFilter={true}
				isShowCheck={search != '' || selectedIsRequired != 'all' || selectedStatus != 'all'}
				{isFilterActive}
                {isClearFilterActive}
				on:clear={clearFilter}
				on:apply-filter={handleFilter}
			>
				<div class="text-sm font-medium">Search</div>
				<SearchBar placeholder="Cari nama Modifier" bind:value={search} on:input={handleSearch} />

				<div class="py-2" />
				<div class="text-sm font-medium">Modifier Type</div>
				<Select
					form={null}
					name="type"
					bind:selected={selectedIsRequired}
					on:change={() => {
						onChangeSelectFilter(search, selectedIsRequired, selectedStatus);
					}}
					options={[
						{ id: "all", name: "All" },
						{ id: "not_required", name: "Tidak Wajib" },
						{ id: "required", name: "Wajib" },
					]}
				/>
				<div class="py-2" />
				<div class="text-sm font-medium">Status</div>
				<Select
					form={null}
					name="type"
					bind:selected={selectedStatus}
					on:change={() => {
						onChangeSelectFilter(search, selectedIsRequired, selectedStatus);
					}}
					options={[
						{ id: "all", name: "All" },
						{ id: "active", name: "Active" },
						{ id: "not_active", name: "InActive" },
					]}
				/>
			</ListFilterAndAction>
			<div class="py-2" />
			<Button leftIcon="bx bx-plus" extClass="w-full justify-center" on:click={handleAdd}>Tambah Modifier</Button>
			{#if isLoading}
				<LoadingText />
			{:else}
				<DataList category={true} withBadge={true} items={mapByStatus(dataFiltered, dataId)} on:item-click={(e) => handleItemClick(e)} />
			{/if}
		</div>
		<div class="w-full md:h-full flex-1 overflow-auto pr-3 py-3 pl-1.5" in:fade={{ duration: 500 }}>
			<div class="relative bg-white rounded-lg shadow-md min-w-full min-h-full">
				<Router {routes} {prefix} on:routeEvent={routeEvent} />
			</div>
		</div>
	</div>
</Layout>

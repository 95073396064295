<script>
	import { push } from "svelte-spa-router";
	import Template from "./templates/FormTemplate.svelte";
	import { connection } from "../../store";
	import { form, field } from "svelte-forms";
	import { required } from "svelte-forms/validators";
	import { createEventDispatcher } from "svelte";

	let status = true;
	const dispatch = createEventDispatcher();
	let name = field("name", "", [required()]);
	let description = field("description", "", [required()]);
	let products = field("products", [], [checkEmptyArray()]);
	let formAdd = form(name, description, products);
	let errors = [];

	async function handleSubmit(e) {
		const { name, description, products, status } = e.detail;
		await formAdd.validate();
		const valid = $formAdd.valid;
		if (valid) {
            const mappedProduct = products.map((e) => ({ _id: e._id, name: e.name, status: e.status }));
			$connection
				.call("menus.add", name, description, mappedProduct, status)
				.then((result) => {
					dispatch("routeEvent");
					window.pushToast("Successfully Add Menu");
					push(`/menu/${result}/detail`);
				})
				.catch((err) => {
					window.pushToast(`${err.reason}`, "danger");
				});
		} else {
			errors = $formAdd.errors;
			window.pushToast("Something Wrong! Please check again.", "danger");
		}
	}
	function checkEmptyArray() {
		return (value) => ({ valid: value.length > 0, name: "required" });
	}
</script>

<Template
	bind:errors
	bind:products={$products.value}
	bind:name={$name.value}
	bind:description={$description.value}
	bind:status
	on:submit={handleSubmit}
/>

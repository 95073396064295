<script>
  import { link, push } from "svelte-spa-router";
  import { phone, gender } from "../../../common/helper/FormHelper.ts";
  export let data;
  export let roles;
</script>

<div class="p-4">
  <div class="flex flex-wrap flex-col">
    <div class="flex items-center mb-4">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Name</div>
      <div class="flex items-center flex-1">: {data?.profile?.name}</div>
    </div>
    <div class="flex items-center mb-4">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Phone</div>
      <div class="flex items-center flex-1">
        : {data?.profile?.phone?.number
          ? phone(data?.profile?.phone?.number)
          : phone(data?.profile?.phone)}
      </div>
    </div>
    <div class="flex items-center mb-4">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Gender</div>
      <div class="flex items-center flex-1">
        : {gender(data?.profile?.gender)}
      </div>
    </div>
    <div class="flex items-center mb-4">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Address</div>
      <div class="flex items-center flex-1">
        : {data?.profile?.address ? data.profile.address : "-"}
      </div>
    </div>
    <div class="flex items-center mb-4">
      <div class="flex items-center w-1/6 text-sm text-gray-500">Email</div>
      <div class="flex items-center flex-1">: {data?.emails[0]?.address}</div>
    </div>
  </div>
</div>

<script>
  import Template from "./templates/FormTemplate.svelte";
  import { form, field } from "svelte-forms";
  import { required, max, min } from "svelte-forms/validators";
  import { push } from "svelte-spa-router";
  import { connection } from "../../store";
  import utils from "../../utils";
  import { onMount } from "svelte";
  import LoadingText from "../../common/LoadingText.svelte";

  export let params = {};
  let dataId = params._id;

  let managerIds = [];
  let discounts = [];
  let operationalHours = [];
  let status = false;
  let isOpen = true;
  let isLoading = false;

  let name = field("name", "", [required()]);
  let address = field("address", "", [required()]);
  let desc = field("desc", "", [required()]);
  let phone = field("phone", "", [required()]);
  let tax = field("tax", 10, [required(), min(0), max(100)]);
  let serviceCharge = field("serviceCharge", 15, [required(), min(0), max(100)]);
  let rounding = field("rounding", "DOWN", [required()]);
  let latitude = field("latitude", "", [required()]);
  let longitude = field("longitude", "", [required()]);
  let prefix = field("prefix", "", [required()]);

  let errors = [];

  let formAdd = form(
    name,
    address,
    desc,
    phone,
    tax,
    serviceCharge,
    rounding,
    latitude,
    longitude,
    prefix
  );

  onMount(() => {
    loadData();
  });

  function loadData() {
    isLoading = true;
    $connection.call("stores.byId", dataId).then((result) => {
      if (result) {
        $name.value = result.name;
        $address.value = result.address;
        $desc.value = result.desc;
        $phone.value = result.phone.number;
        $tax.value = result.tax;
        $serviceCharge.value = result.serviceCharge;
        $rounding.value = result.rounding;
        $latitude.value = result.location.lat;
        $longitude.value = result.location.long;
        $prefix.value = result.prefix;
        managerIds = result.managerIds;
        status = result.status == "ACTIVE";
        isOpen = result.isOpen;
        discounts = result.discounts;
        operationalHours = result.operationalHours;

        isLoading = false;
      }
    });
  }

  async function handleSubmit(e) {
    const {
      managerIds,
      name,
      address,
      desc,
      status,
      isOpen,
      latitude,
      longitude,
      operationalHours,
      phoneMap,
      tax,
      serviceCharge,
      rounding,
      discountList,
      prefix,
    } = e.detail;
    const serviceChargeMap = Number.isNaN(serviceCharge)
      ? ""
      : utils.formatNumber(serviceCharge);

    errors = [];

    await formAdd.validate();
    const valid = $formAdd.valid;

    if (valid) {
      $connection
        .call(
          "stores.update",
          dataId,
          name,
          address,
          desc,
          status,
          isOpen,
          latitude,
          longitude,
          operationalHours,
          phoneMap,
          parseFloat(tax),
          serviceChargeMap,
          rounding,
          discountList,
          prefix
        )
        .then((res) => {
          window.pushToast("Store updated");
          push(`/stores/${dataId}/info`);
        })
        .catch((err) => {
          window.pushToast(err.reason, "danger");
        });
    } else {
      errors = $formAdd.errors;
      window.pushToast("Required fields not complete", "danger");
    }
  }
</script>

{#if isLoading}
  <LoadingText />
{:else}
  <Template
    bind:errors
    bind:id={dataId}
    bind:name={$name.value}
    bind:address={$address.value}
    bind:desc={$desc.value}
    bind:phone={$phone.value}
    bind:tax={$tax.value}
    bind:serviceCharge={$serviceCharge.value}
    bind:rounding={$rounding.value}
    bind:latitude={$latitude.value}
    bind:longitude={$longitude.value}
    bind:prefix={$prefix.value}
    bind:managerIds
    bind:status
    bind:isOpen
    bind:operationalHours
    bind:discounts
    formTitle="Edit Store"
    on:submit={handleSubmit}
  />
{/if}

<script>
  import { field, form } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import Loading from "../common/Loading.svelte";
  import Logo from "../common/Logo.svelte";
  import Toast from "../common/Toast.svelte";
  import Button from "../common/control/Button.svelte";
  import Input from "../common/control/Input.svelte";
  import Layout from "../common/layout/LayoutAuth.svelte";
  import { connection, loggedInUserId } from "../store";

  let errors = [];
  let formErrors = [];
  let isLoading;
  let email = field("email", "", [required()]);
  let password = field("password", "", [required()]);

let formLogin = form(email, password);

  const handleSubmit = async () => {
    isLoading.toggle();
    errors = [];
    formErrors = [];
   await formLogin.validate();
    const valid = $formLogin.valid;
    if (valid) {
      let user;
      let passwordMap = $password.value;

      if (validateEmail($email.value)) {
        user = { email: $email.value };
      } else {
        user = { username: $email.value };
      }

      setTimeout(() => {
        $connection
          .login({
            password: passwordMap,
            user,
          })
          .then((user) => {
            loggedInUserId.set(user.userId);
            clearInput();
          })
          .catch((err) => {
            errors = [err.reason];
            isLoading.toggle();
          });
      }, 1000);
} else {
      formErrors = $formLogin.errors;
      isLoading.toggle();
    }
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const clearInput = () => {
    $email.value = "";
    $password.value = "";
  };
</script>

<Toast />
<Loading text="Loading..." bind:this={isLoading} />
<Layout>
  <div
    class="max-w-md w-full p-6 border border-gray-200 bg-white shadow-lg rounded-md font-sans"
  >
    <div class="relative flex flex-wrap">
      <div class="w-full relative">
        <div
          class="p-4 bg-white shadow-lg rounded-full flex items-center justify-center w-40 h-40 absolute -top-28"
          style="left: 50%; margin-left: -75px;"
        >
          <Logo />
        </div>
        <h4 class="text-center text-3xl font-semibold text-dark-second pt-20">
          Login
        </h4>
        <hr class="my-7 mx-5" />
        <form class="px-4">
          {#if errors.length > 0}
		        <div class="p-4 bg-red-500 text-white my-7 text-center">
              {errors[0]}
            </div>
          {/if}
          <div class="flex flex-col mb-5 space-y-2">
            <div>
              <Input
                label="Email or Username"
                name="email"
                bind:value={$email.value}
                bind:errors={formErrors}

              />
            </div>
            <div>
              <Input
                label="Password"
                name="password"
                bind:value={$password.value}
                type="password"
                bind:errors={formErrors}
              />
            </div>
          </div>
          <div>
            <Button
              status="primary"
              extClass="w-full"
              size="medium"
              rounded
              type="submit"
              on:click={handleSubmit}
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</Layout>

<script>
  import Cleave from "cleave.js";
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  import { validateErrorField, validateInput } from "../helper/FormHelper";
  const dispatch = createEventDispatcher();

  onMount(() => {
    const inputArr = document.getElementsByClassName("input-phone");
    const fields = Array.from(inputArr);

    fields.forEach(function (field) {
      new Cleave(field, {
        phone: true,
        blocks: [4, 4, 4, 2],
        phoneRegionCode: "id",
      });
    });
  });

  export let label = "";
  export let placeholder = "";
  export let value = "";
  export let name;
  export let form;
  export let disabled = false;
  export let noLabel = false;
  export let hint = false;
  export let hintText = "";

  export let errors = [];

  $: if (errors.length > 0) {
    errors = errors
      .filter((err) => err.split(".")[0] == name)
      .map((e) => ({
        field: e.split(".")[0],
        error: e.split(".")[1],
      }));
    errors = errors;
  }

  function handleInput(evt) {
    const { target } = evt;
    value = target.value;
  }
</script>

{#if !noLabel}
  <label class="block text-primary text-sm font-bold mb-2" for={value}>
    {label}
  </label>
{/if}

<div class="relative">
  <input
    type="text"
    {value}
    {name}
    step="0.01"
    class="pl-9 input-phone appearance-none {errors.length > 0
      ? 'border-red-500'
      : 'border-gray-800'} block w-full text-sm text-dark-second border dark:text-white dark:bg-dark-third rounded py-2 px-3 focus:ring-transparent focus:border-gray-800"
    {placeholder}
    {disabled}
    on:input={handleInput}
    on:change={dispatch("change", value)}
  />
  <div class="absolute top-1.5 left-2">
    <span class="text-sm text-gray-600">+62</span>
  </div>
</div>

{#if errors.length > 0 && errors[0]["error"] == "required"}
  <p class="text-red-500 text-xs italic">Please fill out this field.</p>
{/if}

{#if hint}
  <p class="text-gray-600 text-xs italic">{hintText}</p>
{/if}

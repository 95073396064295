<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let value = "";
  export let actionOne = false;
  export let actionTwo = false;
  export let actionSearch = false;
  let classes = "";
  export let extClass = "";
  export let placeholder = "Search...";
  export let isSearch = false;

  if (actionOne && !actionTwo) {
    classes = "pl-10 pr-10";
  } else if (actionOne && actionTwo) {
    classes = "pl-10 pr-16";
  } else if (isSearch) {
    classes = "pl-2 pr-10";
  } else {
    classes = "pl-10";
  }
</script>

<div class="relative text-gray-600 w-full">
  {#if !isSearch}
    <span class="absolute inset-y-0 left-0 flex items-center pl-3">
      <i class="bx bx-search text-xl" />
    </span>
  {/if}
  <div class="absolute inset-y-0 right-0 flex items-center pr-3">
    {#if actionOne}
      <button on:click={() => dispatch("click-one")} class="flex items-center">
        <i class="bx bx-plus text-xl" />
      </button>
    {/if}
    {#if actionTwo}
      <button on:click={() => dispatch("click-two")} class="flex items-center">
        <i class="bx bx-filter text-xl" />
      </button>
    {/if}
    {#if actionSearch}
      <button
        on:click={() => dispatch("click-search")}
        class="flex items-center bg-white"
      >
        <i class="bx bx-search text-xl" />
      </button>
    {/if}
  </div>
  <input
    type="search"
    name="q"
    bind:value
    on:input={() => dispatch("input", value)}
    on:change={() => dispatch("change", value)}
    class=" w-full py-2 text-sm text-gray-800 rounded-md {classes} {extClass} border-0 focus:outline-none ring-1 ring-gray-200 focus:ring-gray-300 focus:ring-2"
    {placeholder}
    autocomplete="off"
  />
</div>

<script>
  import { fade } from "svelte/transition";
  import { push, replace } from "svelte-spa-router";
  import Router from "svelte-spa-router";
  import Layout from "../../common/layout/LayoutSidebar.svelte";
  import DataList from "../../common/DataDisplay/List.svelte";
  import SearchBar from "../../common/SearchBar.svelte";

  import List from "./List.svelte";
  import Add from "./Add.svelte";
  import Edit from "./Edit.svelte";
  import Info from "./Info.svelte";
  import ListFilterAndAction from "../../common/control/ListFilterAndAction.svelte";
  import Select from "../../common/control/Select.svelte";
  import { onMount } from "svelte";
  import { connection } from "../../store";
  import LoadingText from "../../common/LoadingText.svelte";
	import { debounce } from "lodash";

  export let params = {};

  const prefix = "/users";
  const routes = {
    "/": List,
    "/add": Add,
    "/:_id/edit": Edit,
    "/:_id/info": Info,
    "/:_id/info/*": Info,
  };

  let isLoading = true;
  let search = "";
  let filter = "all";
  let pageNumber = 1;
  let pageSize = 40;
  let loadingList = false;
	let isEndList = false;
  $: dataId = params.wild?.split("/")[0];

  let data;

  $: isFilterActive = filter == "all" && search == "" ? false : true;
  let roles = [];

  let dataFiltered = [];

  onMount(() => {
    loadData();
  });

  function loadData() {
    isLoading = true;
    const keyword = search.trim().toLowerCase();
    $connection.call("users.all",pageNumber, pageSize, keyword, filter).then((result) => {
      if (result) {
        isEndList = data?.length == result.length;
        data = result;
        dataFiltered = data.map((w) => ({ ...w, roles: roles.filter((us) => us.userId == w._id) }));
        isLoading = false;
        loadingList = false;
      }
    });
  }

  function getMoreItems(e) {
		if (e.detail.target.scrollHeight - e.detail.target.scrollTop <= e.detail.target.clientHeight + 20 && !isEndList) {
			pageNumber += 1;

			loadingList = true;
			_debounceLoadData();
		}
	}

  const _debounceLoadData = debounce(function (e) {
		loadData();
	}, 1000);

  function handleSearch() {
    loadData();
  }

  function handleItemClick(e) {
    dataId = e.detail._id;
    replace(`${prefix}/${dataId}/info`);
  }

  function handleAdd() {
    dataId = null;
    push(`${prefix}/add`);
  }

  function clearFilter() {
    filter = 'all';
    search = '';
    loadData();
  }
</script>

<Layout title="Users">
  <div class="flex flex-col md:flex-row md:h-full">
    <div class="flex flex-col md:h-full w-full md:w-1/4 py-3 pl-3 pr-1.5">
      <ListFilterAndAction
        {isFilterActive}
        isClearFilterActive={isFilterActive}
        on:clear={clearFilter}
        on:action={handleAdd}
      >
        <SearchBar 
            bind:value={search} 	
            on:input={debounce(function (e) {
            handleSearch();
          }, 1000)}/>
        <div class="py-2" />
        <Select
          name="type"
          bind:selected={filter}
          on:change={(e) => handleSearch()}
          options={[
              { id: 'all', name: 'All' },
              { id: 'internal', name: 'Internal' },
              { id: 'customer', name: 'Customer' },
          ]}
        />
      </ListFilterAndAction>
      {#if isLoading}
        <LoadingText />
      {:else}
        <DataList
					{loadingList}
          category={true}
          on:scroll={(e) => getMoreItems(e)}
          items={[
            {
              category: `${
                filter == "internal"
                  ? "Internal"
                  : filter == "all"
                  ? "All"
                  : "Customer"
              } (${dataFiltered.length})`,
              items: dataFiltered.map((item) => ({
                _id: item._id,
                title: item.profile?.name,
                icon: "bx bx-user",
                active: item._id == dataId,
              })),
            },
          ]}
          on:item-click={(e) => handleItemClick(e)}
        />
      {/if}
    </div>
    <div
      class="w-full md:h-full flex-1 overflow-auto pr-3 py-3 pl-1.5"
      in:fade={{ duration: 500 }}
    >
      <div class="relative bg-white rounded-lg shadow-md min-w-full min-h-full">
        <Router {routes} {prefix} />
      </div>
    </div>
  </div>
</Layout>

<script lang="ts">
  import { replace } from "svelte-spa-router";
  import { connection } from "../store";
  import { onMount } from "svelte";

  function handleLogout() {
    replace("/login");
    localStorage.clear();
    $connection.logout();
    window.location.reload();
  }
</script>

<div
  class="container relative w-full max-w-full mx-auto flex justify-center items-center py-24 px-6 bg-gray-50 h-screen my-32 md:my-0"
>
  <div class="max-w-5xl w-full px-6 font-sans">
    <div class="relative flex flex-wrap">
      <div class="w-full relative text-center">
        <p class="mb-4">You dont have Authorization for access site.</p>
        <button
          on:click|preventDefault={handleLogout}
          class="px-4 bg-transparent p-2 rounded-lg text-white bg-green-500 hover:opacity-95 ml-2 outline-none"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>

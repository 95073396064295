<script>
  import Template from "./templates/FormTemplate.svelte";
  import { form, field } from "svelte-forms";
  import { required, max,min } from "svelte-forms/validators";
  import { push } from "svelte-spa-router";
  import { connection } from "../../store";
  import utils from "../../utils";

  let status = false;
  let isOpen = true;
  let isLoading;

  let name = field("name", "", [required()]);
  let address = field("address", "", [required()]);
  let desc = field("desc", "", [required()]);
  let phone = field("phone", "", [required()]);
	let tax = field("tax", 10, [required(), min(0), max(100)]);
  let serviceCharge = field("serviceCharge", 15, [required(), min(0), max(100)]);
  let rounding = field("rounding", "DOWN", [required()]);
  let latitude = field("latitude", "", [required()]);
  let longitude = field("longitude", "", [required()]);
  let prefix = field("prefix", "", [required()]);
  let managerIds = field("managerIds", [], [checkMangerIDs()]);

  let errors = [];

  let formAdd = form(
    name,
    address,
    desc,
    phone,
    tax,
    serviceCharge,
    rounding,
    latitude,
    longitude,
    prefix,
    managerIds
  );

  async function handleSubmit(e) {
    const {
      managerIds,
      name,
      address,
      desc,
      status,
      isOpen,
      latitude,
      longitude,
      operationalHours,
      phoneMap,
      tax,
      serviceCharge,
      rounding,
      discountList,
      prefix,
    } = e.detail;
    const serviceChargeMap = Number.isNaN(serviceCharge)
      ? ""
      : utils.formatNumber(serviceCharge);

    errors = [];

    await formAdd.validate();
    const valid = $formAdd.valid;

    if (valid) {
      $connection
        .call(
          "stores.add",
          managerIds,
          name,
          address,
          desc,
          status,
          isOpen,
          latitude,
          longitude,
          operationalHours,
          phoneMap,
          parseFloat(tax),
          serviceChargeMap,
          rounding,
          discountList,
          prefix
        )
        .then((res) => {
          window.pushToast("Store created");
          push(`/stores/${res}/info`);
        })
        .catch((err) => {
          window.pushToast(err.reason, "danger");
        });
    } else {
      errors = $formAdd.errors;
      window.pushToast("Required fields not complete", "danger");
    }
  }
  function checkMangerIDs() {
    return (value) => ({ valid: value.length > 0, name: "empty" });
  }
</script>

<Template
  bind:errors
  bind:name={$name.value}
  bind:address={$address.value}
  bind:desc={$desc.value}
  bind:phone={$phone.value}
  bind:tax={$tax.value}
  bind:serviceCharge={$serviceCharge.value}
  bind:rounding={$rounding.value}
  bind:latitude={$latitude.value}
  bind:longitude={$longitude.value}
  bind:prefix={$prefix.value}
  bind:managerIds={$managerIds.value}
  bind:status
  bind:isOpen
  formTitle="Add Store"
  on:submit={handleSubmit}
/>

<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();
	import Input from "../../../common/control/Input.svelte";
	import Switch from "../../../common/control/Switch.svelte";
	import Button from "../../../common/control/Button.svelte";
	import TextArea from "../../../common/control/TextArea.svelte";
	import Header from "../../../common/Header.svelte";
	import CheckList from "../../../common/control/CheckList.svelte";
	import { printType } from "../../../enum/printType";
	export let id;
	export let name;
	export let desc;
	export let isTopping;
	export let printable;
	export let errors;

	let printOptions = Object.values(printType);
</script>

<Header title={id ? "Edit Category" : "Add Category"}>
	<Button
		leftIcon="bx bx-save"
		on:click={() => {
			dispatch("submit", { name, desc, isTopping, printable });
		}}
	>
		Save Change
	</Button>
</Header>
<div class="space-y-2 p-4">
	<div class="flex-1">
		<Input label="Name" bind:value={name} name="name" bind:errors />
	</div>
	<div class="flex-1">
		<TextArea label="Description" bind:value={desc} name="desc" maxlength={50} bind:errors />
	</div>
	<div class="flex-1 pt-3">
		<Switch
			bind:value={isTopping}
			checked={isTopping}
			name="topping"
			text="Is it Topping ?"
			textRight={isTopping ? "Yes" : "No"}
			on:change={(e) => {
				isTopping = !isTopping;
			}}
		/>
	</div>
	{#if !isTopping}
		<div>
			<label class="block text-primary text-sm font-bold mb-2" for="printable">Print Labels</label>
			<div>
				<CheckList
					items={printOptions}
					checkedItems={printable ?? printOptions.map((item) => item.value)}
					on:change={(e) => {
						const r = e.detail.selected;
						if (printable.includes(r)) {
							printable.splice(
								printable.findIndex((item) => r == item),
								1
							);
						} else printable.push(r);
						printable = printable;
					}}
				/>
			</div>
		</div>
	{/if}
</div>

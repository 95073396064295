<script>
	import { fade } from "svelte/transition";
	import { push } from "svelte-spa-router";
	import Router from "svelte-spa-router";
	import Layout from "../../common/layout/Default.svelte";
	import DataList from "../../common/DataDisplay/List.svelte";
	import LoadingText from "../../common/LoadingText.svelte";
	import SearchBar from "../../common/SearchBar.svelte";
	import { connection } from "../../store";
	import { onMount } from "svelte";

	import List from "./List.svelte";
	import Add from './Add.svelte';
	import Edit from './Edit.svelte';
	import Detail from "./Detail.svelte";

	export let params = {};

	const prefix = "/category";
	const routes = {
		"/": List,
		'/add': Add,
		"/:_id": Detail,
		'/:_id/edit': Edit,
	};

	let isLoading = true;
	let search = "";
	let data;
	let dataFiltered;
	$: dataId = params.wild?.split("/")[0];
	$: if (dataId) {
		dataFiltered = mappingData(data);
	}
	onMount(() => {
		loadData();
	});

	async function loadData() {
		await $connection
			.call("productCategories.all.forList.forAdminLite")
			.then((result) => {
				if (result) {
					data = result;
					dataFiltered = mappingData(result);
					isLoading = false;
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function handleSearch(e) {
		const keyword = e.detail.trim().toLowerCase();
		let itemFiltered = data.filter((item) => {
			if (
				item.name.toLowerCase().includes(keyword)
				// ||
			)
				return true;
			return false;
		});

		dataFiltered = mappingData([...itemFiltered]);
	}

	function mappingData(res) {
		if (res != undefined) {
			const active = res.filter((e) => e["status"] == "ACTIVE");
			const inactive = res.filter((e) => e["status"] == "INACTIVE");
			return [
				{
					category: `Active (${active.length})`,
					items: active.map((item) => categoryMap(item)),
				},
				{
					category: `InActive (${inactive.length})`,
					items: inactive.map((item) => categoryMap(item)),
				},
			];
		} else {
			return [
				{
					category: `Active (0)`,
					items: [],
				},
				{
					category: `InActive (0)`,
					items: [],
				},
			];
		}
	}

	function categoryMap(category) {
		return {
			_id: category._id,
			title: category.name,
			icon: "bx bx-package",
			active: category._id == dataId,
		};
	}

	function handleItemClick(e) {
		dataId = e.detail._id;
		push(`${prefix}/${e.detail._id}`);
	}
	function routeEvent() {
		loadData();
	}
</script>

<Layout title="Product Category">
	<div class="flex flex-col md:flex-row md:h-full">
		<div class="flex flex-col md:h-full w-full md:w-1/4 py-3 pl-3 pr-1.5">
			<SearchBar value={search} on:input={handleSearch} actionOne on:click-one={() => push(`${prefix}/add`)} />
			{#if isLoading}
				<LoadingText />
			{:else}
				<DataList category={true} items={dataFiltered} on:item-click={(e) => handleItemClick(e)} />
			{/if}
		</div>
		<div class="w-full md:h-full flex-1 overflow-auto pr-3 py-3 pl-1.5" in:fade={{ duration: 500 }}>
			<div class="relative bg-white rounded-lg shadow-md min-w-full min-h-full">
				<Router {routes} {prefix} on:routeEvent={routeEvent} />
			</div>
		</div>
	</div>
</Layout>

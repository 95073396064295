<script>
	import { push } from "svelte-spa-router";
	import Template from "./templates/FormTemplate.svelte";
	import { connection } from "../../store";
	import { form, field } from "svelte-forms";
	import { required } from "svelte-forms/validators";
	import LoadingText from "../../common/LoadingText.svelte";
	import { createEventDispatcher } from "svelte";

	export let params = {};
	const dispatch = createEventDispatcher();
	let data;
	let dataId;
	let isLoading;
	let status = true;
	let name = field("name", "", [required()]);
	let description = field("description", "", [required()]);
	let products = field("products", [], [checkEmptyArray()]);
	let formEdit = form(name, description, products);
	let errors = [];

	$: if (params) {
		dataId = params._id;
		loadData(dataId);
	}
	async function loadData(dataId) {
		isLoading = true;
		await $connection
			.call("menus.byId.forAdminLite", dataId)
			.then((result) => {
				data = result;
				$name.value = data?.name;
				$description.value = data?.description;
				status = data?.status == "ACTIVE";
				$products.value = data?.products;
			})
			.catch((error) => {
				console.log(error);
			});
		isLoading = false;
	}
	async function handleSubmit(e) {
		const { name, description, products } = e.detail;
		await formEdit.validate();
		const valid = $formEdit.valid;
		if (valid) {
            const mappedProduct = products.map((e) => ({ _id: e._id, name: e.name, status: e.status }));
			$connection
				.call("menus.edit", dataId, name, description, mappedProduct)
				.then(() => {
					dispatch('routeEvent');
					window.pushToast("Successfully Edit Menu");
					push(`/menu/${dataId}/detail`);
				})
				.catch((error) => {
					window.pushToast(`${error.reason}`, "danger");
				});
		} else {
			errors = $formEdit.errors;
			window.pushToast("Something Wrong! Please check again.", "danger");
		}
	}
	function checkEmptyArray() {
		return (value) => ({ valid: value.length > 0, name: "required" });
	}
</script>

{#if isLoading}
	<div class="p-4">
		<LoadingText />
	</div>
{:else}
	<Template
		id={dataId}
		bind:errors
		bind:products={$products.value}
		bind:name={$name.value}
		bind:description={$description.value}
		bind:status
		on:submit={handleSubmit}
	/>
{/if}

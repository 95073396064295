<script>
	import Modal from "../Modal.svelte";
	import { debounce } from "lodash";
	import LoadingText from "../LoadingText.svelte";
	import Button from "../control/Button.svelte";
	import { connection } from "../../store";
	import { createEventDispatcher } from "svelte";
	import ProductCard from "../card/ProductCard.svelte";
	import { formatRupiah } from "../helper/FormHelper";
	const dispatch = createEventDispatcher();

	export let storeId;
	export let storeProductsId;
	let modalAddProduct;
	let dataFiltered = [];
	let tempProductSelected = [];
	let isLoading = false,
		showPaginate = false,
		isEndPaginate = false;
	let searchProduct = "";
	let page = 1,
		perPage = 100;
	async function loadProducts() {
		// reset value
		showPaginate = false;
		isEndPaginate = false;
		isLoading = true;

		// get data
		const selectedProductIds = storeProductsId;
		$connection.call("products.all.forList.pagination.search", storeId, page, perPage, searchProduct.trim().toLowerCase(), selectedProductIds).then((result) => {
			resolveSelected(result.data);

			if (result.length > perPage) {
				showPaginate = true;
				isEndPaginate = Math.floor(result.totalPage) == page;
			}

			isLoading = false;
		});
	}
	function handleAddProduct(item) {
		const isSelected = !item.isSelected;
		item.isSelected = isSelected;

		if (isSelected) {
			tempProductSelected.push(item._id);
		} else {
			tempProductSelected = tempProductSelected.filter((it) => it != item._id);
		}
		tempProductSelected = tempProductSelected;

		resolveSelected(dataFiltered);
	}
	async function handleSelectProduct() {
		if (tempProductSelected.length == 0) {
			window.pushToast("Please add min 1 product!", "danger");
			return;
		}
		isLoading = true;
		await $connection
			.call("stores.add.products", storeId, tempProductSelected)
			.then((res) => {
				window.pushToast("Successfully Add Product.", "success");
				isLoading = false;
				tempProductSelected = [];
				searchProduct = "";
				page = 1;
				dispatch("load-data");
				modalAddProduct.toggle();
			})
			.catch((err) => {
				console.log("err", err);
				window.pushToast("Something Wrong! Cant Add Product.", "danger");
				isLoading = false;
			});
	}
	function handleSearch() {
		page = 1;
		loadProducts();
	}
	function resolveSelected(_data) {
		dataFiltered = _data.map((e) => ({
			...e,
			isSelected: tempProductSelected.includes(e._id) ? true : false,
		}));
	}
	export function open() {
		modalAddProduct.toggle();
		searchProduct = "";
		page = 1;
		loadProducts();
	}

	export function close() {
		modalAddProduct.close();
	}
</script>

<Modal
	bind:this={modalAddProduct}
	title="Add Product"
	closed
	extClass="overflow-hidden"
	on:close={() => {
		tempProductSelected = [];
		page = 1;
		searchProduct = "";
		modalAddProduct.toggle();
	}}
>
	<input
		bind:value={searchProduct}
		on:input={debounce(function (e) {
			handleSearch();
		}, 1000)}
		type="text"
		placeholder="Search Product.."
		class="appearance-none block pl-5 pr-5 w-full text-dark-second border dark:text-white dark:bg-dark-third rounded-full py-2 px-3 focus:ring-transparent focus:border-gray-800 outline-none"
	/>
	{#if isLoading}
		<div class="p-4">
			<LoadingText />
		</div>
	{:else}
		{#if dataFiltered.length == 0}
			<p class="m-0 text-gray-400 p-4">No Products Available.</p>
		{/if}
		<div class="grid grid-cols-4 gap-4 py-4 my-2 overflow-y-auto" style="max-height: calc(70vh - 30px);">
			{#each dataFiltered as product}
				<ProductCard
					image={product.images[0].url}
					name={product.name}
					description={formatRupiah(product.price, "Rp. ")}
					selectable
					selected={product.isSelected}
					on:card-click={() => handleAddProduct(product)}
				/>
			{/each}
		</div>
		{#if dataFiltered.length > 0}
			<div class="flex justify-between w-full">
				<div class="flex space-x-2">
					{#if showPaginate}
						<Button
							disabled={page == 1}
							on:click={async () => {
								if (page > 1) {
									page -= 1;
									await loadProducts();
								}
							}}>Prev</Button
						>
						<Button
							disabled={isEndPaginate}
							on:click={async () => {
								if (!isEndPaginate) {
									page += 1;
									await loadProducts();
								}
							}}>Next</Button
						>
					{/if}
				</div>
				<Button status="success" on:click={() => handleSelectProduct()}>Select Product</Button>
			</div>
		{/if}
	{/if}
</Modal>

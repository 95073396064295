<script>
	import { onMount } from "svelte";
	import LayoutSidebar from "../common/layout/LayoutSidebar.svelte";
	import CardDashboard from "../common/card/CardDashboard.svelte";
	import CardDashboardTotal from "../common/card/CardDashboardTotal.svelte";
	import Loading from "../common/Loading.svelte";
	import utils from "../utils";
	import { connection } from "../store";

	let transCount = 0;
	let storesCount = 0;
	let productsCount = 0;
	let campaignsCount = 0;
	let promosCount = 0;
	let isLoading;
	let check = 0;
	onMount(() => {
		if (check == 0) {
			utils.handleUnAutorized();
			check = 1;
		}
		isLoading.toggle();
		$connection
			.call("dashboard.get.data")
			.then((result) => {
				storesCount = result.stores;
				productsCount = result.products;
				campaignsCount = result.campaigns;
				promosCount = result.promos;
				transCount = result.transactions;
			})
			.catch((error) => {
				console.log(error);
				window.pushToast("Something Wrong! Cant load data.", "danger");
			});
		isLoading.toggle();
	});
</script>

<Loading text="Loading..." bind:this={isLoading} />
<LayoutSidebar title="Dashboard">
	<div class="p-4">
		<div class="grid grid-cols-1 gap-4 mb-4">
			<CardDashboardTotal title="Total Transaction" type="number" content={transCount} />
		</div>
		<div class="grid grid-cols-2 md:grid-cols-4 gap-4">
			<CardDashboard title="Store" content={storesCount} />
			<CardDashboard title="Product" content={productsCount} />
			<CardDashboard title="Campaign" content={campaignsCount} />
			<CardDashboard title="Promo" content={promosCount} />
		</div>
	</div>
</LayoutSidebar>

<script>
  export let status = "primary";
  export let height = "1";
  let classes = "";

  if (status === "primary") {
    classes = "border-primary";
  } else if (status === "info") {
    classes = "border-blue-500";
  } else if (status === "warning") {
    classes = "border-yellow-400";
  } else if (status === "danger") {
    classes = "border-red-500";
  } else if (status === "dark") {
    classes = "border-gray-800";
  } else if (status === "light") {
    classes = "border-gray-200";
  } else if (status === "white") {
    classes = "border-white";
  } else if (status === "black") {
    classes = "border-black";
  } else if (status === "gray") {
    classes = "border-gray-300";
  }
</script>

<hr class="border-{height} {classes}" />

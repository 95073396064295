<script>
	import Modal from "../Modal.svelte";
	import { debounce } from "lodash";
	import LoadingText from "../LoadingText.svelte";
	import Button from "../control/Button.svelte";
	import { connection } from "../../store";
	import { formatRupiah } from "../helper/FormHelper";
	import { noImg } from "../helper/StringHelper";

	export let selectedToppings;
	let modalTopping;

	let dataToppings = [],
		tempSelectedToppings = [];
	let loadingToppings = false,
		showPaginateToppings = false,
		isEndPaginateToppings = false;
	let searchTopping = "";
	let page = 1,
		perPage = 25;
	async function loadToppings() {
		// reset value
		showPaginateToppings = false;
		isEndPaginateToppings = false;
		dataToppings = [];
		loadingToppings = true;

		const selectedIds = selectedToppings.length > 0 ? selectedToppings.map((prod) => prod._id) : [];
		await $connection
			.call("products.topping.pagination.search", page, perPage, searchTopping.trim().toLowerCase(), selectedIds)
			.then((result) => {
				resolveSelected(result.data, "topping");

				if (result.length > perPage) {
					showPaginateToppings = true;
					isEndPaginateToppings = Math.floor(result.totalPage) == page;
				}

				loadingToppings = false;
			})
			.catch((err) => {
				window.pushToast(err.error, "danger");
				loadingToppings = false;
			});
	}
	const handleSearchTopping = async () => {
		page = 1;
		await loadToppings();
	};
	const handleAddToppings = (item) => {
		const data = {
			_id: item._id,
			name: item.name,
			desc: item.desc,
			price: item.price,
			sku: item.sku,
			ingredients: item.ingredients,
			images: item.images,
			status: item.status,
			isSelected: !item.isSelected,
		};

		if (data.isSelected) {
			tempSelectedToppings.push(data);
		} else {
			tempSelectedToppings = tempSelectedToppings.filter((it) => it._id != item._id);
		}
		tempSelectedToppings = tempSelectedToppings;

		resolveSelected(dataToppings, "topping");
	};

	const handleSelectToppings = () => {
		selectedToppings = [...selectedToppings, ...tempSelectedToppings];
		tempSelectedToppings = [];
		window.pushToast("Successfully Add Toppings.", "success");
		modalTopping.toggle();
	};
	const resolveTempSelected = (item) => {
		return tempSelectedToppings.length > 0 && tempSelectedToppings.filter((it) => it._id === item._id).length == 1;
	};
	const resolveSelected = (_data = []) => {
		dataToppings = _data.map((e) => ({
			...e,
			isSelected: tempSelectedToppings.some((temp) => temp._id == e._id),
		}));
		return;
	};
	export function open() {
		modalTopping.toggle();
		searchTopping = "";
		page = 1;
		loadToppings();
	}

	export function close() {
		modalTopping.close();
	}
</script>

<Modal
	bind:this={modalTopping}
	title="Add Topping"
	closed
	on:close={() => {
		tempSelectedToppings = [];
		modalTopping.toggle();
	}}
>
	<div class="flex flex-col">
		<input
			bind:value={searchTopping}
			on:input={debounce(function (e) {
				handleSearchTopping();
			}, 1000)}
			type="text"
			placeholder="Search Topping.."
			class="appearance-none block pl-5 pr-5 w-full text-dark-second border dark:text-white dark:bg-dark-third rounded-full py-2 px-3 focus:ring-transparent focus:border-gray-800 outline-none"
		/>
		{#if loadingToppings}
			<div class="p-4">
				<LoadingText />
			</div>
		{:else}
			{#if dataToppings.length == 0}
				<p class="m-0 text-gray-400 p-4">No Toppings Available.</p>
			{/if}
			<div class="grid grid-cols-4 gap-4 my-4 overflow-y-auto" style="max-height: calc(70vh - 30px);">
				{#each dataToppings as topp}
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<div
						on:click={() => handleAddToppings(topp)}
						class="relative p-2 flex flex-col items-center justify-center border {resolveTempSelected(topp, 'topping')
							? 'bg-gray-200'
							: ''} rounded-md cursor-pointer hover:opacity-90"
					>
						{#if resolveTempSelected(topp, "topping")}
							<div class="absolute top-2 right-2 bg-green-500 text-white rounded-full h-6 w-6 flex justify-center items-center">
								<i class="bx bx-check" />
							</div>
						{/if}
						<img class="w-20 h-20 rounded-md object-cover" src={topp.images[0].url != "" ? topp.images[0].url : noImg} alt=".." />
						<h4 class="text-gray-800 text-md mt-3 font-medium text-center">{topp.name}</h4>
						<!-- svelte-ignore missing-declaration -->
						<p class="text-gray-800 text-md font-medium">{formatRupiah(topp.price, "Rp. ")}</p>
					</div>
				{/each}
			</div>
			{#if dataToppings.length > 0}
				<div class="flex justify-between w-full">
					<div class="flex space-x-2">
						{#if showPaginateToppings}
							<Button
								disabled={page == 1}
								on:click={async () => {
									if (page > 1) {
										page -= 1;
										await loadToppings();
									}
								}}>Prev</Button
							>
							<Button
								disabled={isEndPaginateToppings}
								on:click={async () => {
									if (!isEndPaginateToppings) {
										page += 1;
										await loadToppings();
									}
								}}>Next</Button
							>
						{/if}
					</div>
					<Button status="success" on:click={() => handleSelectToppings()}>Select Toppings</Button>
				</div>
			{/if}
		{/if}
	</div>
</Modal>

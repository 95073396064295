<script>
  import { debounce } from "lodash";
  import { onMount } from "svelte";
  import LoadingModal from "../../../common/Loading.svelte";
  import Loading from "../../../common/LoadingText.svelte";
  import Modal from "../../../common/Modal.svelte";
  import ModalMenu from "../../../common/Modal/ModalMenu.svelte";
  import SearchBar from "../../../common/SearchBar.svelte";
  import Tile from "../../../common/Tile.svelte";
  import Button from "../../../common/control/Button.svelte";
  import ExpandableSection from "../../../common/control/ExpandableSection.svelte";
  import { connection } from "../../../store";


  export let id;
  let menuList = [];
  let modalAddMenu;
  let modalDeleteMenu;
  let deletedMenu;
  let loadingMenuProducts = false;
  let isLoading;

  let pageList = 1, perPageList = 10;
	let showStoreMenuPaginate = false,
		isStoreMenuEndPaginate = false;
	let totalMenus;
	let storeMenuIds;
  let searchMenu = '';


  onMount(() => {
    loadData();
  });

  function loadData() {
    // reset value
    showStoreMenuPaginate = false;
		isStoreMenuEndPaginate = false;
		menuList = [];
    loadingMenuProducts = true;

		$connection
			.call("stores.menus.pagination", id, pageList, perPageList, searchMenu.toString())
			.then((result) => {
				menuList = result.menus;
				totalMenus = result.totalMenus;
        storeMenuIds = result.storeMenuIds;
				if (result.length > perPageList) {
					showStoreMenuPaginate = true;
					isStoreMenuEndPaginate = Math.floor(result.totalPage) == pageList;
				}
        loadingMenuProducts = false;
			})
			.catch((err) => {
				window.pushToast(err.error, "danger");
			});
  }
 
	async function onOpenModalMenu() {
		modalAddMenu.open();
	}

  function deleteMenu(menu) {
    isLoading.toggle();
    $connection
      .call("stores.remove.menu", id, menu._id)
      .then((result) => {
        window.pushToast("Successfully Deleted Menu.", "success");
        modalDeleteMenu.toggle();
        pageList = 1;
        loadData();
        isLoading.toggle();
      })
      .catch((err) => {
        window.pushToast("Something Wrong! Cant Remove Menu.", "danger");
        isLoading.toggle();
      });
  }

  function handleSearch() {
    pageList = 1;
    loadData();
  }
</script>

<ModalMenu
	bind:this={modalAddMenu}
	bind:storeId={id}
	bind:storeMenuIds
	on:load-data={() => {
		pageList = 1;
		loadData();
	}}
></ModalMenu> 
<Modal
  size="small"
  title="Delete Menu"
  confirm
  bind:this={modalDeleteMenu}
  on:submit={() => deleteMenu(deletedMenu)}
  on:cancel={() => {
    deletedMenu = null;
    modalDeleteMenu.toggle();
  }}
/>

<LoadingModal text="Loading..." bind:this={isLoading} />

<Tile
  title="Total Menus"
  info={totalMenus}
/>
<div class="mt-5">
  <div class="flex space-x-2 mb-4 items-center">
    <div class="flex-1">
      <SearchBar
          bind:value={searchMenu}
          on:input={debounce(function (e) {
              handleSearch();
          }, 1000)}
      />
    </div>
      <Button
        leftIcon="bx bx-plus"
        on:click={() => {
          onOpenModalMenu();
        }}>Add Menu</Button
      >
  </div>
</div>

{#if loadingMenuProducts}
  <Loading />
{:else if !menuList.length}
  <tr>
    <td colspan="3" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      No Menu Available
    </td>
  </tr>
{:else}
  <div>
    <ExpandableSection
      data={menuList}
      on:delete={(e) => {
        deletedMenu = e.detail;
        modalDeleteMenu.toggle();
      }}
      hasStatusOnChild={true}
    />
  </div>
  {#if showStoreMenuPaginate}
		<div class="flex space-x-2 mt-4">
			<Button
				disabled={pageList == 1}
				on:click={() => {
					if (pageList > 1) {
						pageList -= 1;
            loadData();
					}
				}}>Prev</Button
			>
			<Button
				disabled={isStoreMenuEndPaginate}
				on:click={() => {
					if (!isStoreMenuEndPaginate) {
						pageList += 1;
            loadData();
					}
				}}>Next</Button
			>
		</div>
	{/if}
{/if}

<script>
	import { replace } from "svelte-spa-router";
	import Template from "./templates/FormTemplate.svelte";
	import { connection } from "../../store";
	import { form, field } from "svelte-forms";
	import { required } from "svelte-forms/validators";
	import Loading from "../../common/Loading.svelte";
	import { createEventDispatcher } from "svelte";

	export let params = {};

	const { _id } = params;
	const dispatch = createEventDispatcher();
	let isLoading;
	let dataId;
	let data;
	let errors = [];

	$: if (params) {
		dataId = params._id;
		loadData(dataId);
	}

	let name = field("name", "", [required()]);
	let desc = field("desc", "", [required()]);
	let items = field("items", [], [checkEmptyArray()]);
	let isRequired = field("isRequired", false, [required()]);
	let formEdit = form(name, desc, items);

	async function loadData(dataId) {
		await $connection
			.call("products.additionals.byId.forAdminLite", dataId)
			.then((result) => {
				data = result;
				$name.value = data?.name;
				$desc.value = data?.desc;
				$items.value = data?.items;
				$isRequired.value = data?.isRequired;
			})
			.catch((error) => {
				console.log(error);
			});
	}
	function handleSubmit(e) {
		const { name, desc, itemMap, isRequired } = e.detail;
		formEdit.validate();
		const valid = $formEdit.valid;
		if (valid) {
			isLoading.toggle();
			$connection
				.call("products.additionals.update", dataId, name, desc, itemMap, isRequired)
				.then(() => {
					dispatch("routeEvent");
					setTimeout(() => {
						replace(`/modifier/${dataId}`);
					}, 1000);
					window.pushToast("Successfully Update Modifier.", "success");
				})
				.catch((error) => {
					window.pushToast(error, "danger");
				});
		} else {
			errors = $formEdit.errors;
			window.pushToast("Something Wrong! Please check again form field.", "danger");
		}
	}
	function checkEmptyArray() {
		return (value) => ({ valid: value.length > 0, name: "required" });
	}
</script>

<Loading text="Loading..." bind:this={isLoading} />

{#if data}
	<Template
		id={dataId}
		bind:errors
		bind:name={$name.value}
		bind:desc={$desc.value}
		bind:items={$items.value}
		bind:isRequired={$isRequired.value}
		on:submit={handleSubmit}
	/>
{/if}

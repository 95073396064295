<script>
  import { createEventDispatcher } from "svelte";
  import { noImg } from "../../config";
  export let image;
  export let name;
  export let description;
  export let selected = false;
  export let selectable = false;
  const dispatch = createEventDispatcher();

  $: source = image ? image : noImg;
</script>

<div
  class="relative overflow-hidden w-full rounded-lg shadow-md cursor-pointer bg-white"
  on:click={() => dispatch("card-click")}
>
  <img
    class="object-cover w-full lg:h-32 md:h-64 h-32"
    src={source}
    alt={name ? name : "profile-img"}
  />
  <div class="py-3 px-2">
    <h4 class="text-sm font-bold">{name}</h4>
    <div class="text-xs mb-2 mt-1">
      {#if description}
        <span>{description}</span>
      {:else}
        <span class="text-gray-300"> No description </span>
      {/if}
    </div>
  </div>
  {#if selectable}
    <div
      class="absolute top-2 right-2 rounded border w-7 h-7 bg-white bg-opacity-50"
    >
      {#if selected}
        <div class="text-center">
          <i class="bx bx-check bx-sm text-primary" />
        </div>
      {/if}
    </div>
  {/if}
</div>

<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();
	import Input from "../../../common/control/Input.svelte";
	import MoneyInput from "../../../common/control/MoneyInput.svelte";
	import Button from "../../../common/control/Button.svelte";
	import TextArea from "../../../common/control/TextArea.svelte";
	import Header from "../../../common/Header.svelte";
	import { formatNumber } from "../../../common/helper/FormHelper";
	import Util from "../../../common/Util/Utility";
	import Switch from "../../../common/control/Switch.svelte";

	export let id;
	export let name;
	export let desc;
	export let items = [];
	export let errors = [];
	export let isRequired;

	function handleAddItem() {
		// need to add code
		items.push({ name: "", price: 0, default: false, code: Util.generateId(6) });
		items = items;
	}

	function handleItemNameChange(index, value) {
		items[index].name = value;
		items = items;
	}

	function handleItemPriceChange(index, value) {
		items[index].price = value;
		items = items;
	}

	function handleToggleItemDefault(index) {
		items[index].default = !items[index].default;
		items = items;
	}

	function handleSetItemDefault(index) {
		if (items.filter((it) => it.default == true).length == 0) {
			items[index].default = true;
			items = items;
		} else {
			items.map((it) => {
				if (it.default == true) {
					it.default = false;
				}
			});
			items[index].default = true;
			items = items;
		}
	}

	function handleRemoveItem(index) {
		items = items.filter((item, idx) => idx != index);
	}
</script>

<Header title={id ? "Edit Modifier" : "Add Modifier"}>
	<Button
		leftIcon="bx bx-save"
		on:click={() => {
			const itemDefaultCheck = items.find((e) => e["default"] == true); // find the default child
			if (itemDefaultCheck == null) {
				window.pushToast("Default Modifier is Required", "danger");
				return;
			}

			const namedItems = items.filter((it) => it.name !== ""); // filter named child
			if (itemDefaultCheck.name == "" || namedItems.length == 0) {
				window.pushToast("Required Items min 1.", "danger");
				return;
			}

			const itemMap = namedItems.map((item) => {
				let newPrice;
				if (item["price"].toString().includes("Rp.")) {
					newPrice = formatNumber(item.price);
				} else {
					newPrice = item.price;
				}
				return {
					name: item.name,
					price: isNaN(newPrice) ? 0 : parseFloat(newPrice.toString().replace(/[^0-9\,]+/g, "") ?? 0),
					default: item.default,
					code: item.code,
				};
			});

			dispatch("submit", { name, desc, itemMap, isRequired });
		}}
	>
		Save Change
	</Button>
</Header>
<div class="space-y-2 p-4">
	<div class="flex-1 mr-1">
		<Input bind:errors label="Name" placeholder="ex: Ice" bind:value={name} name="name" />
	</div>
	<div class="flex-1 mr-1">
		<TextArea bind:errors label="Description" placeholder="ex: add ice" bind:value={desc} name="desc" />
	</div>
	<div>
		<Switch
			bind:value={isRequired}
			checked={isRequired}
			name="status"
			text="Apakah Modifier Wajib?"
			textRight={isRequired ? "Modifier Wajib" : "Modifier Tidak Wajib"}
			on:change={(e) => {
				isRequired = !isRequired;
			}}
		/>
	</div>
	<div class="flex-1 space-y-2">
		<label>Items</label>
		{#each items as item, index}
			<div class="flex items-center space-x-2">
				<div class="flex rounded border bg-gray-100 items-center justify-center w-10 h-10">
					{index + 1}
				</div>
				<div class="flex-1">
					<Input
						noLabel
						placeholder="name ex: medium"
						value={item.name}
						name="item-name"
						on:change={(e) => handleItemNameChange(index, e.detail)}
					/>
				</div>
				<div class="flex-1">
					<MoneyInput
						noLabel
						placeholder="price ex: 1000"
						value={item.price}
						name="item-price"
						on:change={(e) => handleItemPriceChange(index, e.detail)}
					/>
				</div>
				<div class="flex space-x-2 flex-1 items-center">
					{#if item.default}
						<Button status="success" on:click={() => handleToggleItemDefault(index)}>Default</Button>
					{:else}
						<Button on:click={() => handleSetItemDefault(index)}>Set as default</Button>
					{/if}
					<Button leftIcon="bx bx-trash" on:click={() => handleRemoveItem(index)} />
				</div>
			</div>
		{/each}
		<Button extClass="mt-2" on:click={handleAddItem}>Add new item</Button>
	</div>
</div>

<script>
  export let title = "";
</script>

<div
  class="relative flex justify-between bg-primarydark rounded-t-lg px-4 py-2"
>
  <p class="text-white py-1"><b>{title}</b></p>
  <div class="flex space-x-2">
    <slot />
  </div>
</div>

<script>
	import { push, replace } from "svelte-spa-router";
	import Button from "../../../common/control/Button.svelte";
	import Modal from "../../../common/Modal.svelte";
	import Header from "../../../common/Header.svelte";
	import Loading from "../../../common/Loading.svelte";
	import { formatRupiah } from "../../../common/helper/FormHelper";
	import { connection } from "../../../store";
	import { createEventDispatcher } from "svelte";

	export let id;
	export let data;
	let isLoading;
	const dispatch = createEventDispatcher();
	let modalConfirmDelete;

	function handleDelete() {
		isLoading.toggle();
		$connection
			.call("products.additionals.remove", id)
			.then(() => {
				dispatch("reload-data");
				replace(`/modifier`);
				window.pushToast("Successfully Deleted Modifier.", "success");
			})
			.catch((error) => {
				console.log(error);
				window.pushToast("Something Wrong! Cant deleted modifier.", "danger");
			});
		isLoading.toggle();
		modalConfirmDelete.toggle();
	}
</script>

<Loading text="Loading..." bind:this={isLoading} />
<Modal
	size="small"
	title="Delete Modifier"
	confirm
	bind:this={modalConfirmDelete}
	on:submit={handleDelete}
	on:cancel={() => {
		modalConfirmDelete.toggle();
	}}
/>
<Header title="Modifier Information">
	<Button leftIcon="bx bx-edit" on:click={() => push(`/modifier/${id}/edit`)}>Edit</Button>
	<Button leftIcon="bx bx-trash" on:click={modalConfirmDelete.toggle()}>Delete</Button>
	<Button leftIcon="bx bx-notification" on:click={() => dispatch('activated', id)}>Activate</Button>
    <Button leftIcon="bx bx-notification-off" on:click={() => dispatch('deactivated', id)}>Deactivate</Button>
</Header>
<div class="relative p-4">
	<div class="mb-4">
		<div class="flex flex-col relative">
            <div class="flex items-center">
                <div class="text-sm text-gray-500 w-1/6">Name</div>
                <div class="">:&nbsp;</div>
                <div class="flex-1">{data.name}</div>
            </div>
            <div class="flex items-start">
                <div class=" text-sm text-gray-500  w-1/6">Description</div>
                <div class="">:&nbsp;</div>
                <div class=" flex-1">{data.desc}</div>
            </div>
            <div class="flex items-center">
                <div class=" text-sm text-gray-500  w-1/6">Status</div>
                <div class="">:&nbsp;</div>
                <div class=" flex-1">{data.status == 'ACTIVE' ? 'Active' : 'InActive'}</div>
            </div>
            <div class="flex items-center">
                <div class=" text-sm text-gray-500  w-1/6">Apakah Modifier Wajib</div>
                <div class="">:&nbsp;</div>
                <div class=" flex-1">{data.isRequired ? 'Modifier Wajib' : 'Modifier Tidak Wajib'}</div>
            </div>
        </div>
	</div>
	<hr class="border-gray-100 my-2" />
	<table class="min-w-full divide-y divide-gray-200">
		<thead class="bg-gray-50">
			<tr>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> No </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Name </th>
				<th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"> Price </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" />
			</tr>
		</thead>
		<tbody class="bg-white divide-y divide-gray-200">
			{#each data.items as item, index}
				<tr>
					<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
						{index + 1}
					</td>
                    <td class="px-6 py-4 max-w-xs break-words whitespace-normal text-sm text-gray-800">
						{item.name}
					</td>
					<td class="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
						{formatRupiah(item.price, "Rp. ")}
					</td>
					<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
						{#if item.default}
							<i class="bx bxs-check-circle text-green-500 text-lg" />
						{/if}
					</td>
				</tr>
			{/each}
		</tbody>
	</table>
</div>

<script>
	import { replace } from "svelte-spa-router";
	import { connection, loggedInUserId } from "../store";
	export let title = "Dashboard";

	function handleLogout() {
		replace("/login");
		localStorage.clear();
		$connection.logout();
		window.location.reload();
	}

	export let showSidebar;
	let name = "admin";
	connection.subscribe((server) => {
		server.subscribe("users.findOne", $loggedInUserId);
		let userCollection = server.collection("users").reactive().one();
		if (userCollection.data()) {
			name = userCollection.data().profile.name;
		}

		userCollection.onChange((data) => {
			name = data.profile.name;
		});
	});
</script>

<nav class="mx-3 border-b-2 bg-transparent md:flex-row md:flex-no-wrap justify-between flex items-center h-14">
	<div class="flex items-center text-lg font-semibold">
		<button
			on:click={() => {
				showSidebar = !showSidebar;
			}}
			class="md:hidden cursor-pointer"
		>
			<i class="bx bx-menu bx-sm text-primary mr-4 pt-1" />
		</button>
		<p class="md:pl-2">{title}</p>
	</div>
	<div class="flex items-end justify-end px-4">
		<!-- User -->
		<span class="flex-col md:flex-row list-none items-center hidden md:flex text-sm mr-1">
			{name}
			<i class="bx bx-user-circle bx-sm text-primary ml-1" />
		</span>
		<a href="#" class="flex-col md:flex-row list-none items-center hidden md:flex text-sm" on:click={handleLogout}
			><i class="bx bx-log-out bx-sm text-primary" /></a
		>
	</div>
</nav>

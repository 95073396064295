<script>
	import { replace } from "svelte-spa-router";
	import Template from "./templates/FormTemplate.svelte";
	import { form, field } from "svelte-forms";
	import { required } from "svelte-forms/validators";
	import Loading from "../../common/Loading.svelte";
	import { connection } from "../../store";
	import { createEventDispatcher } from "svelte";

	let name = field("name", "", [required()]);
	let desc = field("desc", "", [required()]);
	let items = field("items", [], [checkEmptyArray()]);
	let isRequired = field("isRequired", false, [required()]);

	let formAdd = form(name, desc, items, isRequired);
	let isLoading;
	let errors = [];
	const dispatch = createEventDispatcher();

	async function handleSubmit(e) {
		await formAdd.validate();
		const valid = $formAdd.valid;
		if (valid) {
			isLoading.toggle();
			const { name, desc, itemMap, isRequired } = e.detail;
			$connection
				.call("products.additionals.add", name, desc, itemMap, isRequired)
				.then((result) => {
					dispatch("routeEvent");
					setTimeout(() => {
						replace(`/modifier/${result}`);
					}, 1000);
					window.pushToast("Successfully Add Modifier.", "success");
				})
				.catch((error) => {
					console.log(error);
					window.pushToast(error, "danger");
				});
		} else {
			errors = $formAdd.errors;
			window.pushToast("Something Wrong! Please check again form field.", "danger");
		}
	}
	function checkEmptyArray() {
		return (value) => ({ valid: value.length > 0, name: "required" });
	}
</script>

<Loading text="Loading..." bind:this={isLoading} />

<Template
	bind:errors
	bind:name={$name.value}
	bind:desc={$desc.value}
	bind:items={$items.value}
	bind:isRequired={$isRequired.value}
	on:submit={handleSubmit}
/>

<script>
	import { createEventDispatcher } from "svelte";
	export let title = "icon-button";
	export let icon = "bx bx-trash";
	export let type = "danger";
	export let small = false;
	export let rounding = false;
	const dispatch = createEventDispatcher();
</script>

<span
	class="{small ? 'w-6 h-6' : 'w-8 h-8'} {rounding ? 'rounded-full' : 'rounded-md'} {type == 'white'
		? 'bg-transparent hover:opacity-80'
		: 'bg-white hover:bg-gray-200'} cursor-pointer text-center"
	on:click={(e) => dispatch("click", e)}
	{title}
>
	<i class="{icon} text-{type} {small ? '' : 'mt-2'}" />
</span>

<script>
  import Util from "../../common/Util/Utility";
  import { createEventDispatcher } from "svelte";

  import Check from "./Check.svelte";

  const dispatch = createEventDispatcher();

  export let items = [];
  export let status = "primary";
  export let checkedItems = [];

  const name = `cbx-${Util.generateId(5)}`;
</script>

{#each items as item}
  <Check
    bind:text={item.text}
    {name}
    bind:value={item.value}
    checked={checkedItems.includes(item.value)}
    on:change={() =>
      dispatch("change", { selected: item.value, index: item.index })}
    disabled={item.disabled}
  />
{/each}

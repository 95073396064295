<script>
  import { debounce } from "lodash";
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import Loading from "../../../common/Loading.svelte";
  import Modal from "../../../common/Modal.svelte";
  import ModalProduct from "../../../common/Modal/ModalProduct.svelte";
  import SearchBar from "../../../common/SearchBar.svelte";
  import Tile from "../../../common/Tile.svelte";
  import Button from "../../../common/control/Button.svelte";
  import { connection } from "../../../store.js";
  
  export let id;
  export let data = null;

  let modalAddProduct;
  let modalConfirmDelete;
  let idProduct = undefined;
  let isLoading;

  $: tempProductSelected = [];

  // Meteor.subscribe('products.all.forSelection');
  // let sub = $connection.subscribe("products.all.forSelection");

  let pageList = 1, perPageList = 10;
	let showStoreProductPaginate = false,
		isStoreProductEndPaginate = false;
  
  let storeProducts = [];
  let storeProductsId = [];
  let totalProducts = 0;
  let searchProduct = '';

  onMount(() => {
    loadStoreProducts();
  });

  function loadStoreProducts() {
		// reset value
		showStoreProductPaginate = false;
		isStoreProductEndPaginate = false;
		storeProducts = [];

		isLoading.toggle();
		$connection
			.call("stores.products.byStore.pagination", id, pageList, perPageList, searchProduct.toString())
			.then((result) => {
				storeProducts = result.products;
        totalProducts = result.totalProducts;
				storeProductsId = result.storeProductIds;
				if (result.length > perPageList) {
					showStoreProductPaginate = true;
					isStoreProductEndPaginate = Math.floor(result.totalPage) == pageList;
				}
		    isLoading.toggle();
			})
			.catch((err) => {
        console.log(err);
				window.pushToast(err.error, "danger");
			});
	}

  function showAddProduct() {
    modalAddProduct.open();
  }

  async function handleDelete() {
    tempProductSelected = [];
    isLoading.toggle();
    await $connection
      .call("stores.remove.product", id, idProduct)
      .then((res) => {
        isLoading.toggle();
        modalConfirmDelete.toggle();
        window.pushToast('Successfully Deleted Product.', 'success');
        pageList = 1;
        loadStoreProducts();
      })
      .catch((err) => {
        console.log("err", err);
        window.pushToast("Something Wrong! Cant Deleted Product.", "danger");
      });
  }
  let modalConfirmDeleteStore;

  async function handleDeleteStore() {
    isLoading.toggle();
    await $connection
      .call("stores.remove", id)
      .then((res) => {
        window.pushToast("Successfully Deleted Store.", "success");
        setTimeout(() => {
          push("/stores");
        }, 2000);
      })
      .catch((err) => {
        console.log("err", err);
        window.pushToast("Something Wrong! Cant deleted Store.", "danger");
        isLoading.toggle();
      });
  }

  function handleSearch() {
    pageList = 1;
    loadStoreProducts();
  }
</script>

<Modal
  size="small"
  title="Delete Store"
  confirm
  bind:this={modalConfirmDeleteStore}
  on:submit={handleDeleteStore}
  on:cancel={() => {
    modalConfirmDeleteStore.toggle();
  }}
/>

<Modal
  size="small"
  title="Delete Product"
  confirm
  bind:this={modalConfirmDelete}
  on:submit={handleDelete}
  closed
  on:close={() => {
    idProduct = undefined;
    modalConfirmDelete.toggle();
  }}
  on:cancel={() => {
    idProduct = undefined;
    modalConfirmDelete.toggle();
  }}
/>
<ModalProduct
	bind:this={modalAddProduct}
	bind:storeId={id}
	bind:storeProductsId
	on:load-data={() => {
		pageList = 1;
		loadStoreProducts();
	}}
></ModalProduct> 

<Loading text="Loading..." bind:this={isLoading} />

<Tile title="Total Products" info={totalProducts} />
<div class="mt-5">
  <div class="flex space-x-2 mb-4 items-center">
      <div class="flex-1">
        <SearchBar
            bind:value={searchProduct}
            on:input={debounce(function (e) {
                handleSearch();
            }, 1000)}
        />
      </div>
      <Button
        leftIcon="bx bx-plus"
        on:click={() => {
         showAddProduct();
        }}>Add Product</Button
      >
      <!-- <Button leftIcon="bx bxs-plus-square">Process</Button> -->
    <!-- <Button disabled={true}>Filter (on dev)</Button> -->
  </div>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-50">
      <tr>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Product
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Status
        </th>
        <th
          scope="col"
          class="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        />
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200">
      {#if storeProducts}
        {#if storeProducts.length == 0}
          <tr>
            <td
              colspan="3"
              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
              >No Product Available</td
            >
          </tr>
        {/if}
        {#each storeProducts as product}
          <tr>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
              {product.name}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
              {product.status == "ACTIVE" ? "Activate" : "Deactivate"}
            </td>
            <td class="whitespace-nowrap text-sm text-gray-800">
              <Button
                leftIcon="bx bx-trash"
                on:click={() => {
                  idProduct = product._id;
                  modalConfirmDelete.toggle();
                }}
              />
            </td>
          </tr>
        {/each}
      {/if}
    </tbody>
  </table>
  {#if showStoreProductPaginate}
    <div class="flex space-x-2 mt-4">
      <Button
          disabled={pageList == 1}
          on:click={async () => {
              if (pageList > 1) {
                  pageList -= 1;
                  await loadStoreProducts();
              }
          }}>Prev</Button
      >
      <Button
          disabled={isStoreProductEndPaginate}
          on:click={async () => {
              if (!isStoreProductEndPaginate) {
                  pageList += 1;
                  await loadStoreProducts();
              }
          }}>Next</Button
      >
    </div>
  {/if}
</div>

<div class="absolute mr-3 mt-2 top-0 right-0 flex space-x-2">
  <Button leftIcon="bx bx-edit" on:click={push(`/stores/${id}/edit`)}
    >Edit</Button
  >
  <Button
    leftIcon="bx bx-trash"
    on:click={() => modalConfirmDeleteStore.toggle()}>Delete</Button
  >
  <Button
    leftIcon="bx bx-notification"
    on:click={() => {
      $connection
        .call("stores.activate", id)
        .then((res) => {
          window.pushToast("Store now Activated.", "success");
        })
        .catch((err) => {
          console.log("err", err);
          window.pushToast("Something Wrong!", "danger");
        });
    }}>Activate</Button
  >
  <Button
    leftIcon="bx bx-notification-off"
    on:click={() => {
      () => {
        $connection
          .call("stores.deactivate", id)
          .then((res) => {
            window.pushToast("Store now Deactivated.", "success");
          })
          .catch((err) => {
            console.log("err", err);
            window.pushToast("Something Wrong!", "danger");
          });
      };
    }}>Deactivate</Button
  >
</div>

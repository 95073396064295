<script>
  import Util from "../../common/Util/Utility";
  import { onMount } from "svelte";
  import ImageKit from "imagekit-javascript";
  import Quill from "quill";
  import { validateErrorField, validateInput } from "../helper/FormHelper";
  export let label = "";
  export let placeholder = "";
  export let content = { text: "", html: "" };
  export let value = "";
  export let name = "";
  export let maxlength = -1;
  export let inputEditor = false;
  export let errors = [];
  let uploading = false;
  let imageInput;
  let quill;
  let editor;

  onMount(() => {
    if (inputEditor) {
      quill = new Quill(editor, {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              [
                { header: [1, 2, false] },
                { list: "ordered" },
                { list: "bullet" },
                "blockquote",
              ],
              ["image"],
            ],
            handlers: {
              image: handleImageSelection,
            },
          },
        },
        placeholder: placeholder,
        theme: "snow",
      });
      quill.on("text-change", function () {
        content = { text: quill.getText(), html: quill.root.innerHTML };
        value = content.html;
      });
    }
  });

  $: countChar = inputEditor ? content.text.length - 1 : 0;

  $: if (errors.length > 0) {
    errors = errors
      .filter((err) => err.split(".")[0] == name)
      .map((e) => ({
        field: e.split(".")[0],
        error: e.split(".")[1],
      }));
    errors = errors;
  }

  function handleInput(e) {
    value = e.target.value;
  }

  function handleImageSelection() {
    if (imageInput) {
      imageInput.click();
    }
  }

  function processUpload(file) {
    const imagekit = new ImageKit({
      publicKey: "public_Yq/EBehF/1Xwmc+XjZGkEqsSh6w=",
      urlEndpoint: "https://ik.imagekit.io/78p1kjbqlaj/",
      authenticationEndpoint: "/imgkit",
    });
    return new Promise((resolve, reject) => {
      imagekit.upload(
        {
          file,
          fileName: `POST_IMAGE_${Util.generateId(5)}`,
          tags: ["post", "image", "inline"],
        },
        function (error, result) {
          if (error) {
            reject({ error });
          } else {
            resolve({ result: result.url });
          }
        }
      );
    });
  }

  async function handleImageUpload(files) {
    if (quill) {
      uploading = true;
      const range = quill.getSelection(true);
      const resp = await processUpload(files[0]);
      if (resp.result) {
        quill.insertEmbed(range.index, "image", resp.result);
      }
      uploading = false;
    }
  }
</script>

<svelte:head>
  <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
</svelte:head>

<label
  class="block tracking-wide text-primary text-sm font-bold mb-2 dark:text-white"
  for={value}
>
  {label}
</label>
{#if inputEditor}
  <div class="h-60 relative">
    <div class="dark:text-white dark:bg-dark-third" bind:this={editor}>
      <div>
        {@html content.html}
      </div>
    </div>
    {#if uploading}
      <div
        class="absolute w-full h-full bg-white bg-opacity-70"
        style="top:0; left:0"
      />
    {/if}
  </div>
  <input
    class="hidden"
    type="file"
    accept=".jpg, .jpeg, .png"
    on:change={(e) => handleImageUpload(e.target.files)}
    bind:this={imageInput}
  />
{:else}
  <textarea
    value={value || ""}
    class="appearance-none block w-full text-sm text-dark-second border dark:text-white dark:bg-dark-third rounded py-2 px-3 focus:ring-transparent
        {errors.length > 0
      ? 'border-red-500'
      : 'border-gray-800'} focus:border-gray-800"
    rows="3"
    {maxlength}
    {placeholder}
    on:input={handleInput}
  />
{/if}
{#if inputEditor}
  <p
    class=" {countChar > maxlength
      ? 'text-red-500'
      : 'text-gray-700'} text-xs italic text-right mt-12"
  >
    {countChar < 0 ? 0 : countChar}/{maxlength}
  </p>
{/if}
{#if errors.length > 0 && errors[0]["error"] == "required"}
  <p class="text-red-500 text-xs italic">Please fill out this field.</p>
{/if}
{#if errors.length > 0 && errors[0]["error"] == "max"}
  <p class="text-red-500 text-xs italic text-right">Max {maxlength} char.</p>
{/if}

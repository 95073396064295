<script>
	import { push } from "svelte-spa-router";
	import { format } from "date-fns";
	import Util from "../../../common/Util/Utility";
	import { connection } from "../../../store";
	import Button from "../../../common/control/Button.svelte";
	import Modal from "../../../common/Modal.svelte";
	import LoadingText from "../../../common/LoadingText.svelte";
	import { onMount } from "svelte";
	export let filter = {};
	export let data = [];
	export let id;

	let selectedIndex = 0;
	let selectedStocks = [];
	let stocks = [];
	let modal;
	let isLoading = false;
	let page = 1,
		perPage = 10;
	let showPaginate = false,
		isEndPaginate = false;
	let stockOpnameList = [];

	onMount(() => {
		loadStoreStockOpname();
	});

	function loadStoreStockOpname() {
		// reset value
		showPaginate = false;
		isEndPaginate = false;
		stockOpnameList = [];
		isLoading = true;

		$connection
			.call("logs.stock.stockopname.onLocation.pagination.forList", id, page, perPage)
			.then((result) => {
				stockOpnameList = result.logs;
				if (result.length > perPage) {
					showPaginate = true;
					isEndPaginate = Math.floor(result.totalPage) == page;
				}
				isLoading = false;
			})
			.catch((error) => {
				console.log(error);
				window.pushToast(`${error.reason}`, "danger");
			});
	}

	function handleShowStockList(stopnm) {
		$connection
			.call("stockUpdates.byStockOpnameId.forStockList", stopnm._id)
			.then((result) => {
				stocks = result;
				modal.toggle();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	let modalConfirmDeleteStore;

	function handleDeleteStore() {
		$connection
			.call("stores.remove", id)
			.then((res) => {
				window.pushToast("Successfully Deleted Store.", "success");
				setTimeout(() => {
					push("/stores");
				}, 2000);
			})
			.catch((err) => {
				console.log("err", err);
				window.pushToast("Something Wrong! Cant deleted Store.", "danger");
			});
		modalConfirmDeleteStore.toggle();
	}
</script>

<Modal
	size="small"
	title="Delete Store"
	confirm
	bind:this={modalConfirmDeleteStore}
	on:submit={handleDeleteStore}
	on:cancel={() => {
		modalConfirmDeleteStore.toggle();
	}}
/>

{#if isLoading}
	<LoadingText />
{:else}
	<table class="min-w-full divide-y divide-gray-200 mt-4">
		<thead class="bg-gray-100">
			<tr>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> No </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Date </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Note </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Action </th>
			</tr>
		</thead>
		<tbody class="bg-white divide-y divide-gray-200">
			{#if !stockOpnameList || !stockOpnameList[0]}
				<tr>
					<td colspan="4" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">No Stock Opname Available</td>
				</tr>
			{:else}
				{#each stockOpnameList as stopnm, index}
					<tr>
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
							{index + 1 + (page - 1) * perPage}
						</td>
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
							{format(stopnm.process.date, "eee, dd MMM yyyy (HH:mm)")}
						</td>
						<td class="px-6 py-4 text-left text-sm lowercase text-gray-800">
							{stopnm.process.notes ? stopnm.process.notes : "-"}
						</td>
						<td class="px-6 py-4 whitespace-nowrap text-left text-sm">
							<Button on:click={() => handleShowStockList(stopnm)} rightIcon="bx bx-list">Stock List</Button>
						</td>
					</tr>
					<!-- {#if index == selectedIndex}
					<tr>
						<td colspan="4" class="p-4">
							<table class="min-w-full divide-y divide-gray-200 border border-gray-300">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Name
										</th>
										<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Packagings
										</th>
										<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Before Opname
										</th>
										<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											After Opname
										</th>
									</tr>
								</thead>
								  <tbody class="bg-white divide-y divide-gray-200">
									{#each stopnm.process.stock as stc}
										<tr>
											<td rowspan={stc?.after.stock.length + 1} class="px-6 py-4 text-sm text-gray-800 align-top">
												{stc.after.item.name}
											</td>
											<td class="px-6 py-4 text-sm text-gray-800 align-top">
												All Packagings
											</td>
											<td class="px-6 py-4 text-sm text-gray-800 align-top">
												<b>{Util.formatRupiah(stc.before.total)} {stc.before.item.measureType}</b>
											</td>
											<td class="px-6 py-4 text-sm text-gray-800">
												<b>{Util.formatRupiah(stc.after.total)} {stc.after.item.measureType}</b>
											</td>
										</tr>
										{#each stc.after.stock as stk, stkIndex}
										<tr>
											<td class="px-6 py-4 text-sm text-gray-800">
												{stk.name} <span class="lowercase"><em>(per-{Util.formatRupiah(stk.measure)} {stk.measureType})</em></span>
											</td>
											<td class="px-6 py-4 text-sm text-gray-800">
												{Util.formatRupiah(stc.before.stock[stkIndex].qty)} 
											</td>
											<td class="px-6 py-4 text-sm text-gray-800">
												{Util.formatRupiah(stk.qty)}
											</td>
										</tr>
										{/each}
									{/each}
								</tbody>
							</table>
						</td>
					</tr>
					{/if} -->
				{/each}
			{/if}
		</tbody>
	</table>
	{#if stockOpnameList.length > 0}
		<div class="flex justify-between w-full mt-4">
			<div class="flex space-x-2">
				{#if showPaginate}
					<Button
						disabled={page == 1}
						on:click={async () => {
							if (page > 1) {
								page -= 1;
								await loadStoreStockOpname();
							}
						}}>Prev</Button
					>
					<Button
						disabled={isEndPaginate}
						on:click={async () => {
							if (!isEndPaginate) {
								page += 1;
								await loadStoreStockOpname();
							}
						}}>Next</Button
					>
				{/if}
			</div>
		</div>
	{/if}
	<div class="absolute mr-3 mt-2 top-0 right-0 flex space-x-2">
		<Button leftIcon="bx bx-edit" on:click={push(`/stores/${id}/edit`)}>Edit</Button>
		<Button leftIcon="bx bx-trash" on:click={() => modalConfirmDeleteStore.toggle()}>Delete</Button>
		<Button
			leftIcon="bx bx-notification"
			on:click={() =>
				$connection
					.call("stores.activate", id)
					.then((res) => {
						window.pushToast("Store now Activated.", "success");
					})
					.catch((err) => {
						console.log("err", err);
						window.pushToast("Something Wrong!", "danger");
					})}>Activate</Button
		>
		<Button
			leftIcon="bx bx-notification-off"
			on:click={() =>
				$connection
					.call("stores.deactivate", id)
					.then((res) => {
						window.pushToast("Store now Deactivated.", "success");
					})
					.catch((err) => {
						console.log("err", err);
						window.pushToast("Something Wrong!", "danger");
					})}>Deactivate</Button
		>
	</div>
{/if}
<!-- <DateRangePicker bind:from={filter.from} bind:to={filter.to} /> -->

<Modal bind:this={modal} size="large" title="Stock List">
	<table class="min-w-full border border-gray-300">
		<thead class="bg-gray-50">
			<tr>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Name </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Packagings </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Before Opname </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> After Opname </th>
			</tr>
		</thead>
		<tbody class="bg-white">
			{#each stocks as stc}
				<tr>
					<td rowspan={stc?.after.stock.length + 1} class="px-6 py-4 text-sm text-gray-800 align-top">
						{stc.after.item.name}
					</td>
					<td class="px-6 py-4 text-sm text-gray-800 align-top"> All Packagings </td>
					<td class="px-6 py-4 text-sm text-gray-800 align-top">
						<b>{Util.formatRupiah(stc.before.total)} {stc.before.item.measureType}</b>
					</td>
					<td class="px-6 py-4 text-sm text-gray-800">
						<b>{Util.formatRupiah(stc.after.total)} {stc.after.item.measureType}</b>
					</td>
				</tr>
				{#each stc.after.stock as stk, stkIndex}
					<tr>
						<td class="px-6 py-4 text-sm text-gray-800">
							{stk.name}
							<span class="lowercase"><em>(per-{Util.formatRupiah(stk.measure)} {stk.measureType})</em></span>
						</td>
						<td class="px-6 py-4 text-sm text-gray-800">
							{Util.formatRupiah(stc.before.stock[stkIndex].qty)}
						</td>
						<td class="px-6 py-4 text-sm text-gray-800">
							{Util.formatRupiah(stk.qty)}
						</td>
					</tr>
				{/each}
			{/each}
		</tbody>
	</table>
</Modal>

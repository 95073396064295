<script>
  import { createEventDispatcher, onMount } from "svelte";
  import Button from "./Button.svelte";
  const dispatch = createEventDispatcher();

  export let name;
  export let selected;
  export let label = "";
  export let textHint = "Choose";
  export let options = [];
  export let disabled = false;
  export let fullWidth = false;

  export let errors = [];

  export let addButton = false;

  $: if (errors.length > 0) {
    errors = errors
      .filter((err) => err.split(".")[0] == name)
      .map((e) => ({
        field: e.split(".")[0],
        error: e.split(".")[1],
      }));
    errors = errors;
  }
</script>

{#if label}
  <label class="block text-primary text-sm font-bold mb-2" for={selected}>
    {label}
  </label>
{/if}
<div class="relative flex space-x-2 items-center">
  <!-- svelte-ignore a11y-no-onchange -->
  <select
    bind:value={selected}
    on:change={(e) => {
      selected = e.target.value;
      dispatch("change", selected);
    }}
    class="flex-1 appearance-none cursor-pointer block text-sm text-dark-second border dark:text-white dark:bg-dark-third rounded p-2 pr-7 focus:ring-transparent
        {errors.length > 0
      ? 'border-red-500'
      : 'border-gray-800 focus:border-gray-800'}{fullWidth ? ' w-full ' : ''}"
    {disabled}
  >
    {#if selected === null || selected === "" || selected === undefined}
      <option class="text-gray-500" selected disabled value={null}
        >{textHint}</option
      >
    {/if}
    {#each options as option}
      {#if selected === option.id}
        <option value={option.id} selected>{option.name}</option>
      {:else}
        <option value={option.id}>{option.name}</option>
      {/if}
    {/each}
  </select>
  {#if addButton}
    <Button
      leftIcon="bx bx-plus"
      type="button"
      on:click={() => {
        dispatch("actionAdd");
      }}>Add</Button
    >
  {:else}
    <i class="absolute right-3 bx bx-chevron-down" />
  {/if}
</div>
{#if errors.length > 0 && errors[0]["error"] == "required"}
  <p class="text-red-500 text-xs italic">Please fill out this field.</p>
{/if}
{#if errors.length > 0 && errors[0]["error"] == "empty"}
  <p class="text-red-500 text-xs italic">Please add some.</p>
{/if}

<script>
	import { push } from "svelte-spa-router";
	import Button from "../../../common/control/Button.svelte";
	import Modal from "../../../common/Modal.svelte";
	import Header from "../../../common/Header.svelte";
	import Loading from "../../../common/Loading.svelte";
	import { connection } from "../../../store.js";
	import { createEventDispatcher } from "svelte";
	import { printType } from "../../../enum/printType";

	export let id;
	export let data;
	const dispatch = createEventDispatcher();
	let isLoading;
	let modalConfirmDelete;

	function handleDelete() {
		modalConfirmDelete.toggle();
		$connection
			.call("productCategories.remove", id)
			.then(() => {
				setTimeout(() => {
					push("/category");
				}, 1000);
				window.pushToast("Successfully Deleted Category.", "success");
			})
			.catch((error) => {
				console.log(error);
				window.pushToast("Something Wrong! Cant deleted category.", "danger");
			});
	}

	async function checkCategory() {
		isLoading.toggle();
		await $connection
			.call("productCategories.check", id)
			.then((result) => {
				if (result > 0) {
					window.pushToast("This category used in product. You must remove product with this category", "danger");
					isLoading.toggle();
				} else {
					isLoading.toggle();
					modalConfirmDelete.toggle();
				}
			})
			.catch((error) => {
				console.log(error);
				isLoading.toggle();
			});
	}
	async function activateCategory() {
		await $connection
			.call("productCategories.activate", id)
			.then(() => {
				window.pushToast("Category now Activated", "success");
				dispatch("reload-data");
			})
			.catch((error) => {
				alert(error);
				console.log(error);
			});
	}
	async function deactivateCategory() {
		await $connection
			.call("productCategories.deactivate", id)
			.then(() => {
				window.pushToast("Category now Deactivated", "success");
				dispatch("reload-data");
			})
			.catch((error) => {
				alert(error);
				console.log(error);
			});
	}
</script>

<Modal
	size="small"
	title="Delete Category"
	confirm
	bind:this={modalConfirmDelete}
	on:submit={handleDelete}
	on:cancel={() => {
		modalConfirmDelete.toggle();
	}}
/>

<Loading bind:this={isLoading} />

<Header title="Product Category Information" />
<div class="flex flex-col p-4">
	<div class="flex items-center">
		<div class="flex items-center w-1/6 text-sm text-gray-500">Name</div>
		<div class="flex items-center flex-1">: {data.name}</div>
	</div>
	<div class="flex items-center">
		<div class="flex items-center w-1/6 text-sm text-gray-500">Description</div>
		<div class="flex items-center flex-1">: {data.desc ?? "-"}</div>
	</div>
	<div class="flex items-center">
		<div class="flex items-center w-1/6 text-sm text-gray-500">Status</div>
		<div class="flex items-center flex-1">: {data.status == "ACTIVE" ? "Active" : "InActive"}</div>
	</div>
	<div class="flex items-center">
		<div class="flex items-center w-1/6 text-sm text-gray-500">Is it Topping?</div>
		<div class="flex items-center flex-1">: {data.isTopping ? "Yes" : "No"}</div>
	</div>
	{#if !data.isTopping}
		<div class="flex items-center">
			<div class="flex items-center w-1/6 text-sm text-gray-500">Print Labels</div>
			{#if data.printable}
				{#if data.printable.length > 0}
					<div class="flex items-center flex-1 overflow-x-auto">
						: {#each data.printable as print}
							{@const foundPrint = Object.values(printType).find((pr) => pr.value == print).text}
							<p class="px-4 rounded-full border border-gray-800 mr-1 ml-1 uppercase text-sm">
								{foundPrint}
							</p>
						{/each}
					</div>
				{:else}
					:
					<p class="px-4 rounded-full border border-gray-800 mr-1 ml-1 uppercase text-sm">None</p>
				{/if}
			{:else}
				<div class="flex items-center flex-1 overflow-x-auto">
					:
					{#each Object.values(printType).map((pr) => pr.text) as print}
						<p class="px-4 rounded-full border border-gray-800 mr-1 ml-1 uppercase text-sm">{print}</p>
					{/each}
				</div>
			{/if}
		</div>
	{/if}
</div>
<div class="absolute top-0 right-0 mr-3 mt-2 flex space-x-2">
	<Button leftIcon="bx bx-edit" on:click={push(`/category/${id}/edit`)}>Edit</Button>
	<Button leftIcon="bx bx-trash" on:click={() => checkCategory()}>Delete</Button>
	<Button leftIcon="bx bx-notification" on:click={() => activateCategory()}>Activate</Button>
	<Button leftIcon="bx bx-notification-off" on:click={() => deactivateCategory()}>Deactivate</Button>
</div>

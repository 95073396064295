<script>
  import Layout from "./templates/LayoutTemplate.svelte";
  import Template from "./templates/EmployeesTemplate.svelte";
  import { connection } from "../../store";
  import LoadingText from "../../common/LoadingText.svelte";
  export let params = {};

  const { _id } = params;
  let dataId = _id;
  let storeName = localStorage.getItem('storeName');


  // $: locationCollectionEmp = $connection
  //   .collection("locations")
  //   .filter((loc) => loc.id == dataId)
  //   .reactive()
  //   .one();

  // let sub = $connection.subscribe("stores.byId.forEmployeeList", dataId);
  // $: data = locationCollectionEmp.data();

  $: if (params) {
    dataId = params._id;
    // sub = $connection.subscribe("stores.byId.forEmployeeList", dataId);

    // sub.ready();
    // locationCollectionEmp = $connection
    //   .collection("locations")
    //   .filter((loc) => loc.id == dataId)
    //   .reactive()
    //   .one();
    // isLoading = !sub.ready();

    // data = locationCollectionEmp.data();

    // locationCollectionEmp.onChange((newData) => {
    //   if (newData.id == dataId) {
    //     data = newData;
    //   }
    // });
  }
</script>

<Layout id={dataId} page="employees">
  <Template id={dataId} {storeName} />
</Layout>

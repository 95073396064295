<script>
	import Modal from "../Modal.svelte";
	import { debounce } from "lodash";
	import LoadingText from "../LoadingText.svelte";
	import Button from "../control/Button.svelte";
	import { connection } from "../../store";

	export let selectedIngredients;
	let modalIngredient;

	let dataIngredients = [],
		tempSelectedIngredients = [];
	let loadingIngredient = false,
		showPaginateIngredient = false,
		isEndPaginateIngredient = false;
	let searchIngredients = "";
	let page = 1,
		perPage = 25;

	async function loadIngredients() {
		// reset value
		showPaginateIngredient = false;
		isEndPaginateIngredient = false;
		dataIngredients = [];
		loadingIngredient = true;

		const selectedIds = selectedIngredients.length > 0 ? selectedIngredients.map((prod) => prod._id) : [];
		await $connection
			.call("products.ingredient.pagination.search", page, perPage, searchIngredients.trim().toLowerCase(), selectedIds)
			.then((result) => {
				resolveSelected(result.data);

				if (result.length > perPage) {
					showPaginateIngredient = true;
					isEndPaginateIngredient = Math.floor(result.totalPage) == page;
				}

				loadingIngredient = false;
			})
			.catch((err) => {
				window.pushToast(err.error, "danger");
				loadingIngredient = false;
			});
	}

	const resolveSelected = (_data = []) => {
		dataIngredients = _data.map((e) => ({
			...e,
			isSelected: tempSelectedIngredients.some((temp) => temp._id == e._id),
		}));
	};

	const resolveTempSelected = (item) => {
		return tempSelectedIngredients.length > 0 && tempSelectedIngredients.filter((it) => it._id === item._id).length == 1;
	};
	const handleSearchIngredients = async () => {
		page = 1;
		await loadIngredients();
	};
	const handleAddIngredients = (item) => {
		const data = {
			_id: item._id,
			name: item.name,
			measureType: item.measureType,
			measure: 0,
			isSelected: !item.isSelected,
		};

		if (data.isSelected) {
			tempSelectedIngredients.push(data);
		} else {
			tempSelectedIngredients = tempSelectedIngredients.filter((it) => it._id != item._id);
		}
		tempSelectedIngredients = tempSelectedIngredients;

		resolveSelected(dataIngredients);
	};
	export function open() {
		modalIngredient.toggle();
		searchIngredients = "";
		page = 1;
		loadIngredients();
	}

	export function close() {
		modalIngredient.close();
	}
	const handleSelectIngredients = () => {
		selectedIngredients = [...selectedIngredients, ...tempSelectedIngredients];
		tempSelectedIngredients = [];
		window.pushToast("Successfully Add Ingredients.", "success");
		modalIngredient.toggle();
	};
</script>

<Modal
	bind:this={modalIngredient}
	title="Add Ingredient"
	closed
	on:close={() => {
		tempSelectedIngredients = [];
		modalIngredient.toggle();
	}}
>
	<input
		bind:value={searchIngredients}
		on:input={debounce(function (e) {
			handleSearchIngredients();
		}, 1000)}
		type="search"
		placeholder="Search Ingredients.."
		class="appearance-none block pl-5 pr-5 w-full text-dark-second border dark:text-white dark:bg-dark-third rounded-full py-2 px-3 focus:ring-transparent focus:border-gray-800 outline-none"
	/>
	{#if loadingIngredient}
		<div class="p-4">
			<LoadingText />
		</div>
	{:else}
		{#if dataIngredients.length == 0}
			<p class="m-0 text-gray-400 p-4">No Ingredients Available.</p>
		{/if}
		<div class="grid grid-cols-4 gap-4 my-4 overflow-y-auto" style="max-height: calc(70vh - 30px);">
			{#each dataIngredients as item}
				<!-- svelte-ignore a11y-click-events-have-key-events -->
				<div
					on:click={() => handleAddIngredients(item)}
					class="relative p-2 flex flex-col items-center justify-center border {resolveTempSelected(item)
						? 'bg-gray-200'
						: ''} rounded-md cursor-pointer hover:opacity-90"
				>
					{#if resolveTempSelected(item)}
						<div class="absolute top-2 right-2 bg-green-500 text-white rounded-full h-6 w-6 flex justify-center items-center">
							<i class="bx bx-check" />
						</div>
					{/if}
					<img
						class="w-20 h-20 rounded-md object-cover"
						src={item.image.url != ""
							? item.image.url
							: "https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png"}
						alt=".."
					/>
					<h4 class="text-gray-800 text-md font-medium">{item.name}</h4>
					<p class="text-gray-800 text-sm font-medium">SKU: {item.sku}</p>
				</div>
			{/each}
		</div>
		{#if dataIngredients.length > 0}
			<div class="flex justify-between w-full">
				<div class="flex space-x-2">
					{#if showPaginateIngredient}
						<Button
							disabled={page == 1}
							on:click={async () => {
								if (page > 1) {
									page -= 1;
									await loadIngredients();
								}
							}}>Prev</Button
						>
						<Button
							disabled={isEndPaginateIngredient}
							on:click={async () => {
								if (!isEndPaginateIngredient) {
									page += 1;
									await loadIngredients();
								}
							}}>Next</Button
						>
					{/if}
				</div>
				<Button status="success" on:click={() => handleSelectIngredients()}>Select Ingredients</Button>
			</div>
		{/if}
	{/if}
</Modal>

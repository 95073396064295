<script>
	import { debounce } from "lodash";
	import { createEventDispatcher, onMount } from "svelte";
	import { push } from "svelte-spa-router";
	import LoadingText from "../../../common/LoadingText.svelte";
	import Modal from "../../../common/Modal.svelte";
	import SearchBar from "../../../common/SearchBar.svelte";
	import Tile from "../../../common/Tile.svelte";
	import Util from "../../../common/Util/Utility";
	import Button from "../../../common/control/Button.svelte";
	import Input from "../../../common/control/Input.svelte";
	import { connection } from "../../../store";

	export let id;
	const dispatch = createEventDispatcher();
	let modalRef;
	let modal = {
		header: "",
	};
	let showPaginate = false,
		isEndPaginate = false;
	let page = 1,
		perPage = 10;
	let suppliesList = [];
	let totalSupplies = 0;
	let loading = false;
    let searchSupply = '';

	onMount(() => {
		loadData();
	});

	function loadData() {
		// reset value
		showPaginate = false;
		isEndPaginate = false;
		suppliesList = [];
		loading = true;

		$connection
			.call("supplies.byLocationId.pagination", id, page, perPage, searchSupply.toString())
			.then((result) => {
				suppliesList = result.supplies;
				totalSupplies = result.totalSupplies;
				if (result.length > perPage) {
					showPaginate = true;
					isEndPaginate = Math.floor(result.totalPage) == page;
				}
				loading = false;
			})
			.catch((error) => {
				loading = false;
				console.log(error);
				window.pushToast(`${err.reason}`, "danger");
			});
	}

	function handleRestockLimit(supply) {
		modal = {
			header: "Set Restock Limit",
			body: {
				restockLimit: supply.restockLimit,
				measureType: supply.item?.measureType,
			},
			onSubmit: (newLimit) => {
				console.log(supply);
				console.log(newLimit);
				dispatch("restock", { id: supply._id, restockLimit: Number(newLimit) });
				modalRef.toggle();
			},
		};
		modalRef.toggle();
	}
	let modalConfirmDeleteStore;

	function handleDeleteStore() {
		$connection
			.call("stores.remove", id)
			.then((res) => {
				window.pushToast("Successfully Deleted Store.", "success");
				setTimeout(() => {
					push("/stores");
				}, 2000);
			})
			.catch((err) => {
				console.log("err", err);
				window.pushToast("Something Wrong! Cant deleted Store.", "danger");
			});
		modalConfirmDeleteStore.toggle();
	}

	function handleSearch() {
        page = 1;
        loadData();
    }
</script>

<Modal
	size="small"
	title="Delete Store"
	confirm
	bind:this={modalConfirmDeleteStore}
	on:submit={handleDeleteStore}
	on:cancel={() => {
		modalConfirmDeleteStore.toggle();
	}}
/>

<Modal bind:this={modalRef} title={modal.header} size="small">
	<Input label="Limit" placeholder="ex: 200" bind:value={modal.body.restockLimit} type="number">
		<b class="lowercase">{modal.body.measureType}</b>
	</Input>
	<div class="flex justify-end w-full space-x-2 mt-5">
		<Button
			status="danger"
			on:click={() => {
				modalRef.toggle();
			}}
		>
			No
		</Button>
		<Button
			status="success"
			on:click={() => {
				if (modal.body.restockLimit > 0) {
					modal.onSubmit(modal.body.restockLimit);
				} else {
					window.pushToast("Sorry! min 1 qty", "error");
				}
			}}
		>
			Yes
		</Button>
	</div>
</Modal>
<Tile title="Total Ingredients" info={totalSupplies} />
<div class="mt-5">
	<div class="flex mb-4">
        <SearchBar
            bind:value={searchSupply}
            on:input={debounce(function (e) {
                handleSearch();
            }, 1000)}
        />
    </div>
	<table class="min-w-full divide-y divide-gray-200">
		<thead class="bg-gray-50">
			<tr>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Name </th>
				<th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"> Quantity </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Measure Type </th>
				<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Restock Limit </th>
			</tr>
		</thead>
		<tbody class="bg-white divide-y divide-gray-200">
			{#if loading}
				<LoadingText />
			{:else if !suppliesList || !suppliesList[0]}
				<tr>
					<td colspan="4" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">No Supplies Available</td>
				</tr>
			{:else}
				{#each suppliesList as supply}
					<tr>
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 align-top">
							{supply.item?.name}
						</td>
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 space-y-2 text-right">
							{supply.total}
						</td>
						<td class="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-600 lowercase align-top">
							{supply.item?.measureType}
						</td>
						<td class="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-600 align-top">
							<Button on:click={() => handleRestockLimit(supply)} rightIcon="bx bx-edit">
								{supply.restockLimit ? Util.formatRupiah(supply.restockLimit) : 0}
								{supply.item?.measureType.toLowerCase()}
							</Button>
						</td>
					</tr>
				{/each}
			{/if}
		</tbody>
	</table>
	{#if showPaginate}
		<div class="flex space-x-2 mt-4">
			<Button
				disabled={page == 1}
				on:click={async () => {
					if (page > 1) {
						page -= 1;
						await loadData();
					}
				}}>Prev</Button
			>
			<Button
				disabled={isEndPaginate}
				on:click={async () => {
					if (!isEndPaginate) {
						page += 1;
						await loadData();
					}
				}}>Next</Button
			>
		</div>
	{/if}
</div>

<div class="absolute mr-3 mt-2 top-0 right-0 flex space-x-2">
	<Button leftIcon="bx bx-edit" on:click={push(`/stores/${id}/edit`)}>Edit</Button>
	<Button leftIcon="bx bx-trash" on:click={() => modalConfirmDeleteStore.toggle()}>Delete</Button>
	<Button
		leftIcon="bx bx-notification"
		on:click={() =>
			$connection
				.call("stores.activate", id)
				.then((res) => {
					window.pushToast("Store now Activated.", "success");
				})
				.catch((err) => {
					console.log("err", err);
					window.pushToast("Something Wrong!", "danger");
				})}>Activate</Button
	>
	<Button
		leftIcon="bx bx-notification-off"
		on:click={() =>
			$connection
				.call("stores.deactivate", id)
				.then((res) => {
					window.pushToast("Store now Deactivated.", "success");
				})
				.catch((err) => {
					console.log("err", err);
					window.pushToast("Something Wrong!", "danger");
				})}>Deactivate</Button
	>
</div>

<script>
	import { fade } from "svelte/transition";
	import { push } from "svelte-spa-router";
	import Router from "svelte-spa-router";
	import Layout from "../../common/layout/Default.svelte";
	import DataList from "../../common/DataDisplay/List.svelte";
	import LoadingText from "../../common/LoadingText.svelte";
	import SearchBar from "../../common/SearchBar.svelte";
	import { connection } from "../../store";
	import { onMount } from "svelte";
	import { debounce } from "lodash";

	import List from "./List.svelte";
	import Add from "./Add.svelte";
	import Edit from "./Edit.svelte";
	import Detail from "./Detail.svelte";
	import Stores from "./Stores.svelte";

	export let params = {};

	const prefix = "/menu";
	const routes = {
		"/": List,
		"/add": Add,
		"/:_id/edit": Edit,
		"/:_id/detail": Detail,
		"/:_id/stores": Stores,
	};

	let isLoading = true;
	let search = "";

	let data;
	$: dataId = params.wild?.split("/")[0];

	onMount(() => {
		loadData();
	});

	async function loadData() {
		await $connection
			.call("menus.all.search", search.trim().toLowerCase())
			.then((result) => {
				if (result) {
					data = result;
					isLoading = false;
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	function mapByStatus(posts, currentActive) {
		const active = [];
		const inActive = [];
		posts.forEach((post) => {
			let pointer;
			if (post.status == "ACTIVE") {
				pointer = active;
			} else {
				pointer = inActive;
			}
			pointer.push({
				id: post._id,
				title: post.name,
				active: post._id === currentActive,
			});
		});
		return [
			{
				category: `Active (${active.length})`,
				items: active,
			},
			{
				category: `InActive (${inActive.length})`,
				items: inActive,
			},
		];
	}

	function handleSearch() {
		loadData();
	}

	function handleAdd(e) {
		dataId = null;
		push(`${prefix}/add`);
	}

	function handleItemClick(e) {
		dataId = e.detail.id;
		push(`${prefix}/${dataId}/detail`);
	}
	function routeEvent() {
		loadData();
	}
</script>

<Layout title="Menu">
	<div class="flex flex-col md:flex-row md:h-full">
		<div class="flex flex-col md:h-full w-full md:w-1/4 py-3 pl-3 pr-1.5">
			<SearchBar
				bind:value={search}
				on:input={debounce(function () {
					handleSearch();
				}, 1000)}
				actionOne
				on:click-one={handleAdd}
			/>
			{#if isLoading}
				<LoadingText />
			{:else}
				<DataList category={true} items={mapByStatus(data, dataId)} on:item-click={(e) => handleItemClick(e)} />
			{/if}
		</div>
		<div class="w-full md:h-full flex-1 overflow-auto pr-3 py-3 pl-1.5" in:fade={{ duration: 500 }}>
			<div class="relative bg-white rounded-lg shadow-md min-w-full min-h-full">
				<Router {routes} {prefix} on:routeEvent={routeEvent} />
			</div>
		</div>
	</div>
</Layout>

<script>
	import { connection } from "../../store";
	import Template from "./templates/FormTemplate.svelte";
	import { form, field } from "svelte-forms";
	import { required } from "svelte-forms/validators";
	import Loading from "../../common/Loading.svelte";
	import { replace } from "svelte-spa-router";

	let category = field("category", null, [required()]);
	let name = field("name", "", [required()]);
	let desc = field("desc", "", [required()]);
	let price = field("price", 0, [required()]);
	let sku = field("sku", "", [required()]);
	let ingredients = field("ingredients", [], [checkEmptyIngredients()]);

	let images = [];
	let additionals = [];
	let toppings = [];
	let activeInApp = true;
	let priceOnline = 0;
	let errors = [];
	let isLoading;

	let formAdd = form(category, name, desc, price, sku, ingredients);

	async function handleSubmit(e) {
		const { category, name, sku, priceMap, desc, images, additionals, toppings, ingMap, activeInApp, priceOnlineMap } = e.detail;
		$ingredients.value = ingMap;
		let mappedAdditionals = additionals.map((e) => ({
			...e,
			status: e.status || "INACTIVE",
		}));
		await formAdd.validate();
		const valid = $formAdd.valid;
		isLoading.toggle();
		if (valid) {
			$connection
				.call(
					"products.add",
					category,
					name,
					parseFloat(priceMap),
					desc,
					sku,
					images,
					mappedAdditionals,
					toppings,
					ingMap,
					activeInApp,
					priceOnlineMap
				)
				.then((result) => {
					setTimeout(() => {
						replace(`/products/${result}/detail`);
					}, 1000);
					window.pushToast("Successfully Add Product.", "success");
				})
				.catch((error) => {
					console.log(error.reason);
					window.pushToast("Something Wrong! Cant Add product.", "danger");
				});
		} else {
			errors = $formAdd.errors;
			window.pushToast("Something Wrong! Please check again.", "danger");
		}
		isLoading.toggle();
	}
	function checkEmptyIngredients() {
		return (value) => ({ valid: value.length > 0, name: "required" });
	}
</script>

<Loading text="Loading..." bind:this={isLoading} />
<Template
	mode="Add"
	bind:errors
	form={formAdd}
	bind:sku={$sku.value}
	bind:name={$name.value}
	bind:price={$price.value}
	bind:desc={$desc.value}
	bind:images
	bind:category={$category.value}
	bind:activeInApp
	bind:priceOnline
	{additionals}
	{toppings}
	bind:ingredients={$ingredients.value}
	on:submit={handleSubmit}
/>

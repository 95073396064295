<script>
	import { connection } from "../../store";
	import Layout from "./templates/LayoutTemplate.svelte";
	import Template from "./templates/SuppliesTemplate.svelte";
	export let params = {};
	let dataId;

	$: if (params) {
		dataId = params._id;
	}

	function setRestockLimit(evt) {
		const { id, restockLimit } = evt.detail;
		$connection
			.call("supplies.update.restockLimit.byId", id, restockLimit)
			.then(() => {
				window.pushToast("Restock limit updated");
			})
			.catch((error) => {
				console.log(error);
				window.pushToast(`${error.reason}`, "danger");
			});
	}
</script>

<Layout id={dataId} page="supplies">
	<Template id={dataId} on:restock={setRestockLimit} />
</Layout>

<script>
	import { push } from "svelte-spa-router";
	import Button from "../../../common/control/Button.svelte";
	import { formatRupiah } from "../../../common/helper/FormHelper";
	import Modal from "../../../common/Modal.svelte";
	import { noImg } from "../../../common/helper/StringHelper";
	import { connection } from "../../../store";
	import { createEventDispatcher } from "svelte";

	export let id;
	export let data;
	const dispatch = createEventDispatcher();
	let modalConfirmDelete;

	function handleDelete() {
		$connection.call("products.remove", id, (err) => {
			if (err) {
				window.pushToast("Something Wrong! Cant deleted product.", "danger");
			} else {
				setTimeout(() => {
					push("/products");
				}, 3000);
				window.pushToast("Successfully Deleted Product.", "success");
			}
		});
	}
</script>

<Modal
	size="small"
	title="Delete Product"
	confirm
	bind:this={modalConfirmDelete}
	on:submit={handleDelete}
	on:cancel={() => {
		modalConfirmDelete.toggle();
	}}
/>
<div class="mb-4">
	<div class="flex flex-col">
		<img class="w-20 h-20 object-cover rounded mb-3" src={data.images[0].url ? data.images[0].url : noImg} alt="product" />
		<div class="flex items-center">
			<div class="flex items-center w-18x text-sm text-gray-500">Name</div>
			<div class="flex items-center flex-1">: {data.name}</div>
		</div>
		<div class="flex items-center">
			<div class="flex items-center w-18x text-sm text-gray-500">Category</div>
			<div class="flex items-center flex-1">
				: {data.category.name}
				<span class="font-medium italic ml-2">{data.category.isTopping ? "(Topping)" : ""}</span>
			</div>
		</div>
		<div class="flex items-center">
			<div class="flex items-center w-18x text-sm text-gray-500">SKU</div>
			<div class="flex items-center flex-1">: {data.sku}</div>
		</div>
		<div class="flex items-center">
			<div class="flex items-center w-18x text-sm text-gray-500">Price</div>
			<div class="flex items-center flex-1">: {formatRupiah(data.price, "Rp. ")}</div>
		</div>
		<div class="flex items-center">
			<div class="flex items-center w-18x text-sm text-gray-500">Ojol<span class="ml-1">Price</span></div>
			<div class="flex items-center flex-1">: {formatRupiah(data.priceOnline ?? 0, "Rp. ")}</div>
		</div>
		<div class="flex items-center">
			<div class="flex items-center w-18x text-sm text-gray-500">Description</div>
			<div class="flex items-center flex-1">: {data.desc}</div>
		</div>
		<div class="flex items-center">
			<div class="flex items-center w-18x text-sm text-gray-500">Status</div>
			<div class="flex items-center flex-1">: {data.status == "ACTIVE" ? "Active" : "InActive"}</div>
		</div>
		<div class="flex items-center">
			<div class="flex items-center w-18x text-sm text-gray-500">
				Display<span class="ml-1">in</span><span class="ml-1">Mobile</span><span class="ml-1">App</span>
			</div>
			<div class="flex items-center flex-1">
				: {data.activeInApp != null ? (data.activeInApp ? "Enable" : "Disable") : "Enable"}
			</div>
		</div>
		<hr class="my-4" />
		<div class="flex flex-col">
			<div class="flex items-center w-1/6 text-sm text-gray-500 mb-2">Ingredients :</div>
			<div class="flex flex-col flex-1">
				{#if data.ingredients && data.ingredients.length < 1}
					<p class="text-gray-500 text-sm w-full">No Ingredients Selected</p>
				{:else}
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Name
								</th>
								<th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
									Measure
								</th>
								<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Measure Type
								</th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							{#each data.ingredients || [] as item, index}
								<tr>
									<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
										{item.name}
									</td>
									<td class="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800">
										{item.measure}
									</td>
									<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
										{item.measureType.toLowerCase()}
									</td>
								</tr>
							{/each}
							<!-- More people... -->
						</tbody>
					</table>
				{/if}
			</div>
		</div>
		{#if !data.category.isTopping}
			<div class="flex flex-col">
				<div class="flex items-center w-1/6 text-sm text-gray-500 mb-2">Modifier :</div>
				<div class="flex flex-col flex-1">
					{#if data.additionals && data.additionals.length < 1}
						<p class="text-gray-500 text-sm w-full">No Modifier Selected</p>
					{:else if data.additionals && data.additionals.length > 0}
						{#each data.additionals as item, index}
							<div class="flex mb-2 gap-2 items-center">
								<p class="text-md font-medium text-gray-700">{item.name}</p>
								{#if item.isRequired}
									<div class="text-gray-700 text-md italic font-bold">(Modifier Wajib)</div>
								{/if}
							</div>
							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Name
										</th>
										<th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
											Price
										</th>
										<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Default
										</th>
									</tr>
								</thead>
								<tbody class="bg-white divide-y divide-gray-200">
									{#each item.items as item, index}
										{#if item.active}
											<tr>
												<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
													{item.name}
												</td>
												<td class="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-800">
													{formatRupiah(item.price, "Rp. ")}
												</td>
												<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
													{#if item.default}
														<i class="bx bxs-check-circle text-green-500 text-lg" />
													{/if}
												</td>
											</tr>
										{/if}
									{/each}
									<!-- More people... -->
								</tbody>
							</table>
						{/each}
					{/if}
				</div>
			</div>
			<div class="flex items-center w-1/6 text-sm text-gray-500 mb-4">Toppings :</div>
			<div class={data.toppings && data.toppings.length == 0 ? "w-full" : "grid grid-cols-4 gap-4"}>
				{#if data.toppings && data.toppings.length == 0}
					<p class="text-gray-500 text-sm w-full">No Topping Selected</p>
				{:else if data.toppings && data.toppings.length > 0}
					{#each data.toppings as topping}
						<div class="p-2 flex flex-col items-center justify-center border rounded-md">
							<img
								class="w-20 h-20 rounded-md object-cover"
								src={topping.images[0].url
									? topping.images[0].url
									: "https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png"}
								alt=".."
							/>
							<h4 class="text-gray-800 text-md mt-3">{topping.name}</h4>
							<p class="text-gray-800 text-sm font-medium">{formatRupiah(topping.price, "Rp. ")}</p>
						</div>
					{/each}
				{/if}
			</div>
		{/if}
	</div>
</div>
<div class="absolute top-0 right-0 mr-3 mt-2 flex space-x-2">
	<Button leftIcon="bx bx-edit" on:click={push(`/products/${id}/edit`)}>Edit</Button>
	<!-- <Button leftIcon="bx bx-trash" on:click={() => modalConfirmDelete.toggle()}>Delete</Button> -->
	<Button
		leftIcon="bx bx-notification"
		on:click={() =>
			$connection
				.call("products.activate", id)
				.then(() => {
					window.pushToast("Product now Activated", "success");
					dispatch("reload-data");
				})
				.catch((err) => {
					alert(err);
				})}>Activate</Button
	>
	<Button
		leftIcon="bx bx-notification-off"
		on:click={() =>
			$connection
				.call("products.deactivate", id)
				.then(() => {
					window.pushToast("Product now Deactivated", "success");
					dispatch("reload-data");
				})
				.catch((err) => {
					alert(err);
				})}>Deactivate</Button
	>
	{#if !data.activeInApp}
        <Button leftIcon="bx bx-show" on:click={() => dispatch('display-in-mobile', true)} status="success"
            >Enable In Mobile App</Button
        >
    {:else if data.activeInApp}
        <Button leftIcon="bx bx-hide" on:click={() => dispatch('disable-in-mobile', false)} status="danger"
            >Disable In Mobile App</Button
        >
    {/if}
</div>

<script>
	import Template from "./templates/FormTemplate.svelte";
	import { replace } from "svelte-spa-router";
	import Loading from "../../common/Loading.svelte";
	import { form, field } from "svelte-forms";
	import { required } from "svelte-forms/validators";
	import { connection } from "../../store";
	import { createEventDispatcher } from "svelte";

	export let params = {};

	const { _id } = params;
	let isLoading;
	let dataId;
	const dispatch = createEventDispatcher();
	let category = field("category", "", [required()]);
	let name = field("name", "", [required()]);
	let desc = field("desc", "", [required()]);
	let price = field("price", 0, [required()]);
	let sku = field("sku", "", [required()]);
	let ingredients = field("ingredients", [], [checkEmptyIngredients()]);
	let formEdit = form(category, name, desc, price, sku, ingredients);
	let errors;
	let data;
	let priceOnline;

	$: if (params) {
		dataId = params._id;
		loadData(dataId);
	}

	async function loadData(dataId) {
		await $connection
			.call("products.byId.forAdminLite", dataId)
			.then((result) => {
				if (result) {
					data = result;
					$name.value = data?.name;
					$price.value = data?.price;
					priceOnline = data?.priceOnline ?? 0;
					$sku.value = data?.sku;
					$desc.value = data?.desc;
					$category.value = data?.categoryId;
					$ingredients.value = mapIng(data?.ingredients);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	async function handleSubmit(e) {
		isLoading.toggle();
		const { category, name, sku, priceMap, desc, images, additionals, toppings, ingMap, priceOnlineMap } = e.detail;
		$ingredients.value = ingMap;
		let mappedAdditionals = additionals.map((e) => ({
			...e,
			status: e.status || "INACTIVE",
			isRequired: e.isRequired || false,
		}));
		await formEdit.validate();

		const valid = $formEdit.valid;
		if (valid) {
			errors = [];
			$connection
				.call(
					"products.update",
					dataId,
					category,
					name,
					parseFloat(priceMap),
					desc,
					sku,
					images,
					mappedAdditionals,
					toppings,
					ingMap,
					priceOnlineMap
				)
				.then(() => {
					dispatch("routeEvent");
					setTimeout(() => {
						replace(`/products/${dataId}/detail`);
					}, 1000);
					window.pushToast("Successfully Update Product.", "success");
				})
				.catch((error) => {
					window.pushToast(error.reason, "danger");
				});
		} else {
			errors = $formEdit.errors;
			window.pushToast("Something Wrong! Please check again form field.", "danger");
		}
		isLoading.toggle();
	}

	function mapIng(ingredient) {
		return ingredient.map((ing) => ({
			_id: ing._id,
			measure: ing.measure,
			name: ing.name,
			measureType: ing.measureType,
		}));
	}
	function checkEmptyIngredients() {
		return (value) => ({ valid: value.length > 0, name: "required" });
	}
</script>

<Loading bind:this={isLoading} text="Loading..." />
{#if data && data["ingredients"] != undefined}
	<Template
		mode="Edit"
		bind:errors
		bind:sku={$sku.value}
		bind:name={$name.value}
		bind:price={$price.value}
		bind:desc={$desc.value}
		images={data.images ? (data.images[0].url ? data.images.map((it) => it.url) : []) : []}
		bind:category={$category.value}
		bind:priceOnline
		additionals={data.additionals}
		toppings={data.toppings}
		id={dataId}
		bind:ingredients={$ingredients.value}
		on:submit={handleSubmit}
	/>
{:else}
	<p>Data Not Found</p>
{/if}

<script>
  import Template from "./templates/FormTemplate.svelte";
  import { form, field } from "svelte-forms";
  import { required, matchField } from "svelte-forms/validators";
  import { push } from "svelte-spa-router";
  import { connection } from "../../store";

  let data = {
    image: "",
    roles: [],
  };

  let name = field("name", "", [required()]);
  let email = field("email", "", [required()]);
  let gender = field("gender", "", [required()]);
  let phone = field("phone", "", [required()]);
  let password = field("password", "", [required()]);
  let repassword = field("repassword", "", [required(), matchField(password)]);

  let errors = [];

  let formAdd = form(name, email, gender, phone, password, repassword);

  async function handleSubmit(e) {
    const { image, name, email, gender, phone, password, address, roles } =
      e.detail;

    errors = [];

    await formAdd.validate();
    const valid = $formAdd.valid;

    if (valid) {
      $connection
        .call(
          "users.add",
          image,
          name,
          email,
          gender,
          phone.toString(),
          password,
          address,
          roles
        )
        .then((res) => {
          window.pushToast("New user created");
          push(`/users/${res}/info`);
        })
        .catch((err) => {
          window.pushToast(err.reason, "danger");
        });
    } else {
      errors = $formAdd.errors;
      window.pushToast("Required fields not complete", "danger");
    }
  }
</script>

<Template
  bind:errors
  bind:image={data.image}
  bind:name={$name.value}
  bind:email={$email.value}
  bind:gender={$gender.value}
  bind:phone={$phone.value}
  bind:password={$password.value}
  bind:repassword={$repassword.value}
  bind:address={data.address}
  bind:roles={data.roles}
  on:submit={handleSubmit}
/>

<script>
	import Layout from "./templates/LayoutTemplate.svelte";
	import Template from "./templates/StockopnameTemplate.svelte";

	export let params = {};
	let dataId;

	$: if (params) {
		dataId = params._id;
	}
</script>

<Layout id={dataId} page="stockopname">
	<Template id={dataId} />
</Layout>
